import { Component, OnInit } from '@angular/core';
import { ProfileTable } from '../_models/profileTable';
import { ProfilesService } from '../_services/profiles/profiles.service';

@Component({
  selector: 'app-profiles-table',
  templateUrl: './profiles-table.component.html',
  styleUrls: ['./profiles-table.component.css']
})
export class ProfilesTableComponent implements OnInit {

  listProfiles: ProfileTable[] = [];

  first = 0;
  rows = 10;

  constructor(
    private profilesSrv: ProfilesService
  ) { }

  ngOnInit() {
    this.getProfiles();
  }

  getProfiles() {
    this.profilesSrv.getProfiles()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listProfiles = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }


  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

}
