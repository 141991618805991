import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CompaniesComponent } from './companies/companies.component';
import { BranchOfficesFormComponent } from './branch-offices-form/branch-offices-form.component';
import { BranchOfficeComponent } from './branch-office/branch-office.component';
import { HomeComponent } from './home/home.component';
import { SalesComponent } from './sales/sales.component';
import { UsersComponent } from './users/users.component';
import { UsersFormComponent } from './users-form/users-form.component';
import { ProductsComponent } from './products/products.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { CustomersComponent } from './customers/customers.component';
import { TransfersComponent } from './transfers/transfers.component';
import { TransfersFormComponent } from './transfers-form/transfers-form.component';
import { CashClosingComponent } from './cash-closing/cash-closing.component';
import { CashRegisterComponent } from './cash-register/cash-register.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { InventoryAuditComponent } from './inventory-audit/inventory-audit.component';
import { ProfilesTableComponent } from './profiles-table/profiles-table.component';
import { accesUrlGuard } from './_guards/profiles.guards';
import { AuditResumeComponent } from './audit-resume/audit-resume.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { PromotionsFormComponent } from './promotions-form/promotions-form.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ParametersFormComponent } from './parameters-form/parameters-form.component';
import { ReportsComponent } from './reports/reports.component';
import { SalesListComponent } from './sales-list/sales-list.component';
import { SalesListDetailComponent } from './sales-list-detail/sales-list-detail.component';
import { CoreComponentComponent } from './common-component/core-component/core-component.component';
import { CashClosingHistoricalComponent } from './cash-closing-historical/cash-closing-historical.component';
import { CategoryManagerComponent } from './category-manager/category-manager.component';
import { UnitOfMeasureComponent } from './unit-of-measure/unit-of-measure.component';
import { ProductVariantComponent } from './product-variant/product-variant.component';
import { InventoryTweaksComponent } from './inventory-tweaks/inventory-tweaks.component';
import { InventoryTweaksFormComponent } from './inventory-tweaks-form/inventory-tweaks-form.component';
import { editProductsGuard } from './_guards/editProducts.guards';
import { ReportCustomersComponent } from './report-customers/report-customers.component';
import { ReportSalesdComponent } from './report-salesd/report-salesd.component';
import { ReportSaleshComponent } from './report-salesh/report-salesh.component';
import { ReportStockComponent } from './report-stock/report-stock.component';
import { ReportStockmovesComponent } from './report-stockmoves/report-stockmoves.component';
import { ReportTransfersComponent } from './report-transfers/report-transfers.component';
import { PurchaseUploadComponent } from './purchase-upload/purchase-upload.component';
import { CartillaComponent } from './cartilla/cartilla.component';
import { ReportDyeComponent } from './report-dye/report-dye.component';

const routes: Routes = [
  { path: 'signIn', component: LoginComponent },
  {
    path: '',
    component: CoreComponentComponent,
    canActivate: [accesUrlGuard],
    data: { path: 'home' },
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'home' },
      },
      {
        path: 'categories',
        component: CategoryManagerComponent,
      },
      {
        path: 'units',
        component: UnitOfMeasureComponent,
      },
      {
        path: 'variants',
        component: ProductVariantComponent,
      },
      {
        path: 'sales',
        component: SalesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'sales' },
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'companies' },
      },

      {
        path: 'branch-office',
        component: BranchOfficeComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'branch-office' },
      },
      {
        path: 'new-branch',
        component: BranchOfficesFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'branch-office' },
      },
      {
        path: 'update-branch/:id',
        component: BranchOfficesFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'branch-office' },
      },

      {
        path: 'new-sale',
        component: SalesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'new-sale' },
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'reports' },
      },
      {
        path: 'report-customers',
        component: ReportCustomersComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-customers' },
      },
      {
        path: 'report-salesd',
        component: ReportSalesdComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-salesd' },
      },
      {
        path: 'report-salesh',
        component: ReportSaleshComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-salesh' },
      },
      {
        path: 'report-stock',
        component: ReportStockComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-stock' },
      },
      {
        path: 'report-stockmoves',
        component: ReportStockmovesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-stockmoves' },
      },
      {
        path: 'report-transfers',
        component: ReportTransfersComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-transfers' },
      },
      {
        path: 'report-dye',
        component: ReportDyeComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'report-dye' },
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'users' },
      },
      {
        path: 'new-user',
        component: UsersFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'users' },
      },
      {
        path: 'update-user/:id',
        component: UsersFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'users' },
      },

      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'products' },
      },
      {
        path: 'new-product',
        component: ProductFormComponent,
        canActivate: [editProductsGuard],
        data: { path: 'products' },
      },
      {
        path: 'update-product/:id',
        component: ProductFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'products' },
      },

      {
        path: 'customers',
        component: CustomersComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'customers' },
      },

      {
        path: 'transfers',
        component: TransfersComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'transfers' },
      },
      {
        path: 'new-transfers',
        component: TransfersFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'transfers' },
      },
      {
        path: 'detail-transfer/:id',
        component: TransfersFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'transfers' },
      },

      {
        path: 'profiles',
        component: ProfilesTableComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'profiles' },
      },
      {
        path: 'new-profile',
        component: ProfilesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'profiles' },
      },
      {
        path: 'update-profile/:id',
        component: ProfilesComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'profiles' },
      },

      {
        path: 'cash-closing',
        component: CashClosingComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'cash-closing' },
      },
      // {path:'cash-closing-historical', component: CashClosingHistoricalComponent, canActivate: [accesUrlGuard], data: { path: 'cash-closing-historical'}},
      {
        path: 'cash-closing-historical',
        component: CashClosingHistoricalComponent,
        data: { path: 'cash-closing-historical' },
      },
      {
        path: 'inventory-audit',
        component: InventoryAuditComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'inventory-audit' },
      },

      { path: 'audit-resume', component: AuditResumeComponent },
      { path: 'cash', component: CashRegisterComponent },

      {
        path: 'promotions',
        component: PromotionsComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'promotions' },
      },
      {
        path: 'new-promotion',
        component: PromotionsFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'promotions' },
      },
      {
        path: 'update-promotion/:id',
        component: PromotionsFormComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'promotions' },
      },

      { path: 'parameters', component: ParametersComponent },
      { path: 'new-parameter', component: ParametersFormComponent },
      { path: 'update-parameter/:id', component: ParametersFormComponent },

      {
        path: 'sales-list',
        component: SalesListComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'sales-list' },
      },
      {
        path: 'sales-list-detail/:id',
        component: SalesListDetailComponent,
        canActivate: [accesUrlGuard],
        data: { path: 'sales-list' },
      },
      {
        path: 'inventory-tweaks',
        component: InventoryTweaksComponent,
        //canActivate: [accesUrlGuard],
        //data: { path: 'sales-list' },
      },
      {
        path: 'new-inventory-tweaks',
        component: InventoryTweaksFormComponent,
        //canActivate: [accesUrlGuard],
        //data: { path: 'sales-list' },
      },
      {
        path: 'update-inventory-tweaks/:id',
        component: InventoryTweaksFormComponent,
        //canActivate: [accesUrlGuard],
        //data: { path: 'sales-list' },
      },
      {
        path: 'purchase-upload',
        component: PurchaseUploadComponent,
        //canActivate: [accesUrlGuard],
        //data: { path: 'sales-list' },
      },
      {
        path: 'cartilla',
        component: CartillaComponent,
        //canActivate: [accesUrlGuard],
        //data: { path: 'cartilla' },
      },
    ],
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{bindToComponentInputs: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
