<div class="mb-4">
    <div class="d-flex my-4 align-items-center">
      <button [routerLink]="['/inventory-tweaks']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
      <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Ajuste</h2>
    </div>
  
    <form [formGroup]="formTransfer" (ngSubmit)="validater()" class="mb-5">
      <div class="card p-3 mb-4">
        <h2 class="fw-bold text-center text-sm-start">Datos del Ajuste</h2>
          <div class="row col-12 m-0 p-0">
            <div class="col-sm-4 mb-3">
              <p class="m-0">Tipo de Ajuste</p>
              <mat-select class="select space-select" (selectionChange)="changeAdjustmenType()" formControlName="adjustmenType">
                @for (type of listTypes; track $index) {
                  <mat-option [value]="type.typeId">
                    {{type.typeName}}
                  </mat-option>
                }
              </mat-select>
              @if (formTransfer.get('adjustmenType')?.touched && formTransfer.get('adjustmenType')?.errors?.['required']) {
                <p class="text-danger">Por favor, seleccione una opción.</p>
              }
            </div>
            @if (formTransfer.get('adjustmenType')?.value != 2) {
                <div class="col-sm-4 mb-3">
                    <p class="m-0">Motivo de ajuste</p>
                    <mat-select class="select space-select" formControlName="adjustmentReasons" >
                      @for (rason of listRasons; track $index) {
                        <mat-option [value]="rason.typeId">
                          {{ rason.typeName }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (formTransfer.get('adjustmentReasons')?.touched && formTransfer.get('adjustmentReasons')?.errors?.['required']) {
                      <p class="text-danger">Por favor, seleccione una opción.</p>
                    }
                </div>
            }
            @if (formTransfer.get('adjustmenType')?.value != 1) {
              <div class="col-sm-4 mb-3">
                <p class="m-0">Proveedor</p>
                <mat-select class="select space-select" formControlName="supplier" >
                  @for (supplier of listSuppliers; track $index) {
                    <mat-option [value]="supplier.typeId">
                      {{ supplier.typeName }}
                    </mat-option>
                  }
                </mat-select>
                @if (formTransfer.get('supplier')?.touched && formTransfer.get('supplier')?.errors?.['required']) {
                  <p class="text-danger">Por favor, ingrese un proveedor.</p>
                }
              </div>
              <div class="col-sm-4 mb-3">
                <label for="document">Factura / Documento</label>
                <input type="text" class="form-control" id="document" formControlName="document" placeholder="Ingresa nombre factura / documento">
                @if (formTransfer.get('document')?.touched && formTransfer.get('document')?.errors?.['required']) {
                    <p class="text-danger">Por favor, ingrese un nombre de documento.</p>
                }
              </div>
              
              <div class="col-sm-4 mb-3">
                <p class="m-0">Monto</p>
                <p-inputGroup>
                  <p-inputGroupAddon>Q</p-inputGroupAddon>
                  <input type="number" class="inputGroup" formControlName="transferValue" placeholder="0.00" readonly />
                </p-inputGroup>
                @if (formTransfer.get('transferValue')?.touched && formTransfer.get('transferValue')?.errors?.['required']) {
                  <p class="text-danger">Por favor, ingrese un monto.</p>
                }
              </div>
            }
          </div>
      </div>
    
      <div class="card p-3 md-4">
        <div class="row col-12">
          <h2 class="fw-bold text-center text-md-start mb-3 col-12 col-md-3">Productos</h2>
          <div  class="col-12 col-md-3">
            <mat-checkbox 
              [checked]="isBulkLoad"
              color="primary"
              (change)="isBulkLoad = !isBulkLoad"
            >
            Cargar masivamente
            </mat-checkbox>
          </div>
          <div class="col-12 col-md-3">
            <button type="button" class="btn btn-light w-100 " (click)="downloadLayout(1)" style="color: #2BA8D9 !important; font-weight: 500;cursor: pointer;">Descargar Layout Ajsute.</button>
          </div>
          <div class="col-12 col-md-3">
            <button type="button" class="btn btn-light w-100" (click)="downloadLayout(2)" style="color: #2BA8D9 !important; font-weight: 500;cursor: pointer;">Descargar Layout Compra.</button>
          </div>
        </div>
        @if(isBulkLoad) {
            <div class="card px-5 py-3 mb-5">
                <h2 class="fw-bold">Carga masiva de productos</h2>
                  <div class="row col-12 mb-3">
                    <div class="col-12 col-md-6">
                      <div class="form-group px-3 mb-2 col-12">
                        <label for="file">Nombre del documento</label>
                        <!-- <input type="text" class="form-control w-100" id="file" aria-describedby="emailHelp" [(ngModel)]="fileName" readonly> -->
                        <p>{{ fileName }}</p>
                      </div>
                      <button type="button" class="btn btn w-100" (click)="openFileInputProducts()" style="color: #2BA8D9; font-weight: 500;cursor: pointer;">Seleccionar archivo.</button>
                    </div>
                    <input id="fileInputProducts" type="file" accept=".xls,.xlsx" class="form-control d-none" (change)="selectFileProducts($any($event.target).files)">
                  </div>
              </div>
        }
        @else {
            <div class="table-responsive">
                <p-table 
                [value]="listProdTransfer"
                class="table datanew" 
                dataKey="code" 
                [tableStyle]="{'min-width': '50rem'}"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th *ngIf="formTransfer.get('adjustmenType')?.value == 2">Precio</th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td>{{ product.nameProduct }} </td>
                      <td> 
                        <input *ngIf="title != 'Modificar'" [id]="'quantity' + product.productId" appDecimalRestriction class="form-control" type="number" (change)="changeQuantityProduc(product.productId)"  [value]="" placeholder="0" /> 
                        <input *ngIf="title == 'Modificar'" [id]="'quantity' + product.productId" appDecimalRestriction class="form-control" type="number" (change)="changeQuantityProduc(product.productId)"  [value]="product.quantityProduct" /> 
                      </td>
                      <td *ngIf="formTransfer.get('adjustmenType')?.value == 2"> 
                        <input [id]="'receivedQuantity' + product.productId" appDecimalRestriction appNotNegative class="form-control" type="number"  [value]="product.priceProduct" disabled /> 
                      </td>
                      <td>
                        <a class="btn btn-info" (click)="deleteProductList(product.productId)"><i class="feather icon-delete"></i></a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
          
                <hr class="mt-3" style="color: #ebebeb;">
                <h3 *ngIf="title != 'Autorizar'" class="mt-2" style="color: #2BA8D9; font-weight: 500;cursor: pointer;" data-bs-toggle="modal" data-bs-target="#products"><i class="bi bi-plus-lg me-2"></i> Agregar Producto</h3>
            
                @if (formTransfer.get('products')?.touched && formTransfer.get('products')?.errors?.['required']) {
                  <p class="text-danger">Por favor, seleccione por lo menos un producto.</p>
                }
        
                <!-- MODAL -->
                <div class="modal fade" id="products" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="productsLabel" aria-hidden="true">
                  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h2 class="modal-title" id="productsLabel">Productos</h2>
                        <button type="button" id="btn-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
          
                        <div class="form-group row">
                          <div class="col-12 col-md-8">
                            <input type="text" class="form-control" id="searchTerm" placeholder="Filtrar por nombre, categoría, SKU o Código de barras.">
                          </div>
                          <button type="button" class="btn btn-info col-12 col-md-4" (click)="getProducts()">Filtrar productos</button>
                        </div>
                        <hr>
                        <p-table 
                        [value]="listProducts" 
                        class="table datanew" 
                        dataKey="code" 
                        [rows]="rows"
                        [paginator]="true"
                        [first]="first"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{first} al {last} de {totalRecords} productos"
                        (onPage)="pageChange($event)"
                        [rowsPerPageOptions]="[10, 25, 50]"
                        >
                          <ng-template pTemplate="header">
                            <tr>
                              <th>Nombre Producto</th>
                              <th>Stok</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-product>
                            <tr style="cursor: pointer;" (click)="selecProduct(product)">
                              <td >{{ product.name }} </td>
                              <td>{{ product.inventory }} </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        }
      </div>
    
      <div class="card p-3 md-5">
        <h2 class="fw-bold text-center text-md-start mb-3">Comentarios</h2>
        <textarea rows="5" cols="30" pInputTextarea formControlName="comments" placeholder="Agrega un comentario relacionado al ajuste de inventario." maxlength="500"></textarea>
        @if (formTransfer.get('comments')?.touched && formTransfer.get('comments')?.errors?.['required']) {
          <p class="text-danger">Por favor, ingrese un comentario.</p>
        }
      </div>
  
  
      <div class="d-flex justify-content-end p-5">
        <div class="page-btn me-3" >
          <a [routerLink]="['/inventory-tweaks']" class="btn btn-light">
            Cancelar
          </a>
        </div>
        <div *ngIf="isNewTrasfer" class="page-btn">
          <button type="submit" class="btn btn-info">
          @if (title == 'Realizar') { Aplicar ajuste }
          @else {Modificar Ajuste}
          </button>
        </div>
      </div>
    </form>
  </div>
  
  
  <p-toast>
  </p-toast>
  