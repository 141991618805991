import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  Event as RouterEvent,
} from '@angular/router';
import { CommonService } from 'src/app/_services/common/common.service';
import { SidebarService } from 'src/app/_services/sidebar/sidebar.service';
import { routes } from 'src/app/helpers/routes';
import { Cookies } from 'typescript-cookie';

@Component({
  selector: 'app-sidebar-one',
  templateUrl: './sidebar-one.component.html',
  styleUrls: ['./sidebar-one.component.scss'],
})
export class SidebarOneComponent implements OnInit {
  public routes = routes;
  base = '';
  page = '';
  currentUrl = '';
  cont: number = 1;
  disable: boolean = false;
  
  public side_bar_data: Array<any> = [];

  constructor(
    private sidebar: SidebarService,
    private router: Router,
    private commonSrv: CommonService,
    private Router: Router,
  ) {
    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.getRoutes(event);
      }
    });
    this.getRoutes(this.router); 
  }

  ngOnInit(): void {
    this.getNavUser();
  }

  getNavUser() {
    this.sidebar.getNav()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok'){
          let listModule = value.data
          .sort((a:any, b:any) => a.nOrden - b.nOrden)
          .filter((m:any) => m.idPadre == null)
          .map((m:any) => {
            let item = {}
            let sumMenu = value.data
            .filter((s:any) => s.idPadre != null && m.idModulo == s.idPadre)
            .map((s:any) => {
              let itemSub = {
                menuValue: s.cNombre,
                route: s.cUrl,
                hasSubRoute: false,
                showSubRoute: false,
                customSubmenuTwo: false,
              }
              return itemSub;
            });

            if(sumMenu.length == 0) {
              item =  {
                menuValue: m.cNombre,
                hasSubRoute: false,
                showSubRoute: false,
                icon: m.cIcono,
                route: m.cUrl,
              }
              return item;
            }
            else {
              item =  {
                menuValue: m.cNombre,
                hasSubRouteTwo: true,
                showSubRoute: false,
                icon: m.cIcono,
                subMenus: sumMenu
              }
              return item;
            }
          })
          
          this.side_bar_data = [
            {
              showAsTab: false,
              separateRoute: false,
              hasSubRoute: false,
              showSubRoute: true,
              menu: listModule
            }
          ]
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  private getRoutes(route: any): void {
    const splitVal = route.url.split('/');
    this.currentUrl = route.url;
    this.base = splitVal[1];
    this.page = splitVal[2];
  }

  public miniSideBarMouseHover(position: string): void {
    if (position == 'over') {
      this.sidebar.expandSideBar.next(true);
    } else {
      this.sidebar.expandSideBar.next(false);
    }
  }

  
  public expandSubMenus(menu: any): void {
    sessionStorage.setItem('menuValue', menu.menuValue);
    this.side_bar_data.map((mainMenus: any) => {
      mainMenus.menu.map((resMenu: any) => {
        if (resMenu.menuValue == menu.menuValue) {
          menu.showSubRoute = !menu.showSubRoute;
        } else {
          resMenu.showSubRoute = false;
        }
      });
    });
  }

  openMenuItem: any = null;
  openSubmenuOneItem: any = null;
  multiLevel1 = false;
  multiLevel2 = false;
  multiLevel3 = false;


  onHiddenModal(close: boolean) {
    this.disable = close;
  }

  openMenu(menu: any): void {
    if (this.openMenuItem === menu) {
      this.openMenuItem = null; 
    } else {
      this.openMenuItem = menu; 
    }
  }
  openSubmenuOne(subMenus: any): void {
    if (this.openSubmenuOneItem === subMenus) {
      this.openSubmenuOneItem = null; 
    } else {
      this.openSubmenuOneItem = subMenus; 
    }
  }

  multiLevelOne(){
    this.multiLevel1 = !this.multiLevel1
  }
  multiLevelTwo(){
    this.multiLevel2 = !this.multiLevel2
  }
  multiLevelThree(){
    this.multiLevel3 = !this.multiLevel3
  }

  public logout(): void {
    localStorage.clear();
    Cookies.remove('userId');
    Cookies.remove('branchId');
    Cookies.remove('cashId');
    Cookies.remove('companyId');
    Cookies.remove('accesBranches');
    Cookies.remove('accessUnitPrice');
    Cookies.remove('accessDiscount');
    Cookies.remove('editCustomers');
    Cookies.remove('editProducts');
    Cookies.remove('allbranchs');
    this.Router.navigate(['/signIn']);
  }
}
