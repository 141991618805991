<!-- Header -->
<div class="header">

  <!-- Logo -->
  <div class="header-left active" (mouseover)="miniSideBarMouseHover('over')" (mouseout)="miniSideBarMouseHover('out')" >
    <a [routerLink] = "routes.adminDashboard" class="logo logo-normal">
      <img [src]="srcImgPage" alt="">
    </a>
    <a [routerLink] = "routes.adminDashboard" class="logo logo-white">
      <img [src]="srcImgPage" alt="">
    </a>
    <a [routerLink] = "routes.adminDashboard" class="logo-small">
      <img src="../../../assets/images/logoUnico-removebg-preview.png" alt="">
    </a>
    <a id="toggle_btn" href="javascript:void(0);" (click)="toggleSidebar()" [ngClass]="{'d-none': page === 'pos'}">
      <i class="feather icon-chevrons-left" class="feather icon-16"></i>
    </a>
  </div>
  <!-- /Logo -->

  <a id="mobile_btn" class="mobile_btn" (click)="togglesMobileSideBar()">
    <span class="bar-icon">
      <span></span>
      <span></span>
      <span></span>
    </span>
  </a>



  <!-- Header Menu -->
  <ul class="nav user-menu d-flex justify-content-between">
    <div class="m-0 p-0 ms-5 fw-bold">
      <p> {{ companyName }} | {{ branchName }} | {{ cashName }} </p>
    </div>
    <li class="nav-item dropdown has-arrow main-drop">
      <a href="javascript:void(0);" class="dropdown-toggle nav-link userset" data-bs-toggle="dropdown">
        <span class="user-info">
          <span class="user-letter">
            <img [src]="userImage" alt="" class="img-fluid">
          </span>
          <span class="user-detail">
            <span class="user-name">ColorShop</span>
            <span class="user-role">{{userName}}</span>
          </span>
        </span>
      </a>
      <div class="dropdown-menu menu-drop-user">
        <div class="profilename">
          <div class="profileset">
            <span class="user-img"><img [src]="userImage" alt="">
              <!-- <span class="status online"></span> -->
            </span>
            <div class="profilesets">
              <h6 class="m-0">ColorShop</h6>
              <span class="user-role" (click)="disable = true" style="cursor: pointer;">Actualizar Caja o Sucursal</span>
            </div>
          </div>
          <hr class="m-0">
          <!-- button for change avatar -->
          <button class="dropdown-item" (click)="showAvatarModal()"><i class="me-2" class="feather icon-user"></i>Cambiar Avatar</button>
          <!-- <a class="dropdown-item" [routerLink] = "routes.profile"> <i class="me-2" class="feather icon-user"></i> My Profile</a>
          <a class="dropdown-item" [routerLink] = "routes.generalSettings"><i class="me-2"
              class="feather icon-settings"></i>Settings</a> -->
          <hr class="m-0">
          <a class="dropdown-item logout pb-0" (click)="logout()"><img src="assets/img/icons/log-out.svg" class="me-2"
              alt="img">Cerrar sesión</a>
        </div>
      </div>
    </li>
  </ul>
  <!-- /Header Menu -->

  <!-- Avatar Modal -->
  <p-dialog header="Seleccionar Avatar" class="text-dark" [modal]="true" [(visible)]="avatarModalVisible" [style]="{ width: '25rem' }">
    <div class="avatar-list" style="padding: 15px;">
      <img src="assets/img/avatars/1.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/1.png')">
      <img src="assets/img/avatars/2.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/2.png')">
      <img src="assets/img/avatars/3.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/3.png')">
    </div>
    <div class="avatar-list" style="padding: 15px;">
      <img src="assets/img/avatars/4.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/4.png')">
      <img src="assets/img/avatars/5.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/5.png')">
      <img src="assets/img/avatars/6.png" alt="" class="img-fluid" width="100px" (click)="changeAvatar('assets/img/avatars/6.png')">
    </div>
  </p-dialog>
  <!-- /Avatar Modal -->

  <!-- <p-dialog header="Seleccionar caja" class="text-dark" [modal]="true" [(visible)]="disable" [style]="{ width: '25rem' }">
    @for (branch of listCashCompanies; track $index) {
      <p class="p-2" style="background-color: rgb(239, 239, 239);">{{ branch.branchName }}</p>
      @for (cash of branch.cash; track $index) {
        <p class="p-2 ps-3" style="cursor: pointer;" (click)="setCash(cash.idCaja, cash.idSucursal, cash.cNombreCaja)" ><i class="bi bi-box2-fill pe-2" style="color: #52cbf6;"></i> {{ cash.cNombreCaja }}</p>
      }
    }
  </p-dialog> -->

  <app-cash-modal [disable]="disable" (disableEmm)="onHiddenModal($event)"></app-cash-modal>

  <!-- Mobile Menu -->
  <div class="dropdown mobile-user-menu">
    <a href="javascript:void(0);" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
        class="fa fa-ellipsis-v"></i></a>
    <div class="dropdown-menu dropdown-menu-right">
      <a class="dropdown-item" [routerLink] = "routes.profile">My Profile</a>
      <a class="dropdown-item" [routerLink] = "routes.generalSettings">Settings</a>
      <a class="dropdown-item" [routerLink] = "routes.signIn">Logout</a>
    </div>
  </div>
  <!-- /Mobile Menu -->
</div>
<!-- /Header -->
