import { Component, OnInit } from '@angular/core';
import { UsersTable } from '../_models/users';
import { dataCompany } from '../_models/dataCompany';
import { SalesService } from '../_services/sales/sales.service';
import { SalesTable } from '../_models/sales/salesTable';
import { MessageService } from 'primeng/api';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css']
})
export class SalesListComponent implements OnInit {

  first = 0;
  rows = 10;
  dateStart!: Date | null;
  dateEnd!: Date | null;
  currency: String = '';

  public selectedValue1 = '';
  selectedList1: dataCompany[] = [
    { value: 'Creación (antiguos primero)' },
    { value: 'Creación (recientes primero)' },
  ];

  salesList: SalesTable[] = [];

  constructor(
    private salesSrv: SalesService,
    private messageService: MessageService,
    private commonSrv: CommonService
  ) { }

  ngOnInit() {
    this.getSales();
    this.getCurrency();
  }


  getSales() {
    let url = "";

    url += (this.dateStart == undefined) ? '' : `&dateStart=${(this.dateStart!.toISOString())?.split('T')[0]}`;
    url += (this.dateEnd == undefined) ? '' : `&dateEnd=${(this.dateEnd!.toISOString())?.split('T')[0]}`;
    url += (this.selectedValue1 == '') ? '' : `&order=${this.selectedValue1}`;

    this.salesSrv.getSales(parseInt(this.commonSrv.branchId), url)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.salesList = value.data.map((item: SalesTable) => {
            let folio = item.idSale.toString().length;
            item.folio = (folio == 1) ? '000' + item.idSale : (folio == 2) ? '00' + item.idSale : (folio == 3) ? '0' + item.idSale : item.idSale.toString();

          return item
          });

        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las ventas, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las ventas, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getCurrency() {
    this.salesSrv.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos de móneda, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

}
