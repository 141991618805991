import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

constructor(
  private http: HttpClient
) { }

  getModules() {
    return this.http.get<any>(`${environment.url}profiles/modules`);
  }

  getProfiles() {
    return this.http.get<any>(`${environment.url}profiles/getProfiles`);
  }

  getProfileById(id: number) {
    return this.http.get<any>(`${environment.url}profiles/getProfileById?idProfiles=${id}`);
  }

  addOrUpdateProfile(data: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}profiles/addOrUpdateProfile`,data,{headers: header});
  }

}
