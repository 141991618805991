<div>
  <h4>Restablecer Contraseña</h4>
  <p>Por favor, ingresa tu correo electrónico para continuar con el proceso de restablecer tu contraseña.</p>

  <div class="mb-3">
    <label for="email" class="form-label">Email</label>
    <input type="text" [(ngModel)]="email" class="form-control" id="email" (keyup)="validEmail()">
    @if (email != '' && !isValidEmail) {
      <p class="text-danger">Por favor, ingrese un email valido.</p>
    }
    <div class="d-flex justify-content-center w-100 mt-3">
      <button type="button" (click)="sendEmail()" class="btn button-primary col-12">Enviar</button>
    </div>
</div>
</div>
