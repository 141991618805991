import { Component, OnInit } from '@angular/core';
import { companiesList } from '../_models/companiesList';
import { MatTableDataSource } from '@angular/material/table';
import { dataCompany } from '../_models/dataCompany';
import { Sort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { UsersService } from '../_services/users/users.service';
import { MessageService } from 'primeng/api';
import { UsersTable } from '../_models/users';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {

  public usersData: UsersTable[] = [];
  first = 0;
  rows = 10;

  constructor (
    private usersSvr: UsersService,
    private messageService: MessageService,
  ) {
    this.getUsers();
  }


  getUsers() {
    this.usersSvr.getUsers()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.usersData = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener los usuarios, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener los usuarios, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

}
