import { Component, Input } from '@angular/core';
import { ItemForSaleP } from '../_models/sales/itemsForSale';

@Component({
  selector: 'app-thermal-printer',
  standalone: true,
  imports: [],
  templateUrl: './thermal-printer.component.html',
  styleUrl: './thermal-printer.component.css'
})
export class ThermalPrinterComponent {
  @Input() itemsAdded!: Array<ItemForSaleP>;
}
