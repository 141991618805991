import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnitsService } from '../_services/units/units.service';
import { Unit } from '../_models/unit';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-unit-of-measure',
  templateUrl: './unit-of-measure.component.html',
  styleUrls: ['./unit-of-measure.component.css'],
})
export class UnitOfMeasureComponent implements OnInit {
  units: Unit[] = [];
  first = 0;
  rows = 10;
  unitForm!: FormGroup;
  selectedUnit: number | null = null;
  selectedUnitFlag: boolean = false;
  @ViewChild('closebutton') closebutton: any;

  constructor(
    private fb: FormBuilder,
    private unitService: UnitsService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadUnits();
    this.unitForm = this.fb.group({
      id: [],
      cUnidadMedida: ['', Validators.required],
      nEstatus: [1, Validators.required],
    });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  ngAfterViewInit() {
    (
      document.getElementById('add-edit-modal') as HTMLElement
    )?.addEventListener('hidden.bs.modal', () => {
      this.unitForm.reset();
      this.unitForm.patchValue({ nEstatus: 1, cUnidadMedida: '', id: null });
    });
  }

  loadUnits(): void {
    const companyId = parseInt(localStorage.getItem('idCompany') || '0', 10);
    this.unitService.getUnits(companyId).subscribe({
      next: (result: any) => {
        console.log('units');
        console.log(result);
        console.log(result.length);
        if (result.length) {
          this.units = result;
        } else {
          // this.messageService.add({
          //   summary: 'Error',
          //   detail: 'Ocurrio un error al cargar los datos, verifique.',
          //   styleClass: 'danger-background-popover',
          // });
        }
      },
      error: (error) =>
        this.messageService.add({
          summary: 'Error',
          detail:
            'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        }),
    });
  }

  resetForm(): void {
    this.selectedUnit = null;
    this.unitForm.reset();
    this.unitForm.patchValue({
      nEstatus: 1,
      cUnidadMedida: '',
      idUnidadMedida: null,
    });
    this.closebutton.nativeElement.click();
  }

  closeAddOrEditForm() {
    this.unitForm.reset();
    this.unitForm.patchValue({
      nEstatus: 1,
      cUnidadMedida: '',
      idUnidadMedida: null,
    });
    this.closebutton.nativeElement.click();
  }

  addOrUpdateUnit(id: number) {
    this.unitForm.markAllAsTouched();
    console.log(this.unitForm.value);
    if (this.unitForm.valid) {
      console.log('Form is valid');
      if (this.selectedUnit) {
        console.log('Selected unit');
        this.unitForm.patchValue({
          id: this.selectedUnit,
        });
      } else {
        console.log('Selected unit is null');
        this.unitForm.patchValue({
          id: null,
        });
      }
      this.unitService.addOrEditUnit(this.unitForm.value).subscribe({
        next: (data) => {
          console.log('Data: ', data);
          if (data.response == 'Ok') {
            this.closebutton.nativeElement.click();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Unidad agregada/actualizada exitosamente',
              styleClass: 'success-background-popover',
            });
            this.unitForm.reset();
            this.selectedUnitFlag = false;
            this.loadUnits();
          } else {
            console.log('Error: ', data);
            this.messageService.add({
              summary: 'Error',
              detail:
                'Ocurrio un error al guardar la unidad, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error: (error) =>
          this.messageService.add({
            summary: 'Error',
            detail:
              'Ocurrio un error al guardar la unidad, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          }),
      });
    } else {
      console.log('Form is not valid');
    }
  }

  getUnit(idUnidadMedida: number) {
    const selectedUnit = this.units.find(
      (unit) => unit.idUnidadMedida === idUnidadMedida
    );

    console.log('selectedUnit');
    console.log(selectedUnit);
    if (selectedUnit) {
      this.unitForm.patchValue({
        idUnidadMedida: idUnidadMedida,
        cUnidadMedida: selectedUnit.cUnidadMedida,
        nEstatus: selectedUnit.nEstatus == 1 ? 1 : 0,
      });
      this.selectedUnitFlag = true;
      this.selectedUnit = idUnidadMedida;
      document.getElementById('addeditmodal')?.click();
    }
  }
}
