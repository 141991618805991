<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Auditoria de inventario</h4>
        </div>
    </div>
    <div class="page-btn pe-4">
        <a class="btn btn-info" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-item-modal"><i
                class="feather icon-plus-circle me-2"></i> Agregar Producto </a>
    </div>
    <div class="form-sort">
        <i class="feather icon-sliders info-img"></i>
        <mat-select [(ngModel)]="selectedAction" (selectionChange)="getAction()" [ngModelOptions]="{standalone: true}"
            class="select space-select" placeholder="Más acciones">
            <ng-template ngFor let-data [ngForOf]="actionsOptions" let-i="index">
                <mat-option [value]="data.value">
                    {{data.value}}
                </mat-option>
            </ng-template>
        </mat-select>
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="table-responsive">
            <p-table [value]="tableData" selectionMode="single" [(selection)]="selectedItem" class="table  datanew" dataKey="id" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>ID</th>
                        <th>Código de Producto</th>
                        <th>Nombre de Producto</th>
                        <th>Cantidad encontrada</th>
                        <th>Ubicación/Comentario</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                        </td>
                        <td>{{customer.id}}</td>
                        <td>{{customer.itemCode}} </td>
                        <td>{{customer.itemName}} </td>
                        <td><div class="input-group mb-3">
                            <input type="number"
                                class="form-control" [(ngModel)]="customer.quantity">
                        </div> </td>
                        <td><div class="input-group mb-3">
                            <input type="text" [(ngModel)]="customer.comments"
                                class="form-control">
                        </div> </td>
                        <td><a class="btn btn-info" (click)="deleteItem(customer.id)" ><i class="feather icon-delete"></i></a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <app-custom-pagination></app-custom-pagination> -->
    </div>
</div>
<div class="row justify-content-end">
    <div class="col-auto">
        <a class="btn btn-info" id="addeditmodal"  (click)="addItemsToAudit()" ><i
            class="feather icon-save me-2"></i> Guardar Conteo </a>
    </div>
</div>
<div class="modal fade" id="add-item-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Añadir Producto</h4>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                    id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <label
                            class="input-searh-items border-gray-300 input-info input-bordered d-flex align-middle gap-2"
                            style="position: relative;"><svg stroke="currentColor" fill="currentColor" stroke-width="0"
                                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M456.69 421.39 362.6 327.3a173.81 173.81 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.81 173.81 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3zM97.92 222.72a124.8 124.8 0 1 1 124.8 124.8 124.95 124.95 0 0 1-124.8-124.8z">
                                </path>
                            </svg>
                            <input type="text" class="input-no-color w-100 h-100"
                                placeholder="Buscar por nombre, código de articulo" value=""
                                (keydown.enter)="showSearchItemIndicator($event)">
                        </label>
                    </div>
                    <div class="col-12 h-50 over-items-results">
                        <div id="search_items_indicator" [ngClass]="{'d-none': showSearchIndicator}"
                            class="row card mx-2 mt-1 w-zindex">
                            <div class="col-12 d-flex mt-2">
                                <div class="text-secondary spinner-grow spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <p class="ms-2 mb-2 align-middle">Buscando Productos...</p>
                            </div>
                        </div>
                        <div (mouseleave)="hideSearchResult()" [ngClass]="{'d-none': showResultItems}"
                            class="row card mx-2 mt-1 w-zindex w-100">
                            <div class="col-12 mt-2">
                                <ng-template ngFor let-data [ngForOf]="itemsResultSearch" let-i="index">
                                    <div class="row py-3 border-bottom hover-item-card" (click)="addItemToAudit(data)">
                                        <div class="col-2">
                                            <!-- <img class="img-fluid" src="../../assets/images/cslogo.png"> -->
                                            <img class="img-fluid" src={{data.imgUrl}}>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <p>{{data.name}}</p>
                                                </div>
                                                <div class="col-12">
                                                    <p>{{data.code}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2" (click)="closeManualModal()">Cancelar</button>
                <button type="button" (click)="addItem()" class="btn btn btn-info d-none">Crear y Agregar</button>
            </div>
        </div>
    </div>
</div>
<button data-bs-toggle="modal" class="d-none" id="btn-cofirm-cash-closing"
    data-bs-target="#confirm-cash-closing-modal"></button>
<div class="modal fade" id="confirm-cash-closing-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" #closebuttonConfirmClosingCash class="btn-close" data-bs-dismiss="modal"
                    id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <p class="fw-bolder">
                            ¿Desea Cancelar los conteos y cancelar el proceso de Auditoria? Todos los datos del conteo actual se perderán.
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2"
                    (click)="closeManualModalConfirmCashClosing()">No</button>
                <button type="button" (click)="cancelAudit()" class="btn btn btn-info">Si</button>
            </div>
        </div>
    </div>
</div>
<p-toast>
</p-toast>