import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { salesReportList } from '../_models/sales/salesReportList';
import { ReportsService } from '../_services/reports/reports.service';
import { reportTableCustomerData } from '../_models/report/reportTableCustomerData';
import { dataCompany } from '../_models/dataCompany';
import { reportsTypes } from '../_models/sales/reportsTypes';
import * as FileSaver from 'file-saver';
import { SalesService } from '../_services/sales/sales.service';


@Component({
  selector: 'app-report-customers',
  templateUrl: './report-customers.component.html',
  styleUrl: './report-customers.component.css'
})
export class ReportCustomersComponent  implements OnInit {
  reportForm!: FormGroup;
  templateForm!: FormGroup;
  selectedReportRowA!: reportTableCustomerData;
  reportList: Array<ReportList> = [];
  selectedAction: String = '';
  startDate!: Date;
  endDate!: Date;
  currency : String = '';
  actionsOptions: reportsTypes[] = [
    {value: 1, name: 'Reporte de Clientes'}
  ];
  public reporData: Array<reportTableCustomerData> = [];

  constructor(private reporService: ReportsService, private messageService: MessageService,  private salesService : SalesService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.reporService.getCustomersReport()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.reporData = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'No se pudo obtener la información del reporte.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
    this.getCurrency();
  }
  getAction() { }
  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getReportData() {
    if(this.startDate != null && this.endDate != null){
      let body = {
        startDate: this.startDate,
        endDate: this.endDate
      }
      this.reporService.getCustomersReportParam(body)
        .subscribe({
          next: data => {
            if (data.response == "Ok") {
              this.reporData = data.data;
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al obtener la información, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al realizar la operación, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  exportData(){
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.reporData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Report");
    });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}

