import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Event as RouterEvent } from '@angular/router';
import { LoginService } from 'src/app/_services/login/login.service';
import { SidebarService } from 'src/app/_services/sidebar/sidebar.service';
@Component({
  selector: 'app-sidebar-three',
  templateUrl: './sidebar-three.component.html',
  styleUrls: ['./sidebar-three.component.scss'],
})
export class SidebarThreeComponent {

  opendSubMenu: Array<string | number> = [];
  public activePath = '';
  public sidebarData: Array<any> = [];

  constructor(
    private sidebar: SidebarService,
    private router: Router,
    private loginSrv: LoginService
  ) 
  {
    this.activePath = this.router.url.split('/')[1];
    this.router.events.subscribe((data: RouterEvent) => {
      if (data instanceof NavigationStart) {
        this.activePath = data.url.split('/')[1];
      }
      this.opendSubMenu[0] = ''
    });
   // this.sidebarData = this.sidebar.sidebarData3;
  }

  showMenu(val: string): void {
    if (this.opendSubMenu[0] != val) {
      this.opendSubMenu[0] = val;
    } else {
      this.opendSubMenu[0] = '';
    }
  }

  showSubMenu(val: string): void {
    if (this.opendSubMenu[1] != val) {
      this.opendSubMenu[1] = val;
    } else {
      this.opendSubMenu[1] = '';
    }
  }
}
