import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { Cookies } from 'typescript-cookie';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  constructor(private http: HttpClient) {}

  getUnits(companyId: number) {
    const parameters = new HttpParams().set('companyId', companyId);
    return this.http.get<any>(`${environment.url}UnitsOfMeasure`, {
      params: parameters,
    });
  }

  createUnit(body: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}UnitsOfMeasure`, body, {
      headers: header,
    });
  }

  updateUnit(body: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.put<any>(`${environment.url}UnitsOfMeasure`, body, {
      headers: header,
    });
  }

  deleteUnit(id: number) {
    return this.http.delete<any>(`${environment.url}UnitsOfMeasure/${id}`);
  }

  addOrEditUnit(body: any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    const companyId = localStorage.getItem('idCompany');
    const userId = Cookies.get('userId');
    body.companyId = companyId;
    body.userId = userId;
    return this.http.post<any>(`${environment.url}UnitsOfMeasure`, body, {
      headers: header,
    });
  }
}
