<!-- Sidebar -->
<div class="sidebar horizontal-sidebar">
  <div id="sidebar-menu-3" class="sidebar-menu">
    <ul class="nav">
      @for (mainTittle of sidebarData; track mainTittle) {
        <li class="submenu">
          <a routerLinkActive="active"  [class.active]="opendSubMenu[0] === mainTittle.tittle"
            (click)="showMenu(mainTittle.tittle)"
            [class.subdrop]="opendSubMenu[0] === mainTittle.tittle"
            >
            @if (mainTittle.tittle === 'Components') {
              <i
                class="feather icon-layers"
              ></i>
            }
            @if (mainTittle.tittle !== 'Components') {
              <img
                [src]="mainTittle.icon"
                alt="img" />
              }<span> {{ mainTittle.tittle }}</span>
              <span class="menu-arrow"></span
              ></a>
              <ul
                class="submenus-two"
            [ngClass]="{
              'd-block': opendSubMenu[0] === mainTittle.tittle,
              'd-none': opendSubMenu[0] !== mainTittle.tittle
            }"
                >
                @for (mainMenus of mainTittle.subRoutes; track mainMenus) {
                  @if (mainMenus.hasSubRoute === false) {
                    <li>
                      <a routerLinkActive="active"
                        [routerLink]="mainMenus.route"
                        [class.active]="mainMenus.activeRoute === activePath"
                        ><img
                    [hidden]="
                      mainTittle.tittle === 'Inventory' ||
                      mainTittle.tittle === 'Profile' ||
                      mainTittle.tittle === 'Reports' ||
                      mainTittle.tittle === 'Settings'
                    "
                        [src]="mainMenus.icon"
                        alt="icon"
                        /><span>
                        {{ mainMenus.tittle }}
                      </span>
                    </a>
                  </li>
                }
                @if (mainMenus.hasSubRoute === true) {
                  <li class="submenu">
                    <a
                  [ngClass]="{
                    active: activePath === mainMenus.activeRoute,
                    subdrop: opendSubMenu[1] === mainMenus.tittle
                  }"
                      (click)="showSubMenu(mainMenus.tittle)"
                      ><span>
                      {{ mainMenus.tittle }}</span
                      >
                      <span class="menu-arrow"></span
                      ></a>
                      <ul routerLinkActive="active"
                        class="submenus-two"
                  [ngClass]="{
                    'd-block': opendSubMenu[1] === mainMenus.tittle,
                    'd-none': opendSubMenu[1] !== mainMenus.tittle
                  }"
                        >
                        @for (subDropMenus of mainMenus.subRoutes; track subDropMenus) {
                          <li>
                            <a
                              [routerLink]="subDropMenus.route"
                              routerLinkActive="active"
                              >{{ subDropMenus.tittle }}</a
                              >
                            </li>
                          }
                        </ul>
                      </li>
                    }
                  }
                </ul>
              </li>
            }
          </ul>
        </div>
      </div>
      <div class="sidebar sidebar-three 'd-none'-sidebar" id="sidebar">
        <div class="sidebar-inner slimscroll">
          <!-- <ng-scrollbar> -->
            <div id="sidebar-menu" class="sidebar-menu">
              <ul>
                @for (mainTittle of sidebarData; track mainTittle) {
                  <li class="submenu">
                    <a
                      (click)="showMenu(mainTittle.tittle)"
                      [class.subdrop]="opendSubMenu[0] === mainTittle.tittle"
                      ><img [src]="mainTittle.icon" alt="img" /><span>
                      {{ mainTittle.tittle }}</span
                      >
                      <span class="menu-arrow"></span
                      ></a>
                      <ul
                [ngClass]="{
                  'd-block': opendSubMenu[0] === mainTittle.tittle,
                  'd-none': opendSubMenu[0] !== mainTittle.tittle
                }"
                        >
                        @for (mainMenus of mainTittle.subRoutes; track mainMenus) {
                          @if (mainMenus.hasSubRoute === false) {
                            <li>
                              <a
                                [routerLink]="mainMenus.route"
                                [class.active]="mainMenus.activeRoute === activePath"
                                ><img
                        [hidden]="
                          mainTittle.tittle === 'Inventory' ||
                          mainTittle.tittle === 'Products' ||
                          mainTittle.tittle === 'Create Product' ||
                          mainTittle.tittle === 'Settings'
                        "
                                [src]="mainMenus.icon"
                                alt="icon"
                                /><span>
                                {{ mainMenus.tittle }}
                              </span>
                            </a>
                          </li>
                        }
                        @if (mainMenus.hasSubRoute === true) {
                          <li class="submenu">
                            <a
                      [ngClass]="{
                        active: activePath === mainMenus.activeRoute,
                        subdrop: opendSubMenu[1] === mainMenus.tittle
                      }"
                              (click)="showSubMenu(mainMenus.tittle)"
                              ><img [src]="mainMenus.icon" alt="img" /><span>
                              {{ mainMenus.tittle }}</span
                              >
                              <span class="menu-arrow"></span
                              ></a>
                              <ul
                      [ngClass]="{
                        'd-block': opendSubMenu[1] === mainMenus.tittle,
                        'd-none': opendSubMenu[1] !== mainMenus.tittle
                      }"
                                >
                                @for (subDropMenus of mainMenus.subRoutes; track subDropMenus) {
                                  <li>
                                    <a
                                      [routerLink]="subDropMenus.route"
                                      routerLinkActive="active"
                                      >{{ subDropMenus.tittle }}</a
                                      >
                                    </li>
                                  }
                                </ul>
                              </li>
                            }
                          }
                        </ul>
                      </li>
                    }
                  </ul>
                </div>
              <!-- </ng-scrollbar> -->
            </div>
          </div>
          <!-- /Sidebar -->
