import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, Event as RouterEvent } from '@angular/router';
import { BranchesList } from 'src/app/_models/branches/branchesList';
import { CashCompanies, CashData } from 'src/app/_models/cash/cashRegisterList';
import { BranchesService } from 'src/app/_services/branches/branches.service';
import { CommonService } from 'src/app/_services/common/common.service';
import { LoginService } from 'src/app/_services/login/login.service';
import { SidebarService } from 'src/app/_services/sidebar/sidebar.service';
import { WebstorgeService } from 'src/app/_services/webstorge.service';
import { routes } from 'src/app/helpers/routes';
import { Cookies, removeCookie, setCookie, getCookie } from 'typescript-cookie';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public routes = routes;
  activePath = '';
  showSearch = false;
  public changeLayout = '1';
  public darkTheme = false;
  public logoPath = '';
  public miniSidebar = false;
  elem = document.documentElement;
  public addClass = false;
  base = '';
  page = '';
  last = '';
  avatarModalVisible: boolean = false;

  brancheId!: number;
  branchesList: BranchesList[] = [];
  userImage: string = 'assets/img/profiles/avator1.png'; // Default image
  branchName: string = '';
  companyName: string = '';

  disable: boolean = false;
  srcImgPage: string = "";

  constructor(
    private Router: Router,
    private common: CommonService,
    private sidebar: SidebarService,
    private loginSrv: LoginService,
    private branchSrv: BranchesService,
    private http: HttpClient
  ) {
    this.setUserImage();
    this.activePath = this.Router.url.split('/')[2];
    this.Router.events.subscribe((data: RouterEvent) => {
      if (data instanceof NavigationStart) {
        this.activePath = data.url.split('/')[2];
      }
    });
    this.sidebar.sideBarPosition.subscribe((res: string) => {
      if (res == 'true') {
        this.miniSidebar = true;
      } else {
        this.miniSidebar = false;
      }
    });
    this.common.base.subscribe((base: string) => {
      this.base = base;
    });
    this.common.page.subscribe((page: string) => {
      this.page = page;
    });
    this.common.last.subscribe((last: string) => {
      this.last = last;
    });
  }

  ngOnInit(): void {
    this.getBranch();
    this.srcImgPage = this.common.imgPage ?? '';
  }

  get company() {
    return this.common.company;
  }
  get branch() {
    return this.common.branch;
  }
  get cashName() {
    return this.common.cashName;
  }
  get userName() {
    return this.common.userName;
  }
  get accesBranches() {
    return JSON.parse(Cookies.get('accesBranches')!.toString());
  }

  public logout(): void {
    localStorage.clear();
    Cookies.remove('userId');
    Cookies.remove('branchId');
    Cookies.remove('cashId');
    Cookies.remove('companyId');
    Cookies.remove('accesBranches');
    Cookies.remove('accessUnitPrice');
    Cookies.remove('accessDiscount');
    Cookies.remove('editCustomers');
    Cookies.remove('editProducts');
    Cookies.remove('allbranchs');
    this.Router.navigate(['/signIn']);
  }

  public toggleSidebar(): void {
    this.sidebar.switchSideMenuPosition();
  }

  public togglesMobileSideBar(): void {
    this.sidebar.switchMobileSideBarPosition();
  }

  public miniSideBarMouseHover(position: string): void {
    if (position == 'over') {
      this.sidebar.expandSideBar.next(true);
    } else {
      this.sidebar.expandSideBar.next(false);
    }
  }

  fullscreen() {
    if (!document.fullscreenElement) {
      this.elem.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  getBranch() {
    this.branchSrv.getBranches().subscribe({
      next: (value) => {
        if (value.response == 'Ok') {
          this.branchesList = value.data;
          //this.common.setBranch(this.branchesList.find(x => x.idSucursal == this.brancheId)!.branchName, this.brancheId)
          this.branchesList = this.branchesList.filter(
            (b) => b.companyId == parseInt(this.common.companyId)
          );

          this.companyName = this.branchesList.find(
            (x) => x.idSucursal == parseInt(getCookie('branchId')!)
          )!.businessName;
          this.branchName = this.branchesList.find(
            (x) => x.idSucursal == parseInt(getCookie('branchId')!)
          )!.branchName;
        }
      },
      error: (err) => {},
    });
  }

  changeAvatar(imageUrl: string) {
    const apiUrl = environment.url + 'users/addImage';
    const userId = Cookies.get('userId');
    const payload = { ImageUrl: imageUrl, UserId: userId };

    this.http.post(apiUrl, payload).subscribe(
      (response: any) => {
        console.log('Image updated successfully', response);
        localStorage.setItem('image', imageUrl); // Update localStorage
        this.setUserImage();
        this.avatarModalVisible = false;
      },
      (error) => {
        console.error('Error updating image', error);
        // Handle error response
      }
    );
  }
  showAvatarModal() {
    this.avatarModalVisible = true;
  }
  setUserImage() {
    const storedImage = localStorage.getItem('image');
    if (storedImage) {
      this.userImage = storedImage;
    }
  }

  onHiddenModal(close: boolean) {
    this.disable = close;
  }
}
