
<div class="card table-list-card">
  <div class="row col-12 card-body">
    <div class="col-11 col-md-10 mx-3">
      <div class="d-flex mt-4 align-items-center">
        <button [routerLink]="['/promotions']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
        <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Promoción</h2>
      </div>
    </div>
    <form [formGroup]="formPromotions">
      <div class="col-11 card my-4 mx-3">
        <div class="card-body p-4 row col-12">
          <h3 class="fw-bold">Datos de promoción</h3>

          @if (formPromotions.get('typePromotion')?.value != 4 && formPromotions.get('typePromotion')?.value != 5) {
            <div class="col-12 mb-3">
              <label for="promotionName">Nombre promoción</label>
              <input type="text" class="form-control" id="promotionName" formControlName="promotionName" aria-describedby="emailHelp" placeholder="">
            </div>
          }

          @if(formPromotions.get('typePromotion')?.value != 5) {
            <div class="col-12 col-md-6 mb-3">
              <p class="m-0">Fecha inicio de promoción</p>
              <p-calendar formControlName="dateStart" class="col-12" [iconDisplay]="'input'" dateFormat="dd/mm/yy" [showIcon]="true" [minDate]="currentDate"></p-calendar>
              @if (formPromotions.get('dateStart')?.touched && formPromotions.get('dateStart')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese fecha de inicio.</p>
              }
            </div>
  
            <div class="col-12 col-md-6 mb-3">
              <p class="m-0">Fecha fin de promoción</p>
              <p-calendar formControlName="dateEnd" [iconDisplay]="'input'" dateFormat="dd/mm/yy" [showIcon]="true" [minDate]="currentDate"></p-calendar>
              @if (formPromotions.get('dateEnd')?.touched && formPromotions.get('dateEnd')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese fecha final.</p>
              }
            </div>
          }

          <div class="col-12 col-md-6 mb-3">
            <p class="m-0 p-0">Tipo promoción</p>
            <mat-select class="select space-select" (selectionChange)="filtesRule()" formControlName="typePromotion">
              @for (item of listTypeProm; track $index) {
                <mat-option [value]="item.id">
                  {{item.name}}
                </mat-option>
              }
            </mat-select>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <p class="m-0 p-0">Regla</p>
            <mat-select class="select space-select" formControlName="ruler">
              @for (item of listRuler; track $index) {
                <mat-option [value]="item.id">
                  {{item.name}}
                </mat-option>
              }
            </mat-select>
          </div>

          @if (formPromotions.get('typePromotion')?.value == 4) {
            <div class="col-12 col-md-6 mb-3">
              <label for="couponCode">Código cupón</label>
              <input type="text" class="form-control" id="couponCode" formControlName="couponCode" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('couponCode')?.touched && formPromotions.get('couponCode')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (formPromotions.get('typePromotion')?.value == 4) {
            <div class="col-12 col-md-6 mb-3">
              <label for="description">Descripción</label>
              <textarea class="form-control" id="description" formControlName="description" rows="3"></textarea>
              @if (formPromotions.get('description')?.touched && formPromotions.get('description')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (formPromotions.get('typePromotion')?.value == 5) {
            <div class="col-12 col-md-6 mb-3">
              <label for="cardNumber">No. Tarjeta</label>
              <input type="number" class="form-control" id="cardNumber" formControlName="cardNumber" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('cardNumber')?.touched && formPromotions.get('cardNumber')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (formPromotions.get('typePromotion')?.value == 5) {
            <div class="col-12 col-md-6 mb-3">
              <label for="saldo">Saldo</label>
              <input type="number" class="form-control" id="saldo" formControlName="saldo" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('saldo')?.touched && formPromotions.get('saldo')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 3) {
            <div class="col-12 col-md-6 mb-3">
              <div class="row col-12">
                <div class="col-11">
                  <label for="productCode">Código producto</label>
                  <input type="text" class="form-control" id="productCode" formControlName="productCode" aria-describedby="emailHelp">
                  <input type="text" class="form-control d-none" id="productId" formControlName="productId" aria-describedby="emailHelp">
                </div>
                <!-- Button trigger modal -->
                @if (title != "Editar") {
                  <button type="button" class="btn btn-info col-1" style="margin-top: 20px; height: 39px;" (click)="isProductPromo = false" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="bi bi-search"></i></button>
                }
              </div>
              @if (formPromotions.get('productCode')?.touched && formPromotions.get('productCode')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (formPromotions.get('typePromotion')?.value == 2 || formPromotions.get('typePromotion')?.value == 3) {
            <div class="col-12 col-md-6 mb-3">
              <div class="row col-12">
                <div class="col-11">
                  <label for="clientName">Nombre Cliente</label>
                  <input type="text" class="form-control" id="clientName" formControlName="clientName" aria-describedby="emailHelp">
                  <input type="text" class="form-control d-none" id="clientId" formControlName="clientId" aria-describedby="emailHelp">
                </div>
                <!-- Button trigger modal -->
                @if (title != "Editar") {
                  <button type="button" class="btn btn-info col-1" style="margin-top: 20px; height: 39px;" data-bs-toggle="modal" data-bs-target="#modalCustomer"><i class="bi bi-search"></i></button>
                }
              </div>
              @if (formPromotions.get('clientName')?.touched && formPromotions.get('clientName')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 3) && (formPromotions.get('ruler')?.value == 3 || formPromotions.get('ruler')?.value == 4)
            ) {
            <div class="col-12 col-md-6 mb-3">
              <label for="quantity">Cantidad</label>
              <input type="text" class="form-control" id="quantity" formControlName="quantity" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('quantity')?.touched && formPromotions.get('quantity')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }
         
          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 3) && formPromotions.get('ruler')?.value == 3
            ) {
            <div class="col-12 col-md-6 mb-3">
              <div class="row col-12">
                <div class="col-11">
                  <label for="productCodeProm">Código producto promoción</label>
                  <input type="text" class="form-control" id="productCodeProm" formControlName="productCodeProm" aria-describedby="emailHelp">
                  <input type="text" class="form-control d-none" id="productCodePromId" formControlName="productCodePromId" aria-describedby="emailHelp">
                </div>
                <!-- Button trigger modal -->
                @if (title != "Editar") {
                  <button type="button" class="btn btn-info col-1" style="margin-top: 20px; height: 39px;" (click)="isProductPromo = true" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="bi bi-search"></i></button>
                }
              </div>
              @if (formPromotions.get('productCodeProm')?.touched && formPromotions.get('productCodeProm')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 3) && formPromotions.get('ruler')?.value == 3
            ) {
            <div class="col-12 col-md-6 mb-3">
              <label for="quantityPromtion">Cantidad promoción</label>
              <input type="number" class="form-control" id="quantityPromtion" formControlName="quantityPromtion" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('quantityPromtion')?.touched && formPromotions.get('quantityPromtion')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 3) && formPromotions.get('ruler')?.value == 4
            ) {
            <div class="col-12 col-md-6 mb-3">
              <label for="amountPromtion">Monto promoción</label>
              <input type="number" class="form-control" id="amountPromtion" formControlName="amountPromtion" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('amountPromtion')?.touched && formPromotions.get('amountPromtion')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 2 || formPromotions.get('typePromotion')?.value == 3 || formPromotions.get('typePromotion')?.value == 4) && formPromotions.get('ruler')?.value == 2
            )  {
            <div class="col-12 col-md-6 mb-3">
              <label for="discountAmount">Monto descuento</label>
              <input type="number" class="form-control" id="discountAmount" formControlName="discountAmount" aria-describedby="emailHelp" placeholder="">
              @if (formPromotions.get('discountAmount')?.touched && formPromotions.get('discountAmount')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un código.</p>
              }
            </div>
          }

          @if (
            (formPromotions.get('typePromotion')?.value == 1 || formPromotions.get('typePromotion')?.value == 2 || formPromotions.get('typePromotion')?.value == 3 || formPromotions.get('typePromotion')?.value == 4) && formPromotions.get('ruler')?.value == 1
            ) {
            <div class="col-12 col-md-6 mb-3">
              <label for="percentage">% Descuento</label>
              <input type="number" class="form-control" id="percentage" formControlName="percentage" aria-describedby="emailHelp" placeholder="Ingresa % descuento">
              @if (formPromotions.get('percentage')?.touched && formPromotions.get('percentage')?.errors?.['required']) {
                <p class="text-danger">Por favor, ingrese un nombre.</p>
              }
            </div>
          }

          <div>
            <span class="example-list-section fw-bold ">
              <mat-checkbox class="example-margin" formControlName="status"
              color="primary"
              >
                <p class="fw-bold ">Activo</p>
              </mat-checkbox>
            </span>
          </div>
        </div>
      </div>
    </form>

    <div class="d-flex justify-content-end p-5">
      <div class="page-btn me-3" >
        <a [routerLink]="['/home']" class="btn btn-light">
          Cancelar
        </a>
      </div>
      <div class="page-btn">
        <button type="button" class="btn btn-info" (click)="savePromotion()">
          @if (title == 'Nueva') {Crear promoción }
          @else {Editar promoción}
        </button>
      </div>
    </div>
  </div>
</div>

 <!-- MODAL PRODUCTOS -->
 <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="staticBackdropLabel">Productos</h2>
        <button type="button" id="btn-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <div class="col-12 col-md-8">
            <input [(ngModel)]="inputFilter" (keyup)="filterProduct()" type="text" class="form-control" id="searchTerm" placeholder="Buscar por nombre o código de producto.">
          </div>
        </div>
        <hr>
        <p-table [value]="productsData" class="table  datanew" dataKey="code" >
          <ng-template pTemplate="header">
            <tr>
              <th>Código Producto</th>
              <th>Nombre Producto</th>
              <th>Costo</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr style="cursor: pointer;" (click)="selectProduct(product)">
              <td >{{ product.code }} </td>
              <td >{{ product.name }} </td>
              <td>{{ product.cost | currency: 'Q ' : 'code' }} </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

 <!-- MODAL CLIENTES -->
 <div class="modal fade" id="modalCustomer" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="staticBackdropLabel">Clientes</h2>
        <button type="button" id="btn-close-customer" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <div class="form-group row">
          <div class="col-12 col-md-8">
            <input [(ngModel)]="inputFilterCustomer" (keyup)="filterCustomer()" type="text" class="form-control" id="searchTerm" placeholder="Buscar por nombre de cliente.">
          </div>
        </div>
        <hr>
        <p-table [value]="customersList" class="table  datanew" dataKey="code" >
          <ng-template pTemplate="header">
            <tr>
              <th>Nombre de cliente</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-customer>
            <tr style="cursor: pointer;" (click)="selectCustomer(customer)">
              <td >{{ customer.name }} </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>






<p-toast>
</p-toast>
