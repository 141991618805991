import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { registerables } from 'chart.js';

@Component({
  selector: 'app-pie-chart',
  template: '<canvas id="pieChart"  width="900" height="800"></canvas>',
})
export class PieChartComponent implements OnInit {
  @Input() data: any;
  ngOnInit() {
    Chart.register(...registerables);
    const ctx = document.getElementById('pieChart') as HTMLCanvasElement;
    new Chart('pieChart', {
      type: 'pie',
      data: {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.datasets[0].data,
            backgroundColor: [
              'red',
              'blue',
              'green',
              'yellow',
              'orange',
              'purple',
              'pink',
              'brown',
              'gray',
              '#FA7070',
              'white',
              '#5FBDFF',
              'magenta',
              'lime',
            ],
            borderWidth: 1,
          },
        ],
      },
    });
  }
}
