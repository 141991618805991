import { Component, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { cashClosingHistoricalData } from '../_models/cashClosingHistorical/cashClosingHistoricalData';
import { CashClosingHistoricalService } from '../_services/cashClosingHistorical/cash-closing-historical.service';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { SalesService } from '../_services/sales/sales.service';
import { cashHistoricalCategories } from '../_models/cashClosingHistorical/cashHistoricalCategories';
import { cashMovements } from '../_models/cashClosing/cashMovements';
import { CashClosingService } from '../_services/cashClosing/cash-closing.service';

@Component({
  selector: 'app-cash-closing-historical',
  templateUrl: './cash-closing-historical.component.html',
  styleUrl: './cash-closing-historical.component.css'
})
export class CashClosingHistoricalComponent implements OnInit {
  public selectedAction = '';
  actionsOptions: dataCompany[] = [
    { value: 'Cancelar Auditoria' },
    { value: 'Resumen de Auditoria' }
  ];
  currency! : String;
  selectedCashName! : String;
  branchName : String = localStorage.getItem('branch') + '';
  ExpectedAmountList : Array<cashHistoricalCategories> = []; 
  public tableData: Array<cashClosingHistoricalData> = [];
  public paymentMethodsDetails: Array<cashHistoricalCategories> = [];
  cashMovementsList: Array<cashMovements> = [];
  selectedItem! : cashClosingHistoricalData;
  currentComments! : String;
  currentDate : String = new Date().toLocaleString()
  constructor(private cashRegisterService: CashClosingHistoricalService, private salesService : SalesService, private cashClosingService : CashClosingService,  private messageService: MessageService, private formBuilder: FormBuilder){

  }
  ngOnInit(): void {
    this.getCurrency();
    this.getCashClosingHistorical();
  }
  getAction(){}
  getCashClosingHistorical() {
    this.cashRegisterService.getCashClosingHistorical()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.tableData = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  closeCashResume(){

  }
  printCashResume(){}
  getCashPaymentMethods(cashResumeId: number ) {
    this.cashRegisterService.paymentMethods(cashResumeId)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.paymentMethodsDetails = [];
            this.paymentMethodsDetails = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente de nuevo.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCashMovements(cashResumeId: number ) {
    this.cashRegisterService.getCashMovements(cashResumeId)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.cashMovementsList = [];
            this.cashMovementsList = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente de nuevo.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  showCashClosingResume(cashResumeId : number, cashName : string, comments : String) {
    document.getElementById('show-modal-cash-closing-details')?.click();
    this.currentComments = comments;
    this.getCashPaymentMethods(cashResumeId);
    this.getCashMovements(cashResumeId);
    this.selectedCashName = cashName;
  }
}
