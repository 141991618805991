import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  login(body: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}login`,body,{headers: header});
  }

  sendEmail(body: Object) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}resetPassword`,body,{headers: header});
  }

  getCash(companyId: number) {
    return this.http.get<any>(`${environment.url}Companies/getCash?companyId=${companyId}`);
  }
}
