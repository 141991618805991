import { Component} from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/_services/common/common.service';
import { ProductsService } from 'src/app/_services/products/products.service';
import { Cookies } from 'typescript-cookie';


@Component({
  selector: 'app-purchase-upload',
  templateUrl: './purchase-upload.component.html',
  styleUrl: './purchase-upload.component.css'
})

export class PurchaseUploadComponent{

  archivo!: FormData;
  fileName: string = "";

  constructor(
    private productSvr: ProductsService,
    private messageService: MessageService,
    private coomonSrv: CommonService,
    private fb: FormBuilder

  ) { }

  openFileInputProducts() {
    (document.getElementById('fileInputProducts') as HTMLInputElement).click();
  }

  selectFileProducts(file: FileList) {
    this.fileName = file[0].name;
    this.archivo = new FormData();
    this.archivo.append('excelFile',file[0]);
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
  }

  uploadInvoice() {
    this.productSvr.uploadInvoice(this.archivo, parseInt(Cookies.get('branchId')!.toString()), parseInt(this.coomonSrv.companyId), parseInt(Cookies.get('userId')!.toString()) )
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.showToastOk('Factura cargada satisfactoriamente.');
          (document.getElementById('fileInputProducts') as HTMLInputElement).value = '';
          this.fileName = '';
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: value.data + ' Favor de verificar.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al cargar factura, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

}
