import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { dataCompany } from '../_models/dataCompany';
import { Sort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { BranchesService } from '../_services/branches/branches.service';
import { CompaniesService } from '../_services/companies/companies.service';
import { BranchesList } from '../_models/branches/branchesList';
import { companiesList } from '../_models/companiesList';

@Component({
  selector: 'app-branch-office',
  templateUrl: './branch-office.component.html',
  styleUrls: ['./branch-office.component.css']
})
export class BranchOfficeComponent {

  first = 0;
  rows = 10;
  isCollapsed: boolean = false;
  initChecked = false;
  public searchDataValue = '';
  public selectedValue1 = '';
  public selectedValue2 = '';
  public selectedValue3 = '';
  public tableData: Array<companiesList> = [];
  dataSource!: MatTableDataSource<companiesList>;
  selectedList1: dataCompany[] = [
    { value: 'Sort by Date' },
    { value: 'Newest' },
    { value: 'Oldest' },
  ];
  selectedList2: dataCompany[] = [
    { value: 'Choose Stores' },
    { value: 'Thomas' },
    { value: 'Rasmussen' },
    { value: 'Benjamin' },
  ];
  selectedList3: dataCompany[] = [
    { value: 'Choose Status' },
    { value: 'Active' },
    { value: 'Inactive' },
  ];

  branchesList: BranchesList[] = [];
  companiesList : companiesList[] = [];

  constructor(
    private branchesService: BranchesService, private companiesService: CompaniesService
  ) {
    this.getBranches();
  }

  toggleCollapse() {
    // this.sidebar.toggleCollapse();
    this.isCollapsed = !this.isCollapsed;
  }
  public filter = false;
  openFilter() {
    this.filter = !this.filter;
  }
  public searchData(value: string): void {
    this.dataSource.filter = value.trim().toLowerCase();
    this.tableData = this.dataSource.filteredData;
  }
  public sortData(sort: Sort) {
    const data = this.tableData.slice();
    if (!sort.active || sort.direction === '') {
      this.tableData = data;
    } else {
      this.tableData = data.sort((a, b) => {
        const aValue = (a as never)[sort.active];
        const bValue = (b as never)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }
  selectAll(initChecked: boolean) {
    if (!initChecked) {
      this.tableData.forEach((f: any) => {
        f.isSelected = true;
      });
    } else {
      this.tableData.forEach((f: any) => {
        f.isSelected = false;
      });
    }
  }
  confirmColor() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: ' btn btn-success',
        cancelButton: 'me-2 btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  getBranches() {
    this.branchesService.getBranches()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.branchesList = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  getCompaniesList() {    
    this.companiesService.getCompanies('', '')
      .subscribe(data => {
        if (data.response == 'Ok') {
          this.companiesList = data.data;
        }
        else {

        }
      });
  }

}
