import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor(private http: HttpClient) { }
  getCompanies(searchTerm: String, shortTerm: String) {
    return this.http.get<any>(`${environment.url}companies?searchTerm=`+ searchTerm + '&shortTerm=' +shortTerm);
  }
  getCompany(id : number) {
    return this.http.get<any>(`${environment.url}companies/`+ id);
  }
  addOrUpdateCompay(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}companies`,body,{headers: header});
  }
  download(): Observable<Blob> {
    return this.http.get(`${environment.url}companies/download`, {
      responseType: 'blob'
    })
  }
  uploadFile(body: FormData) {
    return this.http.post<any>(`${environment.url}companies/uploadTemplate`, body);
  }
}