<section style="margin-bottom: 10px;">
  <div class="row col-12 d-flex justify-content-between">
    <h2 class="col-12 col-md-4 fw-bold mt-3">Unidades de Medida</h2>

    <div class="col-12 col-md-4 text-end">
      <a class="btn btn-info" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-edit-modal">
        <i class="feather icon-plus-circle me-2"></i> Agregar Unidad de Medida
      </a>
    </div>
  </div>
</section>

<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive">
      <p-table [value]="units"
      class="table datanew"
      dataKey="code"
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} unidades"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]">

        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>UserId</th>
            <th>Status</th>
            <th>CreatedAt</th>
            <th>UpdatedAt</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-unit>
          <tr>
            <td>{{ unit.idUnidadMedida }} </td>
            <td>{{ unit.cUnidadMedida }} </td>
            <td>{{ unit.idUsuarioCreacion }} </td>
            <td>{{ unit.nEstatus == 1 ? 'Activo' : 'Inactivo' }} </td>
            <td>{{ unit.dFechaCreacion }} </td>
            <td>{{ unit.dFechaModificacion }} </td>
            <td><a class="btn btn-info" (click)="getUnit(unit.idUnidadMedida)"><i class="feather icon-edit"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<div class="modal fade" id="add-edit-modal" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalFullscreenLabel">Crear Unidad de Medida</h4>
        <button #closebutton type="button" (click)="closeAddOrEditForm()" class="btn-close" data-bs-dismiss="modal" id="modal-close-button" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-12">
            <form class="pb-2" [formGroup]="unitForm">
              <div class="card mb-0">
                <div class="card-body add-product pb-0">
                  <div class="accordion-card-one accordion" id="accordionExample">
                    <div class="accordion-item">
                      <div class="accordion-header" id="headingOne">
                        <div class="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                          <div class="addproduct-icon">
                            <h5>
                              <i class="feather icon-info add-info text-info"></i><span>Datos de Unidad de Medida</span>
                            </h5>
                            <a href="javascript:void(0);"><i class="feather icon-chevron-down chevron-down-add"></i></a>
                          </div>
                        </div>
                      </div>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <div class="row">
                            <div class="col-lg-6 col-sm-12 col-12">
                              <div class="mb-3 add-product">
                                <label class="form-label">Nombre de Unidad de Medida</label>
                                <input type="text" class="form-control" name="cUnidadMedida" formControlName="cUnidadMedida" />
                                <div *ngIf="!unitForm.get('cUnidadMedida')?.valid && (unitForm.get('cUnidadMedida')?.dirty || unitForm.get('cUnidadMedida')?.touched)" class="invalid-feedback d-block">
                                  Por favor, ingrese un nombre.
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 col-12">
                              <div class="mb-3 add-product">
                                <label class="form-label">Estado</label>
                                <select class="form-control" name="nEstatus" formControlName="nEstatus">
                                  <option value="1" selected>Activo</option>
                                  <option value="0">Inactivo</option>
                                </select>
                                <div *ngIf="!unitForm.get('nEstatus')?.valid && (unitForm.get('nEstatus')?.dirty || unitForm.get('nEstatus')?.touched)" class="invalid-feedback d-block">
                                  Por favor, seleccione un estado.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light me-2" (click)="closeAddOrEditForm()">Cancelar</button>
        <button type="button" (click)="addOrUpdateUnit(0)" class="btn btn btn-info">Crear y Guardar</button>
      </div>
    </div>
  </div>
</div>

<p-toast></p-toast>
