import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransfersService {

  constructor(
    public http: HttpClient
  ) { }

  getTransfers(orderBy: string, url: string) {
  
    return this.http.get<any>(`${environment.url}transfers?orderBy=${orderBy}${url}`);
  }

  getTransferById(idTrasnfer: number) {
    return this.http.get<any>(`${environment.url}transfers/getTransferById?id=${idTrasnfer}`);
  }

  getTypeTransfers() {
    return this.http.get<any>(`${environment.url}transfers/getTypeTransfers`);
  }

  createTransfer(data: FormData) {
    return this.http.post<any>(`${environment.url}transfers`,data);
  }

}
