import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//services
import { SalesService } from '../_services/sales/sales.service';

//interfaces
import { ListProducts, SaleDetail } from '../_models/sales/saleDetail';

//libreries
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-sales-list-detail',
  templateUrl: './sales-list-detail.component.html',
  styleUrls: ['./sales-list-detail.component.css']
})
export class SalesListDetailComponent implements OnInit {


  isReturn: boolean = false;
  isCancellation: boolean = false;

  formSale!: FormGroup;
  generalData: SaleDetail[] = [];
  saleProducts!: ListProducts[];
  currency: string = "";

  pFactureParams: number | null = null;
  uuidpFacture: string | null = null;
  salesId: number = 0;

  ticketLogo: String = '';
  currentDateString: string = '';
  subtotalPrice: number = 0;
 

  constructor(
    private fb: FormBuilder,
    private salesSrv: SalesService,
    private messageService: MessageService,
  ) { 
    
  }

  ngOnInit(): void {
    this.initializeForm();
  }


  @Input ()
  set id(saleId: number) {
    if(saleId != undefined) {
      this.salesId = saleId;
      this.getSaleById(saleId);
      this.getCurrency();
    }
  }

  initializeForm() {
    this.formSale = this.fb.group({
      returnType:[],
      reason: [1, Validators.required],//motivo de retorno
      comment: ['', Validators.required],
      saleId: [''],//4
      returnAmount: [0],
      user: [''],
      products: Array<ListProducts>,
      
      date: [''],
      nit: [''],
      customerName: [''],
      amount: [0],
      status: [''],
      registrationUser: [''],
      returnId: [null]
    })
  }

  getSaleById(id: number) {
    this.salesSrv.getSaleById(id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.generalData = [];
          this.generalData.push(value.data);

          this.subtotalPrice = this.generalData[0].amount;
          this.currentDateString = this.generalData[0].date.toString();

          this.pFactureParams = this.generalData[0].pFacture;
          this.uuidpFacture = this.generalData[0].uuidpFacture;

          this.saleProducts = value.data.products;

          this.formSale.patchValue(value.data);
          
          if(value.data.returnType == 2) {
            this.formSale.patchValue({returnAmount: value.data.amount});
            this.salesCancellation();
          }
          if(value.data.returnType == 3) { //devolución
            let quantity = 0;
            this.isCancellation = true;
            this.saleProducts.forEach(product => {
              quantity += product.returnQuantity * product.priceProduct;
            });
            this.formSale.patchValue({returnAmount: quantity});
          }
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener el detalle de venta, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener el detalle de venta, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  salesReturn() {
    this.isReturn = true;
    this.isCancellation = false;
    this.formSale.patchValue({returnType: 3,returnAmount: 0})
    this.saleProducts.map(product => {
      product.returnQuantity = 0;
      return product;
    })
  }

  validateQuantityReturn(id: number) {
    let product = this.saleProducts.find(x =>  x.saleDetailId == id)?.quantitySold;
    let returnQuantity = (document.getElementById(id.toString()) as HTMLInputElement);

    if(product! >= parseInt(returnQuantity.value)) {
      this.saleProducts = this.saleProducts.map(x => { 
        if(x.saleDetailId == id) {
          let returnAmount = this.formSale.get('returnAmount')?.value ?? 0;

          if(x.returnQuantity < parseInt(returnQuantity.value)) {
            x.returnQuantity = parseInt(returnQuantity.value);  
            returnAmount += x.returnQuantity * x.priceProduct;
          }
          else {
            returnAmount -= x.returnQuantity * x.priceProduct;
            x.returnQuantity = parseInt(returnQuantity.value);  
          }
          this.formSale.patchValue({returnAmount:returnAmount});
          return x
        }
        return x;
      });
    }
    else {
      this.saleProducts = this.saleProducts.map(x => { 
        if(x.saleDetailId == id) {
          x.returnQuantity = product!;  
          returnQuantity.value = product!.toString();
          return x;
        }
        return x;
      });
    } 
  }

  salesCancellation() {
    this.isReturn = false;
    this.isCancellation = true;
    this.formSale.patchValue({returnType: 2,returnAmount: 0});
    let quantity = 0;
    this.saleProducts.map(product => {
      product.returnQuantity = product.quantitySold;
      quantity += product.returnQuantity * product.priceProduct;
      return product;
    })
    this.formSale.patchValue({returnAmount: quantity});
  }

  cancelDTE() {
    const { comment } = this.formSale.value;

    this.salesSrv.cancelDTE(this.salesId,comment)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.showToastOk('Cancelación realizada con éxito.');
          this.getSaleById(this.salesId);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al cancelar la compra, favor de intentar más tarde.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al cancelar la compra, favor de intentar más tarde.',
            styleClass: 'danger-background-popover',
          });
      },
    })
  }

  sentData() {
    this.formSale.markAllAsTouched();
    if(this.formSale.valid) {
      this.salesSrv.cancellationOrReturnSale(this.formSale.value)
      .subscribe({
        next:(value) => {
          if(value.response == 'Ok') {
            if(this.isReturn) {
              this.showToastOk('Devolución realizada con éxito.');
              this.getSaleById(this.salesId);
            }
            else {
              if(this.pFactureParams == 1 && this.uuidpFacture != null) {
                this.cancelDTE();
              }
              else {
                this.showToastOk('Cancelación realizada con éxito.');
              this.getSaleById(this.salesId);

              }
              
            }
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
        },
      })
    }
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }

  getCurrency() {
    this.salesSrv.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos de móneda, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

  viewDTE() {
    window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + this.salesId, "_blank");
  }

  generateDTE() {
    this.salesSrv.generateDTE(this.salesId)
    .subscribe({
      next:(value) => {
        debugger
        if(value.response == 'Ok') {
          this.getSaleById(this.salesId);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al generar la factura, favor de intentar más tarde.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
    
  }

  getTicketLogo() {
    this.salesSrv.getTicketLogo()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.ticketLogo = result.data.logoTicket;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

  showPrintTicketModal() {
    this.getTicketLogo();
    document.getElementById('show-ticket-modal')?.click();
  }

  printTicket() {
    const printContent = document.getElementById('ticket-container-to-print');
    const WindowPrt = window.open('', '', 'left=0,top=50,width=600,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt!.document.write("<style>body { width:72mm;max-width:72mm } table { font-size: small !important} .ticket{font-size:12px !important;font-family:'Times New Roman';text-align:center;width:72mm;max-width:72mm}.ticket>table,.ticket>td,.ticket>th,.ticket>tr{border-top:1px solid #000;border-collapse:collapse}.ticket>td.description,.ticket>th.description{width:40mm;max-width:40mm}.ticket>td.quantity,.ticket>th.quantity{width:10mm;max-width:10mm;word-break:break-all}.ticket>td.price,.ticket>th.price{width:22mm;max-width:22mm;word-break:break-all}.ticket>.centered{text-align:center;align-content:center}.ticket>img{max-width:40mm;width:inherit;align-self:center}</style>");
    WindowPrt!.document.write(printContent!.outerHTML);
    WindowPrt!.document.close();
    setTimeout(() => {
      WindowPrt!.focus();
      WindowPrt!.print();
      WindowPrt!.close();
    },
      1000);
  }

}
