import { Component, OnInit, Input } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-double-line-chart',
  templateUrl: './double-line-chart.component.html',
  styleUrls: ['./double-line-chart.component.css'],
})
export class DoubleLineChartComponent implements OnInit {
  @Input() data: any;

  ngOnInit() {
    Chart.register(...registerables);

    const ctx1 = document.getElementById('lineChart1') as HTMLCanvasElement;
    console.log('data');
    console.log(this.data);
    if (ctx1) {
      new Chart(ctx1, {
        type: 'line',
        data: {
          labels: this.data.labels,
          datasets: [
            {
              label: 'Ventas',
              backgroundColor: 'rgba(75,192,192,0.1)',
              borderColor: 'rgba(75,192,192,1)',
              data: this.data.datasets[0].data,
              fill: true,
            },
            {
              label: 'Objetivo',
              backgroundColor: 'rgba(153,102,255,0.1)',
              borderColor: 'rgba(153,102,255,1)',
              data: this.data.datasets[1].data,
              fill: true,
            },
          ],
        },
      });
    }
  }
}
