<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Clientes</h4>
        </div>
    </div>
    <!-- <div class="page-btn">
        <a (click)="downloadTemplateFile()" class="btn btn-dark"><i
                class="feather icon-arrow-up-circle me-2"></i>Descargar Plantilla</a>
    </div> -->
    <div class="page-btn">
        <a href="javascript:void(0);" class="btn btn-light" (click)="excelDocument.click()"><i
                class="feather icon-book me-2"></i> Importar Clientes </a>
    </div>
    <div class="page-btn">
        <a class="btn btn-info d-none" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#create-customer-modal"><i
                class="feather icon-plus-circle me-2"></i> Agregar Cliente </a>
                <a class="btn btn-info" id="addeditmodalTarget" (click)="resetModal()"><i
                    class="feather icon-plus-circle me-2"></i> Agregar Cliente </a>
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="table-top">
            <div class="search-set">
                <div class="search-input">
                    <a href="javascript:void(0);" (click)="getCustomerList()" class="btn btn-searchset"><i
                            class="feather icon-search feather-search"></i></a>
                    <div class="dataTables_filter">
                        <label>
                            <input [(ngModel)]="searchDataValue" [ngModelOptions]="{standalone: true}"
                                 type="search" class="form-control"
                                placeholder="Filtrar por nombre, teléfono o email" />
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-sort">
                <i class="feather icon-sliders info-img"></i>
                <mat-select [(ngModel)]="selectedValue1" (selectionChange)="getCustomerList()" [ngModelOptions]="{standalone: true}"
                    class="select space-select" placeholder="Ordenar">
                    <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                        <mat-option [value]="data.value">
                            {{data.value}}
                        </mat-option>
                    </ng-template>
                </mat-select>
            </div>
        </div>
        <!-- /Filter -->
        <div class="table-responsive">
            <p-table [value]="tableData" selectionMode="single" [(selection)]="selectedCustomerA" class="table  datanew" dataKey="id" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>Nombre</th>
                        <th>Teléfono</th>
                        <th>Email</th>
                        <th>NIT</th>
                        <th>Deuda</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                        </td>
                        <td>{{customer.name}}</td>
                        <td>{{customer.phoneNumber}} </td>
                        <td>{{customer.email}} </td>
                        <td>{{customer.nit}} </td>
                        <td>{{customer.debt?? 0}} </td>
                        <td><a class="btn btn-info" (click)="getCustomer(customer.id)"><i class="feather icon-edit"></i></a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <app-custom-pagination></app-custom-pagination> -->
    </div>
</div>
<div class="modal fade" id="create-customer-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">{{ selectedCustomer ? 'Actualizar Cliente' : ' Crear Cliente' }}</h4>
                <button type="button" #closebuttonCustomer class="btn-close" data-bs-dismiss="modal"
                    id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row h-100">
                    <div class="col-12">
                        <ul class="nav nav-tabs mb-3 border-bottom-0" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-bs-toggle="tab" role="tab" href="#home1a"
                                    aria-selected="true">Datos Básicos</a>
                            </li>                            
                            <li class="nav-item">
                                <a class="nav-link" data-bs-toggle="tab" role="tab" href="#about2a"
                                    aria-selected="false">Historico de Ventas</a>
                            </li>
                        </ul>
                        <form class="pb-2" [formGroup]="customerForm">
                            <div class="tab-content">
                                <div class="tab-pane show active text-muted" id="home1a" role="tabpanel">
                                    <div class="row h-100">
                                        <div class="col-12">
                                            <div class="card mb-0">
                                                <div class="card-body add-product pb-0">
                                                    <div class="accordion-card-one accordion" id="accordionExample">
                                                        <div class="row">
                                                            
                                                            
                                                            <div class="col-lg-6 col-sm-12 col-12">
                                                                <div class="mb-3 add-product">
                                                                    <label class="form-label">NIT</label>
                                                                    <input type="text" class="form-control" name="nit"
                                                                        formControlName="nit" (keydown.enter)="validateCustomerIdentifier($event)" (change)="validateCustomerIdentifier($event)"/>
                                                                    <div *ngIf="!customerForm.get('nit')?.valid && (customerForm.get('nit')?.dirty ||customerForm.get('nit')?.touched)"
                                                                        class="invalid-feedback d-block">
                                                                        Por favor, ingrese un NIT válido.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class="col-lg-6 col-sm-12 col-12">
                                                                <div class="mb-3 add-product">
                                                                    <label class="form-label">Nombre completo</label>
                                                                    <input type="text" class="form-control" name="name"
                                                                        placeholder="Ingresar Nombre"
                                                                        formControlName="name" />
                                                                        <input type="password" class="form-control d-none" name="id"
                                                                        formControlName="id" />
                                                                    <div *ngIf="!customerForm.get('name')?.valid && (customerForm.get('name')?.dirty ||customerForm.get('name')?.touched)"
                                                                        class="invalid-feedback d-block">
                                                                        Por favor, ingrese un nombre.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!--<div class="col-lg-6 col-sm-12 col-12">
                                                                <div class="mb-3 add-product">
                                                                    <label class="form-label">Apellido</label>
                                                                    <input type="text" class="form-control"
                                                                        name="lastName" placeholder="Ingresar Apellido"
                                                                        formControlName="lastName" />
                                                                    <div *ngIf="!customerForm.get('lastName')?.valid && (customerForm.get('lastName')?.dirty ||customerForm.get('lastName')?.touched)"
                                                                        class="invalid-feedback d-block">
                                                                        Por favor, ingrese un apellido.
                                                                    </div>
                                                                </div>
                                                            </div>-->
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <label for="phoneNumber" class="mb-2 form-label">Número
                                                                    de Teléfono</label>
                                                                <input type="text" id="phone" class="form-control"
                                                                    mask="(000) 000-0000" [showMaskTyped]="true"
                                                                    name="phoneNumber" formControlName="phoneNumber">
                                                                <span class="form-text text-muted">(999) 999-9999</span>
                                                                <div *ngIf="!customerForm.get('phoneNumber')?.valid && (customerForm.get('phoneNumber')?.dirty ||customerForm.get('phoneNumber')?.touched)"
                                                                    class="invalid-feedback d-block">
                                                                    Por favor, ingrese un número de telefono válido.
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <div class="mb-3 add-product">
                                                                    <label class="form-label">Email</label>
                                                                    <input type="email" placeholder="Ingresar email"
                                                                        class="form-control" name="email"
                                                                        formControlName="email" />
                                                                    <div *ngIf="!customerForm.get('email')?.valid && (customerForm.get('email')?.dirty ||customerForm.get('email')?.touched)"
                                                                        class="invalid-feedback d-block">
                                                                        Por favor, ingrese un Email válido.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12">
                                                                <input class="form-check-input" type="checkbox" id="creditCustomer" formControlName="creditCustomer" style="width: 20px;height: 20px;">
                                                                <label class="form-label ms-2 fw-bold" for="creditCustomer" [ngStyle]="{'color': !editCustomer ? '#afafaf' : '#5B6670'}"> 
                                                                  Cliente Crédito
                                                                </label>
                                                            </div>

                                                            @if(customerForm.get('creditCustomer')?.value) {
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <div class="mb-3 add-product">
                                                                        <label class="form-label">Límite de Crédito</label>
                                                                        <p-inputGroup>
                                                                            <p-inputGroupAddon>{{currency}}</p-inputGroupAddon>
                                                                            <input type="number" pInputText class="form-control" name="creditLimit" placeholder="0.00" (change)="limitDecimal()" formControlName="creditLimit"  />
                                                                        </p-inputGroup>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <div class="mb-3 add-product">
                                                                        <label class="form-label" for="creditDays">Días de crédito</label>
                                                                        <select class="form-select h-100" aria-label="multiple select example" formControlName="creditDays">
                                                                            <option value="30"> 30 días </option>
                                                                            <option value="45"> 45 días </option>
                                                                            <option value="60"> 60 días </option>
                                                                            <option value="72"> 72 días </option>
                                                                            <option value="90"> 90 días </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-sm-12 col-12">
                                                                    <div class="mb-3 add-product">
                                                                        <label class="form-label">Saldo disponible</label>
                                                                        <p-inputGroup>
                                                                            <p-inputGroupAddon>{{currency}}</p-inputGroupAddon>
                                                                            <input type="number" pInputText class="form-control" name="balance" placeholder="0.00" formControlName="balance" readonly  />
                                                                        </p-inputGroup>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <div class="mb-3 add-product">
                                                                    <label class="form-label">Comentarios</label>
                                                                    <textarea
                                                                        placeholder="Agrega información adicional de tu cliente"
                                                                        type="text" rows="4" class="form-control"
                                                                        name="comments"
                                                                        formControlName="comments"></textarea>
                                                                    <div *ngIf="!customerForm.get('comments')?.valid && (customerForm.get('comments')?.dirty ||customerForm.get('comments')?.touched)"
                                                                        class="invalid-feedback d-block">
                                                                        Por favor, ingrese un valor válido.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane text-muted" id="about1a" role="tabpanel">
                                    <div class="card mb-0">
                                        <div class="card-body add-product pb-0">
                                            <div class="accordion-card-one accordion" id="accordionExample">
                                                <div class="row">
                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                        <div class="mb-3 add-product" style="display: none;">
                                                            <label class="form-label">Empresa</label>
                                                            <input type="text" class="form-control" name="businessName"
                                                                formControlName="businessName" />
                                                            <div *ngIf="!customerForm.get('businessName')?.valid && (customerForm.get('businessName')?.dirty ||customerForm.get('businessName')?.touched)"
                                                                class="invalid-feedback d-block">
                                                                Por favor, ingrese nombre de empresa.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <!--<div class="col-lg-6 col-sm-12 col-12">
                                                        <div class="mb-3 add-product" style="display: none;">
                                                            <label class="form-label">Código Postal</label>
                                                            <input maxlength="6" type="text" class="form-control"
                                                                name="postalCode" formControlName="postalCode" />
                                                            <div *ngIf="!customerForm.get('postalCode')?.valid && (customerForm.get('postalCode')?.dirty ||customerForm.get('postalCode')?.touched)"
                                                                class="invalid-feedback d-block">
                                                                Por favor, ingrese código postal valido.
                                                            </div>
                                                        </div>
                                                    </div>-->
                                                    
                                                    <!--<div class="col-lg-6 col-sm-12 col-12">
                                                        <div class="mb-3 add-product">
                                                            <label class="form-label">Régimen Fiscal</label>
                                                            <input type="text" class="form-control" name="taxRegime"
                                                                placeholder="" formControlName="taxRegime" />
                                                            <div *ngIf="!customerForm.get('taxRegime')?.valid && (customerForm.get('taxRegime')?.dirty ||customerForm.get('taxRegime')?.touched)"
                                                                class="invalid-feedback d-block">
                                                                Por favor, ingrese un Régimen Fiscal.
                                                            </div>
                                                        </div>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane text-muted" id="about2a" role="tabpanel">
                                    <div class="table-responsive" style="min-height: 500px;">
                                        <div class="table-top row m-0 p-0 mb-4">
                                            <div class="search-set col-12">
                                              <div class="row w-100">
                                  
                                                <div class="dataTables_filter col-12 col-md-4">
                                                  <p class="m-0">Fecha inicio</p>
                                                  <p-calendar class="col-12" [(ngModel)]="dateStart"  [ngModelOptions]="{standalone: true}" [iconDisplay]="'input'" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                                                </div>
                                  
                                                <div class=" col-12 col-md-4">
                                                  <p class="m-0">Fecha fin</p>
                                                  <p-calendar [(ngModel)]="dateEnd" [iconDisplay]="'input'" [ngModelOptions]="{standalone: true}" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                                                </div>
                                  
                                                <div class="mt-3 col-12 col-md-4">
                                                  <button type="button" (click)="getSales(this.customerForm.get('id')?.value)" class="btn btn btn-info">Aplicar filtro</button>
                                                </div>
                                  
                                              </div>
                                            </div>
                                            <!-- <div class="form-sort col-12 col-md-3">
                                                <i class="feather icon-sliders info-img"></i>
                                                 <mat-select [(ngModel)]="selectedValue1" (selectionChange)="getSales()" [ngModelOptions]="{standalone: true}"
                                                  class="select space-select" placeholder="Ordenar">
                                                  <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                                                      <mat-option [value]="data.value">
                                                          {{data.value}}
                                                      </mat-option>
                                                  </ng-template>
                                                </mat-select>
                                            </div> -->
                                          </div>
                                          <!-- /Filter -->
                                        <p-table  
                                        [value]="salesList"
                                        class="table datanew" 
                                        dataKey="code" 
                                        [tableStyle]="{'min-width': '50rem'}"
                                        [paginator]="true"
                                        [rows]="10"
                                        [showCurrentPageReport]="true"
                                        [tableStyle]="{ 'min-width': '50rem' }"
                                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} ventas"
                                       
                                        [rowsPerPageOptions]="[10, 25, 50]"
                                        
                                        >
                                          <ng-template pTemplate="header">
                                            <tr>
                                              <th>Fecha</th>
                                              <th>Folio</th>
                                              <th>Factura</th>
                                              <th>Monto</th>
                                              <th>Descuento</th>
                                              <th>Estatus</th>
                                              <th>Metodo de Pago</th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-sales>
                                            <tr>
                                              <td>{{ sales.date | date: 'dd/MM/yyyy'}} </td>
                                              <td> {{ sales.folio }} </td>
                                              <td>{{ sales.clienName }} </td>            
                                              <td>{{sales.ammount| currency: currency + '':'symbol' : '1.2-2'}} </td>
                                              <td>{{sales.discountAmmount | currency: currency + '':'symbol' : '1.2-2'}} </td>
                                              <td>{{ sales.status }} </td>
                                              <td>{{ sales.userRegister }} </td>
                                            </tr>
                                          </ng-template>
                                        </p-table>
                                      </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2" (click)="closeCreateCustomerModal()">Cancelar</button>
                <button type="button" (click)="addCustomer()" class="btn btn btn-info">{{ selectedCustomer ? 'Actualizar Cliente' : ' Crear Cliente' }}</button>
            </div>
        </div>
    </div>
</div>
<div class="d-none">
    <form [formGroup]="templateForm">
        <input  type="file" accept=".xlsx," formControlName="excelDocument" name="excelDocument" (change)="onfileChange($any($event.target).files)" #excelDocument>
    </form>
</div>
<p-toast>
</p-toast>
