
<section>
  <div class="row col-12">
    <h2 class="col-12 col-sm-4 fw-bold mt-3 text-center text-md-start">Transferencias</h2>

    <div class="col-12 col-sm-8">
      <div class="my-2 d-flex flex-column align-items-center  flex-sm-row  justify-content-sm-end  gap-sm-4">
        
        <div class="page-btn">
          <a class="btn btn-light text-center "><i class="feather icon-arrow-up-circle me-2"></i>Descargar reporte</a>
        </div>

        <div class="mt-3 mt-sm-0">
          <button type="button" [routerLink]="['/new-transfers']" class="btn btn btn-info"><i class="feather icon-plus feather-search"></i> Nueva transferencia</button>
        </div>
      </div>

    </div>
  </div>
</section>
<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive" style="min-height: 500px;">
      <div class="table-top row col-12 m-0 p-0 mb-4">
          <div class="search-set col-12 col-md-10">
            <div class="row col-12">

              <div class="dataTables_filter col-12 col-md-3">
                <p class="m-0">Fecha inicio</p>
                <p-calendar [(ngModel)]="dateStart" [iconDisplay]="'input'" dateFormat="dd/mm/yy" (ngModelChange)="applyFilter()" [showIcon]="true"></p-calendar>
              </div>

              <div class=" col-12 col-md-3">
                <p class="m-0">Fecha fin</p>
                <p-calendar [(ngModel)]="dateEnd" [iconDisplay]="'input'" dateFormat="dd/mm/yy" (ngModelChange)="applyFilter()" [showIcon]="true"></p-calendar>
              </div>

              <div class="col-12 col-md-3">
                <p class="m-0 p-0">Tipo de transferencia</p>
                <mat-select [(ngModel)]="typeTransfer" [ngModelOptions]="{standalone: true}"
                class="select space-select">
                  <mat-option value="todas">
                    Todas
                  </mat-option>
                  @for (item of listTypeTranfers; track $index) {
                    <mat-option [value]="item.cTipoTransferencia">
                      {{item.cTipoTransferencia}}
                    </mat-option>
                  }
                </mat-select>
              </div>

              <div class="mt-3 col-12 col-md-3">
                <button type="button" class="btn btn btn-info" (click)="getTransfers()">Aplicar filtro</button>
              </div>

            </div>
          </div>
          <div class="form-sort col-12 col-md-2">
              <i class="feather icon-sliders info-img"></i>
              <mat-select [(ngModel)]="selectedValue1" (selectionChange)="getTransfers()" [ngModelOptions]="{standalone: true}"
                class="select space-select" placeholder="Ordenar">
                <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                    <mat-option [value]="data.value">
                        {{data.value}}
                    </mat-option>
                </ng-template>
              </mat-select>
          </div>
        </div>
        <!-- /Filter -->
      <p-table  
      [value]="listTransfers"
      class="table datanew" 
      dataKey="code" 
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} trasferencias"
     
      [rowsPerPageOptions]="[10, 25, 50]"
      
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Fecha</th>
            <th>Tipo de transferencia</th>
            <th>Origen</th>
            <th>Destino</th>
            <th>Estatus</th>
            <th>Usuario registro</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-transfer>
          <tr>
            <td>{{ transfer.dateStart | date: 'dd/MM/yyyy'}} </td>
            <td>{{ transfer.transferType }} </td>
            <td>{{ transfer.origin }} </td>
            <td>{{ transfer.destino }} </td>
            <td>{{ transfer.status }} </td>
            <td>{{ transfer.userRegister }} </td>
            <td><a class="btn btn-info" [routerLink]="['/detail-transfer/'+transfer.idTransfer]"><i class="bi bi-search"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>

<p-toast>
</p-toast>

