<div class="card table-list-card">
    <div class="card-body">
        <div class="d-flex justify-content-between">
            <h2 class="fw-bold mx-3 mt-3">Parámetros</h2>
            <div class="page-btn pt-3 pe-3">
                <a [routerLink]="['/new-parameter']" class="btn btn-info">
                    <i class="feather icon-plus-circle me-2"></i>Añadir Parámetro
                </a>
            </div>
        </div>

        <div class="table-responsive">
            <p-table
            [value]="listParameters"
            class="table datanew"
            dataKey="code"
            [tableStyle]="{'min-width': '50rem'}"
            [paginator]="true"
            [rows]="10"
            [first]="first"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} Parámetros"
            (onPage)="pageChange($event)"
            [rowsPerPageOptions]="[10, 25, 50]"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th>Parámetro</th>
                    <th>Valor</th>
                    <th>Estado</th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-parameter>
                <tr>
                    <td>{{ parameter.parameter }}</td>
                    <td>{{ parameter.value }}</td>
                    <td>
                        @if (parameter.status) { Activo }
                        @else { Inactivo }
                    </td>
                    <td><a class="btn btn-info" [routerLink]="['/update-parameter/'+parameter.parameterId]"><i class="feather icon-edit"></i></a></td>
                </tr>
            </ng-template>
        </p-table>
        </div>
    </div>
</div>
<p-toast></p-toast>