<section>
    <div class="row col-12">
      <h2 class="col-12 col-md-4 fw-bold mt-3">Importar factura de compra</h2>
    </div>
  </section>
  
  <div class="card px-5 py-3 mb-5">    
      <div class="row col-12 mb-3">
        <div class="col-12 col-md-6">
          <div class="form-group px-3 mb-2 col-12">
            <label for="file">Factura de compra</label>            
          </div>
          <button type="button" class="btn btn w-100" (click)="openFileInputProducts()">Seleccionar archivo.</button>
          <p>{{ fileName }}</p>
        </div>
        <div class="col-12 col-md-3 mb-3">
          <button type="button" class="btn btn-info w-100 mt-3" (click)="uploadInvoice()">Realizar carga de factura.</button>
        </div>        
        <input id="fileInputProducts" type="file" accept=".xml,.XML" class="form-control d-none" (change)="selectFileProducts($any($event.target).files)">
      </div>
  </div>