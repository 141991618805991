<div id="signIn" class="row col-12 m-0 p-0">
    <div class="col-12 col-md-4 px-5 m-0">
        <div class="d-flex flex-column justify-content-center" style="height: 100vh;">
            <img class="w-50 mb-2 " src="../../assets/images/logo2-removebg-preview.png" alt="logo">

            <div *ngIf="!isRegister" class="m-0 p-0">
                <h4>Ingresa a tu cuenta</h4>
                <form [formGroup]="formLogin" (ngSubmit)="singIn()">
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="text" class="form-control" id="email" formControlName="email">
                        @if (formLogin.get('email')?.touched && formLogin.get('email')?.errors?.['required']) {
                            <p class="text-danger">Por favor, ingrese email.</p>
                        }
                        @if (formLogin.get('email')?.value != '' && formLogin.get('email')?.hasError('validEmail')) {
                            <p class="text-danger">Por favor, ingrese un email valido.</p>
                        }
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">Contraseña</label>
                        <input type="password" class="form-control" id="password" formControlName="password">
                        @if (formLogin.get('password')?.touched && formLogin.get('password')?.errors?.['required']) {
                            <p class="text-danger">Por favor, ingrese contraseña.</p>
                        }
                        @if (formLogin.get('password')?.value != '' && formLogin.get('password')?.hasError('containsNumbers')) {
                            <p class="text-danger">La contraseña debe de contener al menos un número.</p>
                        }
                        @if (formLogin.get('password')?.value != '' && formLogin.get('password')?.hasError('containsCapitalLetters')) {
                          <p class="text-danger">La contraseña debe de contener al menos una letra mayúscula.</p>
                        }
                        @if (formLogin.get('password')?.value != '' && formLogin.get('password')?.hasError('containsLowercaseLetters')) {
                          <p class="text-danger">La contraseña debe de contener al menos una letra minúscula.</p>
                        }
                        @if (formLogin.get('password')?.value != '' && formLogin.get('password')?.hasError('containsCharacters')) {
                          <p class="text-danger">La contraseña debe contener al menos un carácter especial.</p>
                        }
                    </div>                                    
                
                    <div class="d-flex justify-content-center w-100">
                        <button type="submit" class="btn button-primary col-10" [disabled]="formLogin.invalid" [routerLink]="['/core']">Ingresar</button>
                    </div>
                </form>
            </div>


            <div *ngIf="isRegister" class="m-0 p-0">
                <app-reset-password></app-reset-password>
            </div>


        </div>
    </div>
    <div class="d-none d-md-block col-8 fancy-bg m-0" style="height: 100vh;">

    </div>
</div>
<p-toast>
</p-toast>