import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public base: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public page: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public last: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private _currency = new BehaviorSubject<string>('USD');
  public readonly currency$ = this._currency.asObservable();

  setParameters(
    company: string,
    userName: string,
    branch: string,
    branchId: number,
    idCompany: number,
    imgPage: string,
    boxName: string,
    cashid: string,
  ) {
    localStorage.setItem('company', company);
    localStorage.setItem('userName', userName);
    localStorage.setItem('branch', branch);
    localStorage.setItem('idSucursal', branchId.toString());
    localStorage.setItem('idCompany', idCompany.toString());
    localStorage.setItem('imgPage', imgPage.toString());
    localStorage.setItem('cashName', boxName);
    localStorage.setItem('cashid',cashid)
    console.log('Inicio de Sucursal y Caja '+ cashid);
  }

  setBranch(branchName: string, branchId: number) {
    localStorage.setItem('branch', branchName);
    localStorage.setItem('idSucursal', branchId.toString());
  }

  setCashName(cashname: string) {
    localStorage.setItem('cashName', cashname);
  }

  setCashid (cashid : string){
    localStorage.setItem('cashid', cashid);
  }

  get userName() {
    return localStorage.getItem('userName')!;
  }
  get company() {
    return localStorage.getItem('company')!;
  }
  get companyId() {
    return localStorage.getItem('idCompany')!;
  }
  get branch() {
    return localStorage.getItem('branch')!;
  }
  get branchId() {
    return localStorage.getItem('idSucursal')!;
  }
  get userId(): number {
    return parseInt(localStorage.getItem('userId') || '0', 10);
  }
  get imgPage() {
    return localStorage.getItem('imgPage');
  }
  get cashName() {
    return localStorage.getItem('cashName');
  }
  get cashid() {
    return localStorage.getItem('cashid');
  }
}
