import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNotNegative]'
})
export class NotNegativeDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const inputValue = this.el.nativeElement.value;
    
    if (inputValue < 0 || inputValue == '') {
      this.el.nativeElement.value = 0;
    }
  }

}
