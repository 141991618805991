<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Reporte Movimientos de Inventario</h4>
        </div>
    </div>

    <div class="page-btn">
    </div>
    <div class="page-btn">
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="w-100">
            <div class="row align-items-center pt-4 ps-3">
                <div class="col-6">
                    <div class="form-sort">                        
                        <mat-select [(ngModel)]="selectedAction" (selectionChange)="getAction()"
                            [ngModelOptions]="{standalone: true}" class="select space-select" placeholder="Reportes">
                            <ng-template ngFor let-data [ngForOf]="actionsOptions" let-i="index">
                                <mat-option [value]="data.value">
                                    {{data.name}}
                                </mat-option>
                            </ng-template>
                        </mat-select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Inicio</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Fin</label>
                        <input type="date" class="form-control" [(ngModel)]="endDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="page-btn pe-4">
                        <a class="btn btn-info" id="donwload-table-data" (click)="exportData()"><i
                                class="feather icon-chevron-down"></i> Descargar </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <p-table [value]="reporData" selectionMode="single" [paginator]="true" [rows]="20"
                [(selection)]="selectedReportRowA" class="table  datanew" dataKey="id"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Tipo Movimiento</th>
                        <th>Descripcion Movimiento</th>
                        <th>Producto</th>
                        <th>Nombre producto</th>
                        <th>Cantidad</th>
                        <th>Folio de Venta</th>
                        <th>ID Devolucion</th>
                        <th>Tipo Devolucion</th>
                        <th>Motivo Devolucion</th>                        
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                    <tr>
                        <td>{{report.fecha  }}</td>
                        <td>{{report.tipoMovimiento}}</td>
                        <td>{{report.tipoMovimientoDescripcion}}</td>
                        <td>{{report.codigoProducto}}</td>
                        <td>{{report.nombreProducto}}</td>                                                
                        <td>{{report.cantidad}}</td>
                        <td>{{report.folioVenta}}</td>
                        <td>{{report.idDevolucion}}</td>
                        <td>{{report.tipoDevolucion}}</td>
                        <td>{{report.motivoDevolucion}}</td>                        
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-toast>
</p-toast>