<p-dialog header="Seleccionar caja" class="text-dark" [modal]="true" [(visible)]="disable" [style]="{ width: '25rem' }" closeIcon="false">
  @for (branch of listCashCompanies; track $index) {
    <p class="p-2" style="background-color: rgb(239, 239, 239);">{{ branch.branchName }}</p>
    @for (cash of branch.cash; track $index) {
      <p class="p-2 ps-3" style="cursor: pointer;" (click)="setCash(cash.idCaja, cash.idSucursal, cash.cNombreCaja)" ><i class="bi bi-box2-fill pe-2" style="color: #52cbf6;"></i> {{ cash.cNombreCaja }}</p>
    }
  }

  <div class="flex justify-content-center text-center mt-4">
    <p-button label="Cancelar" severity="secondary" (onClick)="onHidden()" />
  </div>
</p-dialog>