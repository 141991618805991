import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  idSucursal:number = parseInt(localStorage.getItem('idSucursal')!);

  constructor(
    private http: HttpClient
  ) { }


  getProducts(searchTerm: string, orderBy: string, branchId: number) {

    const params = new HttpParams()
    .set('idSucursal', branchId)
    .set('searchTerm',searchTerm)
    .set('orderBy',orderBy)
    
    return this.http.get<any>(`${environment.url}products`,{params});
  }

  getProductById(id: number, branchId: number) {
    return this.http.get<any>(`${environment.url}products/getProduct?id=${id}&idSucursal=${this.idSucursal}`);
  }

  addOrUpdateProducts(body: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}products/addOrUpdateProduct`,body,{headers: header});
  }


  getVariants(companyId: number) {
    const params = new HttpParams()
    .set('companyId', companyId);

    return this.http.get<any>(`${environment.url}products/getVariants`, { params });
  }

  getSalesUnit(companyId: number) {
    const params = new HttpParams()
    .set('companyId', companyId);

    return this.http.get<any>(`${environment.url}products/getSalesUnit`, { params });
  }

  bulkUploadPricing(excelFile: FormData, url: string) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}products/bulkUploadPricing?${url}`,excelFile,{headers: header});
  }

  productLoading(excelFile: FormData, branchId: number, companyId: number,userId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let params= new HttpParams()
    .append('branchId',branchId)
    .append('companyId',companyId)
    .append('userId',userId)

    return this.http.post<any>(`${environment.url}products/productLoading`,excelFile,{headers: header, params});
  }

  uploadInvoice(excelFile: FormData, branchId: number, companyId: number,userId: number) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });

    let params= new HttpParams()
    .append('branchId',branchId)
    .append('companyId',companyId)
    .append('userId',userId)

    return this.http.post<any>(`${environment.url}products/invoiceLoading`,excelFile,{headers: header, params});
  }

}
