<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Reporte Resumen de Ventas</h4>
        </div>
    </div>

    <div class="page-btn">
    </div>
    <div class="page-btn">
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="w-100">
            <div class="row align-items-center pt-4 ps-3">
                <div class="col-6">
                    <div class="form-sort">                        
                        <mat-select [(ngModel)]="selectedAction" (selectionChange)="getAction()"
                            [ngModelOptions]="{standalone: true}" class="select space-select" placeholder="Reportes">
                            <ng-template ngFor let-data [ngForOf]="actionsOptions" let-i="index">
                                <mat-option [value]="data.value">
                                    {{data.name}}
                                </mat-option>
                            </ng-template>
                        </mat-select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Inicio</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Fin</label>
                        <input type="date" class="form-control" [(ngModel)]="endDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="page-btn pe-4">
                        <a class="btn btn-info" id="donwload-table-data" (click)="exportData()"><i
                                class="feather icon-chevron-down"></i> Descargar </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <p-table [value]="reporData" selectionMode="single" [paginator]="true" [rows]="20"
                [(selection)]="selectedReportRowA" class="table  datanew" dataKey="id"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Fecha Anulación</th>
                        <th>ID</th>
                        <th>Folio Interno</th>
                        <th>Autorización</th>
                        <th>Serie</th>
                        <th>Numero</th>
                        <th>Descuento</th>
                        <th>Monto Venta</th>
                        <th>Nombre del Cliente</th>
                        <th>NIT</th>
                        <th>Referencia de pago</th>
                        <th>Autorización de pago</th>
                        <th>Comprador CF</th>
                        <th>Documento 1</th>
                        <th>Documento 2</th>
                        <th>Vendedor</th>
                        <th>Usuario vendedor</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                    <tr>
                        <td>{{report.fecha}}</td>
                        <td>{{report.fechaanulacion}}</td>
                        <td>{{report.id}}</td>
                        <td>{{report.folioInterno}}</td>
                        <td>{{report.autorizacion}}</td>
                        <td>{{report.serie}}</td>
                        <td>{{report.numero}}</td>
                        <td class="text-end">{{report.descuentos | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                        <td class="text-end">{{report.montoVenta | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                        <td>{{report.nombreCliente}}</td>
                        <td>{{report.nit}}</td>
                        <td>{{report.referenciaPago}}</td>
                        <td>{{report.autorizacionPago}}</td>
                        <td>{{report.compradorCF}}</td>
                        <td>{{report.documento1CF}}</td>
                        <td>{{report.documento2CF}}</td>
                        <td>{{report.vendedor}}</td>
                        <td>{{report.usuarioVendedor}}</td>                        
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-toast>
</p-toast>