
<section>
  <div class="row col-12">
    <h2 class="col-12 col-md-4 fw-bold mt-3">Productos</h2>

    <div class="col-12 col-md-8">
      <div class="row col-12 mt-2">
        <div class="col-12 col-md-4">
          <mat-select class="select space-select" placeholder="Más acciones">
            <mat-option (click)="exportToExcel()">
              <i class="bi bi-arrow-down-circle pe-2"></i>
              Descargar Productos
            </mat-option>
          </mat-select>
        </div>
        
        <div class="page-btn col-12 col-md-4">
          <a class="btn btn-light w-100"><i class="feather icon-arrow-up-circle me-2 w-100"></i>Importar Productos</a>
        </div>
        @if (editProducts) {
          <div class="col-12 col-md-4">
            <button type="button" [routerLink]="['/new-product']" class="btn btn btn-info">Agregar Producto</button>
          </div>
        }
        
      </div>
    </div>
  </div>
</section>

<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive">
      <div class="table-top">
          <div class="search-set">
            <div class="search-input">
              <a href="javascript:void(0);" (click)="getProducts()" class="btn btn-searchset">
                <i class="feather icon-search feather-search"></i>
              </a>
              <div class="dataTables_filter">
                <label>
                  <input (keyup.enter)="getProducts()" [(ngModel)]="searchDataValue" [ngModelOptions]="{standalone: true}"
                    type="search" class="form-control"
                    placeholder="Filtrar por nombre, categoría, SKU o Código de barras" />
                </label>
              </div>
            </div>
          </div>
          <div class="form-sort">
              <i class="feather icon-sliders info-img"></i>
              <mat-select [(ngModel)]="selectedValue1" (selectionChange)="getProducts()" [ngModelOptions]="{standalone: true}"
                class="select space-select" placeholder="Ordenar">
                <ng-template ngFor let-data [ngForOf]="selectedList1" let-i="index">
                    <mat-option [value]="data.value">
                        {{data.value}}
                    </mat-option>
                </ng-template>
              </mat-select>
          </div>
        </div>
        <!-- /Filter -->

      <p-table [value]="productsData" 
      class="table datanew" 
      dataKey="code" 
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} productos"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]"
      
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Id Producto</th>
            <th>Código Producto</th>
            <th>Nombre Producto</th>
            <th>Inventario</th>
            <th>Precio de venta</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr>
            <td>{{ product.id}} </td>
            <td>{{ product.code }} </td>
            <td>{{ product.name }} </td>
            <td>{{ product.inventory }} </td>            
            <td>{{product.cost| currency: currency + '':'symbol' : '1.2-2'}} </td>
            <td><a class="btn btn-info" [routerLink]="['/update-product/'+product.id]"><i class="feather icon-edit"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>

@if(editProducts) {
  <app-product-loading></app-product-loading>

  <app-price-loading></app-price-loading>
}


<p-toast>
</p-toast>
