
<div class="card table-list-card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <h2 class="fw-bold mx-3 mt-3">Usuarios</h2>
      <div class="page-btn pt-3 pe-3">
        <a [routerLink]="['/new-user']" class="btn btn-info">
          <i class="feather icon-plus-circle me-2"></i> Añadir Usuario 
        </a>
      </div>
    </div>
    
    <div class="table-responsive">
      <p-table 
      [value]="usersData" 
      class="table datanew" 
      dataKey="code" 
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} usuarios"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Permisos</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>{{ user.name }} </td>
            <td>
              @if (user.status == 1) {Activo}
              @else {Inactivo}
            </td>
            <td>{{ user.permissions }} </td>
            <td><a class="btn btn-info" [routerLink]="['/update-user/'+user.id]"><i class="feather icon-edit"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>

<p-toast>
</p-toast>