import { Component, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { TransfersTable, TypeTransfer } from '../_models/transfers';
import { TransfersService } from '../_services/transfers/transfers.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.css']
})
export class TransfersComponent implements OnInit {

  public searchDataValue = '';
  public selectedValue1 = '';
  public typeTransfer = '';
  dateStart!: Date | null;
  dateEnd!: Date | null;

  listTransfers: TransfersTable[] = [];
  listTypeTranfers: TypeTransfer[] = [];

  selectedList1: dataCompany[] = [
    { value: 'Creación (antiguos primero)' },
    { value: 'Creación (recientes primero)' },
  ];

  constructor(
    private transfersSrv: TransfersService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.getTransfers();
    this.getTypeTransfer();
  }

  getTransfers() {
    let url = "";

    if(this.dateStart != undefined) { url += `&dateStart=${(this.dateStart!.toISOString())?.split('T')[0]}`}
    if(this.dateEnd != undefined) { url += `&dateEnd=${(this.dateEnd!.toISOString())?.split('T')[0]}`}
    if(this.typeTransfer != undefined) { url += `&typeTransfer=${this.typeTransfer}`}

    this.transfersSrv.getTransfers(this.selectedValue1, url)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listTransfers = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las trasferencias, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las trasferencias, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getTypeTransfer() {
    this.transfersSrv.getTypeTransfers()
    .subscribe({
      next:(value) => {
        this.listTypeTranfers = value.data;
      },
      error:(err) => {
        
      },
    })
  }


  applyFilter() {
    if(this.dateStart != null && this.dateEnd != null && (this.dateStart > this.dateEnd)) {
      this.messageService.add({
        summary: 'Error',
        detail: 'La fecha de inicio no puede ser mayor a la fecha final, favor de rectificar.',
        styleClass: 'danger-background-popover',
      });
      this.dateEnd = null;
    }
  }


}
