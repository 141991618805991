
<div class="card table-list-card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <h2 class="fw-bold mx-3 mt-3">Perfiles</h2>
      <div class="page-btn pt-3 pe-3">
        <a [routerLink]="['/new-profile']" class="btn btn-info">
          <i class="feather icon-plus-circle me-2"></i> Añadir Perfil 
        </a>
      </div>
    </div>
    
    <div class="table-responsive">
      <p-table 
      [value]="listProfiles" 
      class="table datanew" 
      dataKey="code" 
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} perfiles"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Perfil</th>
            <th>Descripción</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-profile>
          <tr>
            <td>{{ profile.cPerfil }} </td>
            <td>{{ profile.cDescripcion }} </td>
            <td>
              @if (profile.nEstatus == 1) {Activo}
              @else {Inactivo}
            </td>
            <td><a class="btn btn-info" [routerLink]="['/update-profile/'+profile.idPerfil]"><i class="feather icon-edit"></i></a></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-toast>
</p-toast>
