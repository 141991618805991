import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
@NgModule({
    imports: [
      TableModule,
      DialogModule,
      ToastModule 
    ],
    exports: [
      TableModule,
      DialogModule,
      ToastModule
    ],
    providers: [
      {
        provide: [],
        useValue: { showError: true },
      },
      MessageService
    ],
  })
export class PrimengModule {
}
