<div class="card px-5 py-3 mb-5">
  <h2 class="fw-bold">Carga masiva de precios</h2>
  <form [formGroup]="massivePriceForm" class="row col-12">
    <div class="col-12 col-md-4">
      <p class="m-0 p-0">Selecciona sucursal(es)</p>
      <mat-form-field class="m-0 p-0 w-100">
        <mat-select formControlName="idBranches" multiple>
          @for (branch of listBranch; track $index) {
            <mat-option [value]="branch.idSucursal">{{branch.branchName}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (massivePriceForm.get('idBranches')?.touched && massivePriceForm.get('idBranches')?.errors?.['required']) {
        <p class="text-danger">Por favor, seleccione al menos una sucursal.</p>
      }
    </div>
   
    <div class="col-12 col-md-4 mb-3">
      <div class="col-12">
        <div class="form-group px-3 mb-3 col-12">
          <label for="nameProduct">Nombre del documento</label>
          <input type="text" class="form-control w-100" id="nameFile" aria-describedby="emailHelp" formControlName="nameFile" readonly>
          @if (massivePriceForm.get('excelFile')?.touched && massivePriceForm.get('excelFile')?.errors?.['required']) {
            <p class="text-danger">Por favor, seleccione un archivo.</p>
          }
        </div>
        <button type="button" class="btn btn w-100" (click)="openFileInput()">Seleccionar archivo.</button>
      </div>
      <input id="fileInput" type="file" accept=".xls,.xlsx" class="form-control d-none" (change)="selectFile($any($event.target).files)">
    </div>

    <div class="col-12 col-md-4 mb-3">
      <button type="button" class="btn btn btn-info w-100 mt-3" (click)="addUsersInBulk()">Realizar carga masiva.</button>
    </div>
  </form>
</div>