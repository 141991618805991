<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Reporte de Inventario</h4>
        </div>
    </div>

    <div class="page-btn">
    </div>
    <div class="page-btn">
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="w-100">
            <div class="row align-items-center pt-4 ps-3">
                <div class="col-6">
                    <div class="form-sort">                                                
                    </div>
                </div>
                <div class="col-2">                    
                </div>
                <div class="col-2">                    
                </div>
                <div class="col-2">
                    <div class="page-btn pe-4">
                        <a class="btn btn-info" id="donwload-table-data" (click)="exportData()"><i
                                class="feather icon-chevron-down"></i> Descargar </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <p-table [value]="reporData" selectionMode="single" [paginator]="true" [rows]="20"
                [(selection)]="selectedReportRowA" class="table  datanew" dataKey="id"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Código de Producto</th>
                        <th>Nombre de Producto</th>
                        <th>Descripción</th>
                        <th>Código de Barras</th>
                        <th>Categoría</th>
                        <th>Unidad de medida</th>
                        <th>Inventario</th>
                        <th>Precio Compra</th>
                        <th>% IVA</th>
                        <th>Precio Venta</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                    <tr>
                        <td>{{report.cCodigoProducto}}</td>
                        <td>{{report.cNombreProducto}}</td>
                        <td>{{report.cDescripcion}}</td>
                        <td>{{report.cCodigoBarras}}</td>
                        <td>{{report.cNombreCategoria}}</td>
                        <td>{{report.cUnidadMedida}}</td>
                        <td>{{report.nStock}}</td>
                        <td class="text-end">{{report.nPrecioCompra | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                        <td>{{report.nPorcentajeIVA}}</td>
                        <td class="text-end">{{report.nPrecioVenta | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-toast>
</p-toast>