import { HttpRequest, HttpHandler, HttpEvent,  HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getCookie, setCookie } from 'typescript-cookie'
@Injectable()
export class cookiesInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let userId = getCookie('userId');
      let branchId = getCookie('branchId');
      let cashId = getCookie('cashId');
      let companyId = getCookie('companyId');
      const headers = new HttpHeaders({
        'userId': userId + '',
        'branchId': branchId + '',
        'cashId': cashId + '',
        'companyId': companyId + '',
      });
      request = request.clone({
          headers: headers
      });

      return next.handle(request);
  }
}