import { Component, Input, OnInit } from '@angular/core';
import { ProductsTable } from '../_models/products';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsService } from '../_services/products/products.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { CommonService } from '../_services/common/common.service';
import { InventoryAdjustmentService } from '../_services/inventoryAdjustment/inventory-adjustment.service';
import { AdjustmentProduct, InventoryAdjustment } from '../_models/InventoryAdjustment';

@Component({
  selector: 'app-inventory-tweaks-form',
  templateUrl: './inventory-tweaks-form.component.html',
  styleUrls: ['./inventory-tweaks-form.component.css']
})
export class InventoryTweaksFormComponent implements OnInit {

  title: string = "Realizar";
  listProducts: ProductsTable[] = [];

  listProdTransfer: AdjustmentProduct[] = [];


  formTransfer!: FormGroup;
  idTransfer: number = 0;
  idProductDes: number = 0;
  isNewTrasfer: boolean = true;

  listTypes: InventoryAdjustment[] = []
  listRasons: InventoryAdjustment[] = []
  listSuppliers: InventoryAdjustment[] = []

  isBulkLoad: boolean = false;
  archivo!: FormData;
  fileName: string = "";

  first = 0;
  rows = 10;
  user = ""

  constructor(
    private productsSrv: ProductsService,
    private messageService: MessageService,
    private commonSrv: CommonService,
    private fb: FormBuilder,
    private inventorySrv: InventoryAdjustmentService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.user = this.commonSrv.userName;
    this.initializeForm();
    await this.getProducts();
    this.initializeForm();
    this.getAdjustmenType();
    this.getAdjustmentReasons();
    this.getSuppliers();
  }

  @Input() 
  set id(id: number) {
    if(id == undefined) {
      this.title = "Realizar";
    }
    else {
      this.title = "Modificar";
      this.idTransfer = id;
      this.getAdjustmentById(id);
    }
  }

  initializeForm() {
    this.formTransfer = this.fb.group({
      adjustmenTypeId:null,
      adjustmenType: [1,Validators.required],
      adjustmentReasons: [null, Validators.required],
      document: [''],
      supplier: [null],
      transferValue: [0],
      comments:['', Validators.required],
      products: [],
      excelFile: [],
      isBulkLoad: ['']
    })
  }

  async getProducts() {
    let term = "";
    if(document.getElementById('searchTerm')) term = (document.getElementById('searchTerm') as HTMLInputElement).value;
    this.productsSrv.getProducts(term,'',parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listProducts = value.data.filter((x: ProductsTable) => x.inventory > 0);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getAdjustmentById(id: number) {
    this.inventorySrv.getAdjustmentById(id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {

          this.formTransfer.patchValue(value.data);
          this.listProdTransfer = value.data.products;
          let amount = 0;
          this.listProdTransfer.forEach(p => {
            amount += p.priceProduct * p.quantityProduct;
          })
          this.formTransfer.patchValue({transferValue: parseFloat(amount.toFixed(2))});

          this.changeAdjustmenType();
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  getAdjustmenType() {
    this.inventorySrv.getAdjustmenType() 
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listTypes = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener los tipos de ajuste, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener los tipos de ajuste, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
      },
    })
  }

  getAdjustmentReasons() {
    this.inventorySrv.getAdjustmentReasons(parseInt(this.commonSrv.companyId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listRasons = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener motivos de ajuste, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener motivo de ajuste, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
      },
    })
  }
  
  getSuppliers() {
    this.inventorySrv.getSuppliers(parseInt(this.commonSrv.companyId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listSuppliers = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener proveedores, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al obtener proveedores, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
      },
    })
  }


  changeAdjustmenType() {
    if(this.formTransfer.get('adjustmenType')?.value == 1) { //ajuste
      this.formTransfer.get('adjustmentReasons')!.setValidators([Validators.required]);
      this.formTransfer.get('adjustmentReasons')!.updateValueAndValidity();

      this.formTransfer.get('supplier')!.setValidators([]);
      this.formTransfer.get('supplier')!.updateValueAndValidity();
      this.formTransfer.get('document')!.setValidators([]);
      this.formTransfer.get('document')!.updateValueAndValidity();
    }
    else {
      this.formTransfer.get('adjustmentReasons')!.setValidators([]);
      this.formTransfer.get('adjustmentReasons')!.updateValueAndValidity();

      this.formTransfer.get('supplier')!.setValidators([Validators.required]);
      this.formTransfer.get('supplier')!.updateValueAndValidity();
      this.formTransfer.get('document')!.setValidators([Validators.required]);
      this.formTransfer.get('document')!.updateValueAndValidity();
    }
  }


  openFileInputProducts() {
    (document.getElementById('fileInputProducts') as HTMLInputElement).click();
  }

  selectFileProducts(file: FileList) {
    this.fileName = file[0].name;
    this.archivo = new FormData();
    this.archivo.append('excelFile',file[0]);

    this.formTransfer.patchValue({excelFile: this.archivo});
  }

  selecProduct(product: ProductsTable) {

    if(this.listProdTransfer.find(x => x.productId == product.id) != undefined) {
        this.messageService.add({
        summary: '',
        detail: 'El producto seleccionado ya fue anteriormente agregado.',
        styleClass: 'warning-background-popover',
      });
      return;
    }

    let newProduct: AdjustmentProduct = {
      productId: product.id,
      priceProduct: product.cost,
      quantityProduct: 0,
      nameProduct: product.name,
    }

    this.listProdTransfer.push(newProduct);
    document.getElementById('btn-close')?.click();
  }

  changeQuantityProduc(idProduct: number) {
    let newStok = (document.getElementById('quantity'+idProduct) as HTMLInputElement).value;
   
    this.listProdTransfer = this.listProdTransfer.map(product => {
      if(idProduct == product.productId) {
        product.quantityProduct = parseInt(newStok);
        let amount = (this.formTransfer.get('transferValue')?.value != '') ? parseFloat(this.formTransfer.get('transferValue')?.value) : 0;
        this.formTransfer.patchValue({transferValue: parseFloat((product.quantityProduct * product.priceProduct + amount).toFixed(2))})
        return product;
      }
      else {
        return product;
      }
    });
  }

  deleteProductList(id: number) {
    this.listProdTransfer = this.listProdTransfer.filter(x => x.productId != id);
  }


  validater() {
    if(this.listProdTransfer.length == 0 && !this.isBulkLoad) {
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de seleccionar al menos un producto',
        styleClass: 'danger-background-popover',
      });
      return;
    }

    if(this.archivo == undefined && this.isBulkLoad) {
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de seleccionar al menos un producto',
        styleClass: 'danger-background-popover',
      });
      return;
    }

    if(this.formTransfer.get('transferValue')?.value == 0 && this.formTransfer.get('adjustmenType')?.value == 2 && !this.isBulkLoad) {
      this.messageService.add({
        summary: 'Error',
        detail: 'Favor de agregar una cantidad diferente de 0 en cada producto.',
        styleClass: 'danger-background-popover',
      });
      return;
    }

    this.createAdjustmen();
  }

  createAdjustmen() {
    this.formTransfer.patchValue({'products': this.listProdTransfer, isBulkLoad: this.isBulkLoad});
    this.formTransfer.enable();
    this.formTransfer.markAllAsTouched();
    
    if(this.formTransfer.valid) {
      if(this.isBulkLoad) {
        this.inventorySrv.createAdjustmentMasive(this.formTransfer)
        .subscribe({
          next:(value) => {
            if(value.response == 'Ok') {
              if (this.title == 'Crear') {
                this.showToastOk('Ajuste de Inventario registrado exitosamente.');
              }
              else {
                this.showToastOk('Ajuste de Inventario editado exitosamente.');
              }
              this.router.navigate(['/inventory-tweaks']);
            }
            else {
              console.warn("Error ", value);
              this.messageService.add({
                summary: 'Error',
                detail: value.data,
                styleClass: 'danger-background-popover',
              });
            }
          },
          error:(err) => {
            console.error("Error ", err);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocorrió un error al realizar Ajuste de Inventario, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          },
        })
      }
      else {
        this.inventorySrv.createAdjustment(this.formTransfer.value)
      .subscribe({
        next:(value) => {
          if(value.response == 'Ok') {
            if (this.title == 'Crear') {
              this.showToastOk('Ajuste de Inventario registrado exitosamente.');
            }
            else {
              this.showToastOk('Ajuste de Inventario editado exitosamente.');
            }
            this.router.navigate(['/inventory-tweaks']);
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocorrió un error al realizar Ajuste de Inventario, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocorrió un error al realizar Ajuste de Inventario, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
      }
      
    }
    else{
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de verificar.',
        styleClass: 'danger-background-popover',
      });
    }
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  downloadLayout(option: number) {

    let layout =  "";
    let title = "";
    
    if(option == 1) {
      layout = this.inventorySrv.layoutAdjustment;
      title = "Layout Ajsute.xlsx"
    }
    else {
      layout = this.inventorySrv.layoutSales;
      title = "Layout Compra.xlsx"
    }

    let downloadInstance = document.createElement('a');
    downloadInstance.href = layout;
   
    downloadInstance.target = "_blank";
    downloadInstance.download = title;

    document.body.appendChild(downloadInstance);
    downloadInstance.click();
    document.body.removeChild(downloadInstance);
  }

}
