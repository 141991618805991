import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { SidebarService } from '../_services/sidebar/sidebar.service';
import { CommonService } from '../_services/common/common.service';

export const accesUrlGuard: CanActivateFn = (route, state) => {
  const path =  route.data['path'];
  const navService = inject(SidebarService);
  const commonSrv = inject(CommonService);
  const router = inject(Router);
  return navService.getNav()
  .pipe( map(r => {
    if(r.response == "Ok" && r.data.length != 0) {
      let modules: string[] = r.data.map((x: any )=> x.cUrl);
      if(modules.includes(path)) {
        return true;
      }
      else {
        return router.parseUrl("/home");
      }
    }
    else {
      return router.parseUrl("/signIn");
    }
    }),
    catchError(e => {
      return of(router.parseUrl("/signIn"));
    })
  )
};