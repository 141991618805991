import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { SpinnerService } from '../_services/spinner.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {
  constructor(public spinner: SpinnerService, private cdr: ChangeDetectorRef,) {}
  loading$ = this.spinner.loading$;
}
