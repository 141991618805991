import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { }
  getItems(searchTerm: String) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    let  body = {
      searchTerm: searchTerm
    };
    return this.http.post<any>(`${environment.url}sales/getItemListSearch`,body,{headers: header});
  }
  getCustomers(searchTerm: String) {
    return this.http.get<any>(`${environment.url}sales/customers?searchTerm=`+ searchTerm);
  }
  getTemplateData() {
    return this.http.get<any>(`${environment.url}sales/getTemplateData`);
  }
  getItem(id: number) {
    return this.http.get<any>(`${environment.url}sales/getItem/` + id);
  }
  getItemDetail(id: number) {
    return this.http.get<any>(`${environment.url}sales/getItemDetailById/` + id);
  }
  getQuoteNumber() {
    return this.http.get<any>(`${environment.url}sales/getQuoteNumber`);
  }
  getGifCard(couponCode : String) {
    return this.http.get<any>(`${environment.url}sales/getGifCard?couponCode=`+ couponCode);
  }
  getDiscountCoupon(couponCode : String) {
    return this.http.get<any>(`${environment.url}sales/getDiscountCoupon?couponCode=`+ couponCode);
  }
  generateDTE(saleId : number) {
    return this.http.get<any>(`${environment.url}Billing/generateDTE?saleID=`+ saleId);
  }
  getCurrency() {
    return this.http.get<any>(`${environment.url}sales/currency`);
  }
  getTicketLogo(){
    return this.http.get<any>(`${environment.url}sales/ticketLogo`);
  }
  getCustomerIdentifierName(){
    return this.http.get<any>(`${environment.url}sales/customerIdentifierName`);
  }
  searchOrCreateCustomerByNit(identifier : String, brachID : String){
    return this.http.get<any>(`${environment.url}sales/searchOrCreateCustomerByNit?identifier=` + identifier + `&branchID=`+brachID);
  }
  customerbyId(identifier : Number){
    return this.http.get<any>(`${environment.url}sales/customerbyId?id=` + identifier);
  }
  getCurrentPromotions() {
    return this.http.get<any>(`${environment.url}sales/getCurrentPromotions`);
  }
  addOrUpdateItem(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}sales/addQuickItem`,body,{headers: header});
  }
  addCustomer(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}sales/addCustomer`,body,{headers: header});
  }
  addSale(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}sales/addSale`,body,{headers: header});
  }
  sendQuote(body: FormData) {
    return this.http.post<any>(`${environment.url}sales/sendQuote`, body);
  }
  sendQuote2(body: FormData) {
    return this.http.post<any>(`${environment.url}sales/sendQuote2`, body);
  }
  getSales(idBranch: number, url: string) {
    return this.http.get<any>(`${environment.url}sales/getSales?idBranch=${idBranch}${url}`);
  }
  getSalesByCustomer(url: string, id: number) {
    return this.http.get<any>(`${environment.url}sales/getSalesByCustomer?idCustomer=${id}${url}`);
  }
  getSaleById(saleId: number) {
    return this.http.get<any>(`${environment.url}sales/getSaleById?id=${saleId}`);
  }
  validateIdentifier(identifier: String) {
    return this.http.get<any>(`${environment.url}sales/validateIdentifier?identifier=${identifier}`);
  }
  cancellationOrReturnSale(data: FormGroup) {
    return this.http.post<any>(`${environment.url}sales/cancellationOrReturnSale`,data);
  }
  cancelDTE(id: number, comment: string ) {
    const params = new HttpParams()
    .append('saleID', id)
    .append('motivo', comment);

    return this.http.get<any>(`${environment.url}billing/cancelDTE`,{params});
  }
  saveTintes(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}sales/saveTintes`,body,{headers: header});
  }

}
