import { Component, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { MessageService } from 'primeng/api';
import { InventoryAdjustmentTable } from '../_models/InventoryAdjustment';
import { InventoryAdjustmentService } from '../_services/inventoryAdjustment/inventory-adjustment.service';
import { SalesService } from '../_services/sales/sales.service';

@Component({
  selector: 'app-inventory-tweaks',
  templateUrl: './inventory-tweaks.component.html',
  styleUrls: ['./inventory-tweaks.component.css']
})
export class InventoryTweaksComponent implements OnInit {

  public searchDataValue = '';
  public selectedValue1 = '';
  public typeTransfer = '';
  dateStart!: Date | null;
  dateEnd!: Date | null;
  currency: String = '';

  listAdjustment: InventoryAdjustmentTable[] = [];

  selectedList1: dataCompany[] = [
    { value: 'Creación (antiguos primero)' },
    { value: 'Creación (recientes primero)' },
  ];

  constructor(
    private adjustemtSrv: InventoryAdjustmentService,
    private messageService: MessageService,
    private salesService : SalesService
  ) { }

  ngOnInit() {
    this.getAdjustment();
    this.getCurrency();
  }

  getAdjustment() {

    this.adjustemtSrv.getListAdjustment(this.dateStart!, this.dateEnd!, this.selectedValue1)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listAdjustment = value.data;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener las Ajsutes inventario, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener las ajustes inventario, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  applyFilter() {
    if(this.dateStart != null && this.dateEnd != null && (this.dateStart > this.dateEnd)) {
      this.messageService.add({
        summary: 'Error',
        detail: 'La fecha de inicio no puede ser mayor a la fecha final, favor de rectificar.',
        styleClass: 'danger-background-popover',
      });
      this.dateEnd = null;
    }
  }

  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

}
