import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UsersService } from '../_services/users/users.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { BranchesService } from '../_services/branches/branches.service';
import { BranchesList } from '../_models/branches/branchesList';
import { ModuleData } from '../_models/users';
import { CommonSelect } from '../_models/common';
import { ProfilesService } from '../_services/profiles/profiles.service';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.css']
})
export class UsersFormComponent implements OnInit {
  title = 'Añadir';

  userForm!: FormGroup;
  listBranches: BranchesList[] = [];
  listProfiles: CommonSelect[] = [];

  constructor (
    private fb: FormBuilder,
    private messageService: MessageService,
    private usersSrv: UsersService,
    private profilesSrv: ProfilesService,
    private router: Router,
    private branchesSrv: BranchesService,
    private commonSrv: CommonService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getBranches();
    this.getProfiles();
    
  }

  @Input()
  set id(idUser: number) {
    if(idUser == undefined) {
      this.title = 'Añadir';
    }
    else {
      this.title = 'Editar';
      this.getUserById(idUser);
    }
  }

  passwordValidator(control: any) {
    const password = control.value;

    const containsNumbers = /\d/.test(password);
    const containsCapitalLetters = /[A-Z]/.test(password);
    const containsLowercaseLetters = /[a-z]/.test(password);
    const containsCharacters = /[^a-zA-Z0-9]/.test(password);

    if(!containsNumbers) {
      return { containsNumbers: true };
    }
    if(!containsCapitalLetters) {
      return { containsCapitalLetters: true };
    }
    if(!containsLowercaseLetters) {
      return { containsLowercaseLetters: true };
    }
    if(!containsCharacters) {
      return { containsCharacters: true };
    }
    else {
      return null
    }
  }

  validEmail(control: any) {
    const email = control.value;
    const patronCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if(!patronCorreo.test(email)) {
      return { validEmail: true };
    }
    else {
      return null;
    }
  }

  initializeForm() {
    this.userForm = this.fb.group({
      id: [null],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      email: ['',[Validators.required,this.validEmail]],
      password: ['',[Validators.required,Validators.minLength(6), this.passwordValidator]],
      idSucursal: [5,Validators.required],
      idProfile: ['', Validators.required]
    })
  }

  getBranches() {
    this.branchesSrv.getBranches()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listBranches = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  getProfiles() {
    this.profilesSrv.getProfiles()
    .subscribe({
      next:(value) => {
        this.listProfiles = value.data.filter((x:any) => x.idSucursal == parseInt(this.commonSrv.branchId)).map((x: any) => {
          let item: CommonSelect = {
            id: x.idPerfil,
            name: x.cPerfil
          }
          return item;
        });
      },
      error:(err) => {
        
      },
    })
  }

 

  getUserById(id: number) {
    this.usersSrv.getUserById(id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.userForm.patchValue(value.data);
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  createOrUpdateUser() {
    this.userForm.markAllAsTouched();

    if(this.userForm.valid) {
      this.usersSrv.addOrUpdateUsers(this.userForm.value)
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            window.scroll(0,0);
            if(this.title == 'Añadir') {
              this.showToastOk('Usuario agregado exitosamente.');
              this.userForm.reset();
            }
            else {
              this.router.navigate(['/users']);
              this.showToastOk('Usuario editado exitosamente.');
            }
          }
          else if(value.data == "error-email"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Hubo un problema al crear el usuario. Intente con otro email.',
              styleClass: 'danger-background-popover',
            });
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al guardar el usuario, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
          
        },
        error:(err) => {
          console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar el usuario, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
    else {

    }
  }


  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }

}
