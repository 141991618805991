import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-home-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home-card.component.html',
  styleUrl: './home-card.component.css',
})
export class HomeCardComponent {
  @Input() receivables0to7Days: any;
  @Input() receivables8to30Days: any;
  @Input() receivablesOver30Days: any;
  @Input() receivablesOverdue: any;
}
