<div class="card table-list-card">
    <div class="row col-12 card-body">
        <div class="col-11 col-md-10 mx-3">
            <div class="d-flex mt-4 align-items-center">
                <button [routerLink]="['/parameters']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
                <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Parámetro</h2>
            </div>
        </div>
        <form [formGroup]="formParameters">
            <div class="col-11 card my-4 mx-3">
                <div class="card-body p-4">
                    <div class="col-12 mb-3">
                        <label for="parameter">Nombre del Parámetro</label>
                        <input type="text" class="form-control" id="parameter" formControlName="parameter" placeholder="Ingresa nombre parámetro" maxlength="100">
                        @if (formParameters.get('parameter')?.touched && formParameters.get('parameter')?.errors?.['required']) {
                            <p class="text-danger">Por favor, ingrese un nombre de parámetro.</p>
                        }
                    </div>
                    <div class="col-12 mb-3">
                        <label for="value">Valor</label>
                        <input type="text" class="form-control" id="value" formControlName="value" placeholder="Ingresa valor" maxlength="100">
                        @if (formParameters.get('value')?.touched && formParameters.get('value')?.errors?.['required']) {
                            <p class="text-danger">Por favor, ingrese un valor.</p>
                        }
                    </div>
                    <div class="col-12 mb-3">
                        <label for="status">Estado:</label>
                        <input class="form-check-input ms-3" type="checkbox" id="status" formControlName="status">
                        <label class="form-check-label ms-2" for="status">
                            @if(formParameters.get('status')!.value) { Activo }
                            @else { Inactivo }
                        </label>
                    </div>
                </div>
            </div> 
        </form>

        <div class="d-flex justify-content-end p-5">
            <div class="page-btn me-3">
                <a [routerLink]="['/parameters']" class="btn btn-light">
                    Cancelar
                </a>
            </div>
            <div class="page-btn">
                <button type="button" class="btn btn-info" (click)="createParameter()">
                    @if (title == 'Crear') { Crear Parámetro }
                    @else { Editar Parámetro }
                </button>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>