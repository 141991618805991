import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CashClosingService } from '../_services/cashClosing/cash-closing.service';
import { ExpectedAmount } from '../_models/cashClosing/expectedAmount';
import { cashMovements } from '../_models/cashClosing/cashMovements';
import { SalesService } from '../_services/sales/sales.service';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-cash-closing',
  templateUrl: './cash-closing.component.html',
  styleUrl: './cash-closing.component.css'
})
export class CashClosingComponent implements OnInit {
  cashingress!: FormGroup;
  username : String = localStorage.getItem('userName') + '';
  cashid !: String;
  cashName: string = '';
  cashEgress!: FormGroup;
  cashClosingForm!: FormGroup;
  manualAmountTotal: number = 0;
  remainigCash_0: number = 0;
  remainigCash_1: number = 0;
  remainigCash_2: number = 0;
  remainigCash_3: number = 0;
  currency: String = '';
  totalRemaining: number = 0;
  ExpectedAmountList: Array<ExpectedAmount> = [];
  cashMovementsList: Array<cashMovements> = [];
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closebuttonManualE') closebuttonE: any;
  @ViewChild('closebuttonCashClosing') closebuttonCashClosing: any;
  @ViewChild('closebuttonConfirmClosingCash') closebuttonConfirmClosingCash: any;
  constructor(private cashClosingService: CashClosingService, private messageService: MessageService, private salesService : SalesService, private formBuilder: FormBuilder, private commonSrv: CommonService) { }
  ngOnInit() {
    const cashID = Number(localStorage.getItem('cashid'));
    
    this.cashingress = this.formBuilder.group({      
      amount: [0, [Validators.required, Validators.min(0.01)]],
      reason: ['', [Validators.required]],
      username: [this.username],
      type: [1],
      cashId: [cashID]
    });    

    this.cashEgress = this.formBuilder.group({
      amount: [0, [Validators.required, Validators.min(0.01)]],
      reason: ['', [Validators.required]],
      username: [this.username],
      type: [1],
      cashId: [cashID]
    });
    this.cashClosingForm = this.formBuilder.group({
      cash_0: [0, [Validators.min(0.00)]],
      cash_1: [0, [Validators.min(0.00)]],
      cash_2: [0, [Validators.min(0.00)]],
      cash_3: [0, [Validators.min(0.00)]],
      cash_4: [0, [Validators.min(0.00)]],
      comments: ['', []],
      expected: [0],
      diference: [0],
      totalCash: [0],
      cashId: [0]
    });
    
    this.getCurrency();
    this.getCashClosingExpectedAmount(cashID);
    this.getCashMovements(cashID);
  }
  
  closeManualModal() {
    this.cashingress.reset();
    this.closebutton.nativeElement.click();
  }
  closeManualModalE() {
    this.cashEgress.reset();
    this.closebuttonE.nativeElement.click();
  }
  closeManualModalConfirmCashClosing() {
    this.closebuttonConfirmClosingCash.nativeElement.click();
  }
  closeCashClosingModal() {
    this.cashClosingForm.reset();
    this.closebuttonE.nativeElement.click();
  }
  addCash() {
    const cashID = Number(localStorage.getItem('cashid'));
    this.cashingress.markAllAsTouched();
    if (this.cashingress.valid) {
      this.cashingress.patchValue({ type: 1 });
      this.cashingress.patchValue({ cashId: cashID })
      this.cashClosingService.addCashIngresEgres(this.cashingress.value)
        .subscribe({
          next: data => {
            if (data.response == "Ok") {
              this.closebutton.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Ingreso agregado exitosamente', styleClass: 'success-background-popover' });
              this.cashingress.reset();
              this.cashEgress.reset();
              this.cashEgress.get('username')?.setValue(this.username);
              this.cashingress.get('username')?.setValue(this.username);                          
              this.getCashClosingExpectedAmount(cashID);
              this.getCashMovements(cashID);
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al agregar el ingreso, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al agregar el ingreso, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  closeCash(diferenceConfirm: Boolean) {
    const cashID = Number(localStorage.getItem('cashid'));
    this.cashClosingForm.markAllAsTouched();
    if (this.cashClosingForm.valid) {      
      if (this.totalRemaining != 0 && !diferenceConfirm) {
        if (this.cashClosingForm.get('comments')?.value == '' || this.cashClosingForm.get('comments')?.value.length < 10) {
          this.messageService.add({
            summary: 'Atención',
            detail: 'Para un cierre de Caja con diferencias es obligatorio capturar un comentario de al menos 10 caracteres.',
            styleClass: 'danger-background-popover',
          });
        }
        else {
          this.showCashClosingConfirmModal();
        }
      }
      else {
        this.cashClosingForm.patchValue({expected: this.totalRemaining});
        this.cashClosingForm.patchValue({diference: this.ExpectedAmountList[4].value});
        this.cashClosingForm.patchValue({totalCash: this.manualAmountTotal});
        this.cashClosingForm.patchValue({cashId: cashID })
        this.cashClosingService.closeCash(this.cashClosingForm.value)
          .subscribe({
            next: data => {
              if (data.response == "Ok") {
                this.closebuttonCashClosing.nativeElement.click();
                this.closebuttonConfirmClosingCash.nativeElement.click();
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'El corte de caja se ha realizado correctamente.', styleClass: 'success-background-popover' });
                this.cashClosingForm.reset();                

                this.getCashClosingExpectedAmount(cashID);
                this.getCashMovements(cashID);
              }
              else {
                this.messageService.add({
                  summary: 'Error',
                  detail: 'Ocurrio un error en el corte de caja, contacte con el administrador del sistema.',
                  styleClass: 'danger-background-popover',
                });
              }
            }
            ,
            error: error => this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al agregar el ingreso, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            })
          });
      }
    }
  }
  retireCash() {
    const cashID = Number(localStorage.getItem('cashid'));
    this.cashEgress.markAllAsTouched();
    if (this.cashEgress.valid) {
      this.cashEgress.patchValue({ type: 0 });
      this.cashEgress.patchValue({ cashId: cashID })
      this.cashClosingService.addCashIngresEgres(this.cashEgress.value)
        .subscribe({
          next: data => {
            if (data.response == "Ok") {
              this.closebuttonE.nativeElement.click();
              this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Retiro agregado exitosamente', styleClass: 'success-background-popover' });
              this.cashEgress.reset();
              this.cashingress.reset();
              this.cashEgress.get('username')?.setValue(this.username);
              this.cashingress.get('username')?.setValue(this.username);
              
              this.getCashClosingExpectedAmount(cashID);
              this.getCashMovements(cashID);
            }
            else {
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error al agregar el Retiro, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al agregar el Retiro, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
    }
  }
  getCashClosingExpectedAmount(cashId: number) {
    this.cashClosingService.getCashClosingExpectedAmount(cashId)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.ExpectedAmountList = [];
            this.ExpectedAmountList = result.data;
            this.cashName = this.ExpectedAmountList[6].value.toString();
            this.commonSrv.setCashName(this.cashName);
            
            this.getManualAmountTotal();
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente de nuevo.',
              styleClass: 'danger-background-popover',
            });
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getCashMovements(cashId: number) {
    this.cashClosingService.getCashMovements(cashId)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.cashMovementsList = [];
            this.cashMovementsList = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente de nuevo.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getManualAmountTotal() {
    this.manualAmountTotal = this.cashClosingForm.get('cash_0')?.value +
      this.cashClosingForm.get('cash_1')?.value +
      this.cashClosingForm.get('cash_2')?.value +
      this.cashClosingForm.get('cash_3')?.value;
    this.remainigCash_0 = this.ExpectedAmountList[0].value - this.cashClosingForm.get('cash_0')?.value;
    this.remainigCash_1 = this.ExpectedAmountList[1].value - this.cashClosingForm.get('cash_1')?.value;
    this.remainigCash_2 = this.ExpectedAmountList[2].value - this.cashClosingForm.get('cash_2')?.value;
    this.remainigCash_3 = this.ExpectedAmountList[3].value - this.cashClosingForm.get('cash_3')?.value;
    this.totalRemaining = (this.remainigCash_0 + this.remainigCash_1 + this.remainigCash_2 + this.remainigCash_3);
  }
  showCashClosingConfirmModal() {
    document.getElementById('btn-cofirm-cash-closing')?.click();
  }
  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
}

