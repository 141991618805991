import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './material/material.module';
import { SpinnerInterceptor } from '../interceptors/spinner/spinner.interceptor';
import { PrimengModule } from './primeng/primeng.module';
import { NgxMaskModule } from 'ngx-mask';
import { cookiesInterceptor } from '../interceptors/credentials/cookies.interceptor';
@NgModule({
  imports: [
    MaterialModule,
    PrimengModule,
    NgxMaskModule.forRoot({
      showMaskTyped: false,
    }),
    HttpClientModule
  ],
  exports: [
    MaterialModule,
    PrimengModule,
    NgxMaskModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: cookiesInterceptor, multi: true }
  ],
})
export class sharedModule { }