import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CashClosingService {

  constructor(private http: HttpClient) { }
  getCashClosingExpectedAmount(cashId: number) {
    return this.http.get<any>(`${environment.url}CashClosing/totals?cashId=`+ cashId);
  }
  getCashMovements(cashId: number) {
    return this.http.get<any>(`${environment.url}CashClosing/movements?cashId=`+ cashId);
  }
  addCashIngresEgres(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}CashClosing`,body,{headers: header});
  }
  closeCash(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}CashClosing/closeCash`,body,{headers: header});
  }
}
