import { Component, Input, OnInit } from '@angular/core';
import { ModuleData, Module, Submodules } from '../_models/users';
import { UsersService } from '../_services/users/users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ProfilesService } from '../_services/profiles/profiles.service';
import { Router } from '@angular/router';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  title: string = "Crear";
  formProfiles!: FormGroup;

  listModules: Module[] = [];
  listModulesData: number[] = [];

  isEditProducts: boolean = false;
  isEditCustomers: boolean = false;
  isAccesBranchs: boolean = false;
  isAccesUnitPrice: boolean = false;
  isAccesDiscount: boolean = false;

  constructor(
    private profilesSrv: ProfilesService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private commonSrv: CommonService,
    private router: Router,
  ) { }
  

  ngOnInit() {
    this.initializeForm();
    this.getModules();
  }

  @Input()
  set id(idProfile: number) {
    if(idProfile == undefined) {
      this.title = "Crear";
    }
    else {
      this.title = "Editar";
      this.getProfileById(idProfile);
    }
  }

  initializeForm() {
    this.formProfiles = this.fb.group({
      idBranch: [parseInt(this.commonSrv.branchId)],
      idProfile: [null],
      name: ['', Validators.required],
      description: ['', Validators.required],
      user:[''],
      listModules: [[], Validators.required],
      nOption1: false,
      nOption2: false,
      nOption3:false,
      nOption4:false,
      nOption5:false,
    })
  }

  getModules() {
    this.profilesSrv.getModules()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {

          this.listModules = value.data
          .filter((m:ModuleData) => m.idOrder == null)
          .map((m:ModuleData) => {
            let item: Module = {
              idModule: m.idModule,
              name: m.nameModule,
              color: "primary",
              completed: false,
              subModules: value.data
              .filter((s:ModuleData) => s.idOrder != null && s.idOrder == m.idModule)
              .map((s: ModuleData) => {
                let subItem: Submodules = {
                  idModule: s.idModule,
                  name: s.nameModule,
                  idOrder: s.idOrder,
                  idSubOrder: s.idSubOrder,
                  color: "primary",
                  completed: false
                }
                return subItem;
              })
            }
            return item
          });
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  getProfileById(id: number) {
    this.profilesSrv.getProfileById(id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok' && value.data != null) {
          this.formProfiles.patchValue(value.data);
          let listModulesForm: number[] = this.formProfiles.get('listModules')?.value;

          const {nOption1, nOption2, nOption3, nOption4, nOption5} = this.formProfiles.value;

          this.isEditProducts = nOption1;
          this.isEditCustomers = nOption2;
          this.isAccesBranchs = nOption3;
          this.isAccesUnitPrice = nOption4;
          this.isAccesDiscount = nOption5;

          this.listModulesData = listModulesForm;

          this.listModules = this.listModules.map((m: Module) => {
            
            if(listModulesForm.includes(m.idModule)) m.completed = true;

            if(m.subModules!.length != 0) {
              m.subModules = m.subModules!.map(s => {
                if(listModulesForm.includes(s.idModule)){
                  s.completed = true;
                  return s;
                }
                else {
                  return s;
                }
              })
            }
            return m;
          })
        }
        else {
          console.warn("Error getProfileById ", value);
          this.messageService.add({
            summary: 'warn',
            detail: 'Hubo un problema al obtener los datos del perfil, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error getProfileById ", err);
          this.messageService.add({
            summary: 'error',
            detail: 'Hubo un problema al obtener los datos del perfil, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
      },
    })
  }


  createProfile() {

    let newList = this.listModulesData.filter((numero, index, array) => array.indexOf(numero) === index);

    this.formProfiles.patchValue({
      listModules: newList,
      nOption1: this.isEditProducts,
      nOption2: this.isEditCustomers,
      nOption3: this.isAccesBranchs,
      nOption4: this.isAccesUnitPrice,
      nOption5: this.isAccesDiscount
    });

    console.log(this.formProfiles.value);
    

    //return
    this.formProfiles.markAllAsTouched();
    if(this.formProfiles.valid) {
      this.profilesSrv.addOrUpdateProfile(this.formProfiles.value)
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            if(this.title == "Crear") {
              this.showToastOk('Perfil agregado exitosamente.');
            }
            else {
              this.showToastOk('Perfil editado exitosamente.');
            }
            this.router.navigate(['/profiles']);
          }
          else {
            console.warn("Error crear perfil ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Hubo un problema al crear el perfil, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err)  => {
          console.error("Error crear perfil ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Hubo un problema al crear el perfil, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
    else {
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de verificar.',
        styleClass: 'danger-background-popover',
      });
    }
    
  }

  allComplete: boolean = false;

  updateAllComplete(task: Module, completed: boolean, idSubModule: number) {
    task.completed = task.subModules!.every(subtask => subtask.completed);

    this.listModules = this.listModules.map(x => {
      if(x.idModule == task.idModule){
        x.completed = task.completed
        return x;
      }
      else {
        return x;
      }
    })
    
    this.allComplete = this.listModules.every(task => task.completed);

    if(completed) {
      this.listModulesData.push(idSubModule);
      this.listModulesData.push(task.idModule);
      
    }
    else {
      this.listModulesData = this.listModulesData.filter(m => m != idSubModule);
    }
  }

  someComplete(task: Module): boolean {
    let status = task.subModules!.some(subtask => subtask.completed) && !task.completed;

    if(status ){
      this.listModulesData.push(task.idModule)
    }
    return status;
  }

  setAll(task: Module, completed: boolean) {
    task.completed = completed;
    task.subModules!.forEach(subtask => {
      (subtask.completed = completed)

      if(completed) {
        this.listModulesData.push(subtask.idModule);
      } 
      else {
        this.listModulesData = this.listModulesData.filter(m => m != subtask.idModule);
      }
    });
    this.allComplete = this.listModules.every(task => task.completed);

    (completed) ?
      this.listModulesData.push(task.idModule) :
      this.listModulesData = this.listModulesData.filter(m => m != task.idModule);
  }


  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }


}
