import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { SidebarService } from '../_services/sidebar/sidebar.service';
import { Cookies } from 'typescript-cookie';

export const editProductsGuard: CanActivateFn = (route, state) => {
  const path =  route.data['path'];
  const navService = inject(SidebarService);
  const router = inject(Router);
  const isEditProducts = JSON.parse(Cookies.get('editProducts')!.toString());

  return navService.getNav()
  .pipe( map(r => {
    if(r.response == "Ok" && r.data.length != 0) {
      let modules: string[] = r.data.map((x: any )=> x.cUrl);

      if(modules.includes(path) && isEditProducts) {
        return true;
      }
      else {
        return router.parseUrl("/products");
      }
    }
    else {
      return router.parseUrl("/signIn");
    }
    }),
    catchError(e => {
      return of(router.parseUrl("/signIn"));
    })
  )
};