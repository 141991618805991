import { Component, Input, OnInit, input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ParametersService } from '../_services/parameters/parameters.service';
import { Router } from '@angular/router';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-parameters-form',
  templateUrl: './parameters-form.component.html',
  styleUrl: './parameters-form.component.css'
})
export class ParametersFormComponent implements OnInit{
  title: string = 'Crear';
  formParameters!: FormGroup;


  constructor(
    private parametersSrv: ParametersService,
    private commonSrv: CommonService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  @Input()
  set id(idParameter: number) {
    if (idParameter == undefined) {
      this.title = 'Crear';
    } else {
      this.title = 'Editar';
      this.getParameterById(idParameter);
    }
  }

  initializeForm() {
    this.formParameters = this.fb.group({
      parameterId: [null],
      parameter: ['', Validators.required],
      value: ['', Validators.required],
      status: [true],
      user: ['']
    });
  }

  getParameterById(id: number) {
    this.parametersSrv.getParameterById(id)
    .subscribe({
      next: (value) => {
        if (value.response == 'Ok' && value.data != null) {
          this.formParameters.patchValue(value.data);
        } else {
          console.warn('Error getParameterById', value);
          this.messageService.add({
            summary: 'warn',
            detail:
              'Hubo un problema al obtener los datos del parámetro, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error: (err) => {
        console.error('Error getParameterById ', err);
        this.messageService.add({
          summary: 'error',
          detail:
            'Hubo un problema al obtener los datos del parámetro, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    });
  }

  createParameter() {

    this.formParameters.markAllAsTouched();
    if (this.formParameters.valid) {
      this.parametersSrv
        .addOrEditParameter(this.formParameters.value)
        .subscribe({
          next: (value) => {
            if (value.data == 'Ok') {
              if (this.title == 'Crear') {
                this.showToastOk('Parámetro agregado exitosamente.');
              }
              else {
                this.showToastOk('Parámetro editado exitosamente.');
              }
              this.router.navigate(['/parameters']);
            }
            else {
              console.warn("Error crear parámetro ", value);
              this.messageService.add({
                summary: 'Error',
                detail: 'Hubo un problema al crear el parámetro, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          },
          error:(err) => {
            console.error("Error crear parámetro", err);
            this.messageService.add({
              summary: 'Error',
              detail: 'Hubo un problema al crear el parámetro, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          },
        })
    }
    else {
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de verificar.',
        styleClass: 'danger-background-popover',
      });
    }
  }

  showToastOk(messageContent: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: messageContent,
      styleClass: 'success-background-popover',
    });
  }
}
