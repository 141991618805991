<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Resumen de Auditoria #{{idAudit}}</h4>
        </div>
    </div>
    <div class="page-btn pe-4">
        <a class="btn btn-light" id="addeditmodal" (click)="exportExcel()" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-item-modal"><i
                class="feather icon-arrow-down me-2"></i> Descargar Reporte </a>
    </div>
    <div class="form-sort">
        <i class="feather icon-sliders info-img"></i>
        <mat-select [(ngModel)]="selectedAction" [ngModelOptions]="{standalone: true}"
            class="select space-select" placeholder="Más acciones">
            <ng-template ngFor let-data [ngForOf]="actionsOptions" let-i="index">
                <mat-option [value]="data.value">
                    {{data.value}}
                </mat-option>
            </ng-template>
        </mat-select>
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="table-responsive">
            <p-table [value]="tableData" selectionMode="single" [(selection)]="selectedItem" class="table  datanew" dataKey="id" [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>ID</th>
                        <th>Código de Producto</th>
                        <th>Nombre de Producto</th>
                        <th>Cantidad encontrada</th>
                        <th>Cantidad esperada</th>
                        <th>Diferencia</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                        </td>
                        <td>{{customer.id}}</td>
                        <td>{{customer.itemCode}} </td>
                        <td>{{customer.itemName}} </td>
                        <td>{{customer.foundQuantity}} </td>
                        <td>{{customer.expectedQuantity}} </td>
                        <td *ngIf="customer.diference < 0" class="text-danger">{{customer.diference}} </td>
                        <td *ngIf="customer.diference > 0">{{customer.diference}} </td>
                        <td><a class="btn btn-info"><i class="feather icon-edit"></i></a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- <app-custom-pagination></app-custom-pagination> -->
    </div>
</div>
<div class="row justify-content-end">
    <div class="col-auto">
        <a class="btn btn-info" (click)="finishAudit()" id="addeditmodal">Finalizar Auditoría</a>
    </div>
</div>
<p-toast>
</p-toast>