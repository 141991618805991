import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalRestriction]'
})
export class DecimalRestrictionDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    
    // Evita la entrada del punto si el input es de tipo número
    if (target && target.getAttribute('type') === 'number' && event.key === '.') {
      event.preventDefault();
    }
    if (target && target.getAttribute('type') === 'number' && event.key === '-') {
      event.preventDefault();
    }
  }
}
