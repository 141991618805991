
<div class="table-list-card">
  <div class="row col-12">
    <div class="col-11 col-md-10 mx-3">
      <div class="d-flex mt-4 align-items-center">
        <button [routerLink]="['/sales-list']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
        <h2 class="fw-bold m-0 p-0 ps-2">Detalle Venta</h2>
      </div>
    </div>
    <form [formGroup]="formSale" (ngSubmit)="sentData()" class="mt-3">
      <div class="card p-3">
        <h3 class="row fw-bold m-0 p-0">Datos generales</h3>
        <div class="card-body row col-12">
          <div class="col-12 col-md-9 m-0 p-0">
            <p-table
            [value]="generalData"
            class="table datanew" 
            dataKey="code" 
            [tableStyle]="{'min-width': '50rem'}">
              
              <ng-template pTemplate="header">
                <tr>
                  <th>Fecha</th>
                  <th>NIT</th>
                  <th>Nombre del cliente</th>
                  <th>Monto</th>
                  <th>Estatus</th>
                  <th>Usuario de registro</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-sales>
                <tr>
                  <td> {{ sales.date | date:'dd-MM-yy' }}</td>
                  <td> {{ sales.nit }} </td>
                  <td> {{ sales.customerName }}</td>                
                  <td> {{sales.amount| currency: currency + '':'symbol' : '1.2-2'}} </td>
                  <td> {{ sales.status }} </td>
                  <td> {{ sales.registrationUser }} </td>
                </tr>
                
              </ng-template>
            </p-table>
          </div>
          <div class="col-12 col-md-3 d-flex flex-column align-items-center">
            @if(formSale.get('returnId')?.value != null) {
              @if(formSale.get('returnType')?.value == 2) {
                <p class="text-center warning"><i class="bi bi-exclamation-octagon-fill" style="color: #52cbf6;"></i> <br>Esta venta a sido <b>cancelada</b></p>
              }
              @if( formSale.get('returnType')?.value == 3) {
                <p class="text-center warning"><i class="bi bi-exclamation-octagon-fill" style="color: #52cbf6;"></i> <br>A esta venta se le aplicó una <b>devolución</b></p>
              }
            }
            @else {
              <button type="button" class="btn btn-info mx-1 mb-2" (click)="salesReturn()">Aplicar devolución</button>
              <button type="button" class="btn btn-danger mx-1 mb-2" (click)="salesCancellation()">Aplicar cancelación</button>
            }
          </div>
        </div>
      </div>

      <div class="card p-3">
        <h3 class="fw-bold m-0 p-0">Detalle de la venta</h3>
        <div class="card-body row col-12">
          <div class="col-12 col-md-9">
            <p-table [value]="saleProducts" class="table datanew" 
            dataKey="code" 
            [tableStyle]="{'min-width': '50rem'}"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            [rowsPerPageOptions]="[10, 25, 50]"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>#</th>
                <th>Código del producto</th>
                <th>Nombre del producto</th>
                <th>Cantidad vendida</th>
                @if (isCancellation || isReturn) {<th>Cantidad a devolver</th>}
                <th>Precio unitario</th>
                <th>Subtotal</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sales let-i="rowIndex">
              <tr class="text-center">
                <td> {{ i+1 }} </td>
                <td> {{ sales.codeProduct }}</td>
                <td> {{ sales.productName }} </td>
                <td> {{ sales.quantitySold }} </td>
                @if (isCancellation || isReturn) {
                  <td>
                    <input [id]="sales.saleDetailId" type="number" class="form-control" appNotNegative (change)="validateQuantityReturn(sales.saleDetailId)" [value]="sales.returnQuantity" [disabled]="isCancellation">
                     <!-- {{ sales.returnAmount }}  -->
                  </td>
                }
                <td> {{ sales.priceProduct | currency: 'Q ' : 'code'  }} </td>
                <td> {{ (sales.priceProduct * sales.quantitySold)   | currency: 'Q ' : 'code'  }} </td>
              </tr>
            </ng-template>
          </p-table>
          </div>
          
          <div class="col-12 col-md-3 d-flex flex-column align-items-center">
            <div>
              <p class="m-0 p-0">Monto original</p>
              <p-inputGroup [ngClass]="{'input-group-disabled': true}" class="mb-3">
                <p-inputGroupAddon>Q</p-inputGroupAddon>
                <input type="number" pInputText formControlName="amount" />
              </p-inputGroup>
              </div>
            @if (isCancellation || isReturn) {
              <div>
                <p class="m-0 p-0">Monto a Devolver</p>
                <p-inputGroup [ngClass]="{'input-group-disabled': true}" class="mb-3">
                  <p-inputGroupAddon>Q</p-inputGroupAddon>
                  <input type="number" pInputText formControlName="returnAmount" />
                </p-inputGroup>
              </div>
            }
          </div>
        </div>
      </div>

      @if (isCancellation || isReturn) {
        <div class="card p-3">
          <div class="card-body row col-12">
              <div class="mb-3 col-12 col-md-4">
                <label class="form-label">Motivo</label>
                <select class="form-select" formControlName="reason">
                  <option [value]="1">Producto en mal estado</option>
                  <option [value]="2">Cambio de producto</option>
                </select>
              </div>
              <div class="mb-3 col-12 col-md-8">
                <label for="comment" class="form-label">Comentarios</label>
                <textarea class="form-control" id="comment" formControlName="comment" rows="3" placeholder="Agrega un comentario de acuerdo a la acción que vayas a aplicar."></textarea>
              </div>
            </div>
        </div>
      }


      <div class="d-flex justify-content-between p-5">
        <div >
          @if (pFactureParams == 1) {
            @if(uuidpFacture) {
              <a [href]="'https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid='+uuidpFacture" class="btn btn-info mx-1 mb-2 me-md-3" target="_blank">Ver Factura</a>
            }            
          }
          <button type="button" class="btn btn-info mb-2" [disabled]="salesId == 0" (click)="showPrintTicketModal()">Ver Ticket</button>
        </div>

        <div class="d-flex">
          <div class="page-btn me-3" >
            <a [routerLink]="['/sales-list']" class="btn btn-light">
              Cancelar
            </a>
          </div>
          @if(formSale.get('returnId')?.value == null) {
            <div class="page-btn">
              @if(isCancellation || isReturn) {
                <button type="submit" class="btn btn-info">Aplicar
                  @if (isReturn) {
                    Devolución
                  }
                  @else { 
                    Cancelación
                  }
                </button>
              }
            </div>
          }
        </div>

        
      </div>
    </form>
  </div>
</div>

<a class="d-none" id="show-ticket-modal" data-bs-toggle="modal"
                            data-bs-target="#print-ticket-modal"></a>
<div class="modal fade" id="print-ticket-modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Vista Previa de Ticket</h4>
                <button type="button" #closebuttonAddCuscomerPreview class="btn-close" data-bs-dismiss="modal"
                    id="modal-close-button" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row align-items-center justify-content-center">
                    <div class="col-auto">
                        <div class="ticket" id="ticket-container-to-print">
                            <img [src]="ticketLogo" alt="Logo">
                            <p class="centered">TICKET DE VENTA
                                <br>Num: POS-{{salesId}}
                                <br>Fecha: {{currentDateString | date: 'dd/MM/yyyy'}}</p>
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th class="quantity">Cant.</th>
                                        <th class="description">Descripción</th>
                                        <th class="price">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-template ngFor let-data [ngForOf]="saleProducts" let-i="index">
                                        <tr>
                                            <td class="quantity">{{data.quantitySold}}</td>
                                            <td class="description">{{data.productName}}</td>
                                            <td class="price">{{data.priceProduct | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                                        </tr>
                                    </ng-template>
                                    <tr>
                                        <td class="quantity"></td>
                                        <td class="description">TOTAL</td>
                                        <td class="price">{{subtotalPrice | currency: currency + '' :'symbol' : '1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="centered">¡Gracias por su compra!
                                <br>www.colorshop.gt</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn btn-light me-2">Cancelar</button>
                <button type="button" class="btn btn btn-info" (click)="printTicket()"><i class="feather icon-printer text-white me-2"></i> Imprimir</button>
            </div>
        </div>
    </div>
</div>

<p-toast>
</p-toast>
