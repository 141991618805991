import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpectedAmount } from '../_models/cashClosing/expectedAmount';
import { CashRegisterService } from '../_services/cashRegister/cash-register.service';
import { BranchesService } from '../_services/branches/branches.service';
import { BranchesList } from '../_models/branches/branchesList';
import { MessageService } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrl: './cash-register.component.css',
})
export class CashRegisterComponent implements OnInit {
  cashRegisterList: Array<ExpectedAmount> = [];
  selectedCash!: ExpectedAmount;
  selectedCashFlag!: Boolean;
  selectedCasId!: number;
  cashRegisterForm!: FormGroup;
  first = 0;
  rows = 10;
  listBranches: BranchesList[] = [];
  @ViewChild('closebutton') closebutton: any;
  constructor(
    private cashRegisterService: CashRegisterService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private branchesService: BranchesService
  ) {}
  ngOnInit(): void {
    this.cashRegisterForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      id: [],
      branchId: [],
      userId: [],
      nEstatus: ['1', [Validators.required]],
    });
    this.getCashRegisterList();
    this.selectedCashFlag = false;
    this.getBranches();
  }

  ngAfterViewInit() {
    (
      document.getElementById('add-edit-modal') as HTMLElement
    )?.addEventListener('hidden.bs.modal', () => {
      this.cashRegisterForm.reset();
      this.cashRegisterForm.patchValue({
        nEstatus: 1,
        categoryName: '',
        id: null,
      });
    });
  }
  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }
  getCash(id: number, name: String, nEstatus: number) {
    this.cashRegisterForm.patchValue({
      id: id,
      name: name,
      nEstatus: nEstatus,
    });
    this.selectedCashFlag = true;
    this.selectedCasId = id;
    document.getElementById('addeditmodal')?.click();
  }
  getBranches() {
    this.branchesService.getBranches()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          const companyId = Number(localStorage.getItem('idCompany'));
          this.listBranches = value.data;
          this.listBranches = this.listBranches.filter(x => x.companyId === companyId);          
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }
  getCashRegisterList() {
    this.cashRegisterService.getCashRegisters().subscribe({
      next: (result) => {
        if (result.response == 'Ok') {
          this.cashRegisterList = result.data;
        } else {
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al cargar los datos, verifique.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error: (error) =>
        this.messageService.add({
          summary: 'Error',
          detail:
            'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        }),
    });
  }
  resetForm(): void {
    this.selectedCash = {} as ExpectedAmount;
    this.cashRegisterForm.reset();
    this.cashRegisterForm.patchValue({ status: 1, categoryName: '', id: null });
    this.closebutton.nativeElement.click();
  }

  closeAddOrEditForm() {
    this.cashRegisterForm.reset();
    this.cashRegisterForm.patchValue({ status: 1, categoryName: '', id: null });
    this.closebutton.nativeElement.click();
  }
  addOrUpdateCash(id: number) {
    this.cashRegisterForm.markAllAsTouched();
    if (this.cashRegisterForm.valid) {
      if (this.selectedCashFlag) {
        this.cashRegisterForm.patchValue({
          id: this.selectedCasId,
        });
      } else {
        this.cashRegisterForm.patchValue({
          id: null,
        });
      }
      this.cashRegisterService
        .addOrEditCashRegister(this.cashRegisterForm.value)
        .subscribe({
          next: (data) => {
            if (data.data == 'Ok') {
              this.closebutton.nativeElement.click();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Caja agregada/actualizada exitosamente',
                styleClass: 'success-background-popover',
              });
              this.cashRegisterForm.reset();
              this.selectedCashFlag = false;
              this.getCashRegisterList();
            } else {
              this.messageService.add({
                summary: 'Error',
                detail:
                  'Ocurrio un error al guardar la caja, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          },
          error: (error) =>
            this.messageService.add({
              summary: 'Error',
              detail:
                'Ocurrio un error al guardar la caja, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            }),
        });
    } else {
    }
  }
}
