import { Component, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { resumeAuditTable } from '../_models/resumeAudit/resumeAuditTable';
import { ResumeAuditService } from '../_services/resumeAudit/resume-audit.service';
import { MessageService } from 'primeng/api';
import * as FileSaver from 'file-saver';
import { InventoryAuditService } from '../_services/inventoryAudit/inventory-audit.service';

@Component({
  selector: 'app-audit-resume',
  templateUrl: './audit-resume.component.html',
  styleUrl: './audit-resume.component.css'
})
export class AuditResumeComponent implements OnInit {
  idAudit!: number;
  public selectedAction = '';
  public tableData: Array<resumeAuditTable> = [];
  public selectedItem!: resumeAuditTable;
  actionsOptions: dataCompany[] = [
    { value: '...' },
    { value: '...' }
  ];
  constructor(private salesService: ResumeAuditService, private inventoryAuditService: InventoryAuditService, private messageService: MessageService) { }
  ngOnInit(): void {
    this.getResumeAudit();
    this.getLastAudit();
  }
  getResumeAudit() {
    this.salesService.getResumeAudit()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.tableData = result.data;
            if (result.data.length == 0) {
              this.messageService.add({
                summary: 'Error',
                detail: 'No se encontraron auditorias en proceso, guarde al menos un conteo e intente nuevamente',
                styleClass: 'danger-background-popover',
              })
            }
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente nuevamente.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getLastAudit() {
    this.salesService.getLastAudit()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.idAudit = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente nuevamente.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.tableData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "Audit Resume");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  finishAudit() {
    if(this.tableData.length > 0){
      this.inventoryAuditService.finishAudit()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auditoria Terminada exitosamente', styleClass: 'success-background-popover' });
            this.getResumeAudit();
            this.getLastAudit();
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al finalizar la auditoria, intente nuevamente.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
    }
  }
}
