import { Component, Input, OnInit } from '@angular/core';
import { ParametersTable } from '../_models/parameters';
import { ParametersService } from '../_services/parameters/parameters.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrl: './parameters.component.css',
})
export class ParametersComponent implements OnInit {
  
  listParameters: ParametersTable[] = [];

  first = 0;
  rows = 10;

  constructor(private parametersSrv: ParametersService) { }

  ngOnInit(): void {
    this.getParameters();
  }
  
  getParameters() {
    this.parametersSrv.getParameters()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listParameters = value.data;
        }
        else{

        }
      },
      error:(err) => {

      },
    })
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }
}
