import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  getUsers() {
    return this.http.get<any>(`${environment.url}users`);
  }

  getUserById(id: number) {
    return this.http.get<any>(`${environment.url}users/${id}`);
  }

  addOrUpdateUsers(data: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}users`,data,{headers: header});
  }
}
