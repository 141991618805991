import { Component, OnInit } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { PromotionsService } from '../_services/promotions/promotions.service';
import { CommonSelect } from '../_models/common';
import { PromotionsTable } from '../_models/promotions';

@Component({
  selector: 'app-proposals',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
  public searchDataValue = '';

  typePromotion: number = 0;
  dateStart: Date = new Date();
  dateEnd: Date = new Date();
  status: number = 3;
  listTypeProm: CommonSelect[] = [];
  promotionsList: PromotionsTable[] = [];

  selectedList1: dataCompany[] = [
    { value: 'Creación (antiguos primero)' },
    { value: 'Creación (recientes primero)' },
  ];

  constructor(
    private promotionsSrv: PromotionsService
  ) { }

  ngOnInit() {
    this.dateStart.setMonth(this.dateStart.getMonth() - 1);
    this.dateEnd.setMonth(this.dateEnd.getMonth() + 1);
    this.getPromotions();
    this.getTypesProm();
  }


  getPromotions() {
    this.promotionsSrv.getPromotions(this.typePromotion,this.dateStart.toISOString(),this.dateEnd.toISOString(),this.status)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.promotionsList = value.data;
        }
      },
      error:(err) => {
        
      },
    })
  }

  getTypesProm() {
    this.promotionsSrv.getTypesPromotions()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listTypeProm = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

}
