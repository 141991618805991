<div class="ticket">
    <img src="../../assets/images/logo1.jpg" alt="Logo">
    <p class="centered">TICKET DE VENTA
        <br>Num: 001
        <br>Fecha: 03/14/2024</p>
    <table>
        <thead>
            <tr>
                <th class="quantity">Cant.</th>
                <th class="description">Descripción</th>
                <th class="price">Precio</th>
            </tr>
        </thead>
        <tbody>
            <ng-template ngFor let-data [ngForOf]="itemsAdded" let-i="index">
                <tr>
                    <td class="quantity">{{data.quantity}}</td>
                    <td class="description">{{data.name}}</td>
                    <td class="price">{{data.total}}</td>
                </tr>
            </ng-template>
            <tr>
                <td class="quantity"></td>
                <td class="description">TOTAL</td>
                <td class="price">Q777</td>
            </tr>
        </tbody>
    </table>
    <p class="centered">¡Gracias por su compra!
        <br>www.colorshop.gt</p>
</div>