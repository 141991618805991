<app-loader></app-loader>
<app-core-component id="core-component" class=""></app-core-component>

<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '25rem' }">
    <h3 class="fw-bold text-center mb-1">SU SESIÓN A EXPIRADO POR INACTIVIDAD</h3>
    <p class="text-center mb-4">Favor de iniciar sesión para continuar.</p>

    <div class="flex justify-content-center text-center">
        <p-button label="Aceptar" (click)="visible = false" />
    </div>
</p-dialog>

