<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Reporte de Tintes</h4>
        </div>
    </div>

    <div class="page-btn">
    </div>
    <div class="page-btn">
    </div>
</div>
<div class="card table-list-card">
    <div class="card-body">
        <div class="w-100">
            <div class="row align-items-center pt-4 ps-3">
                <div class="col-6">
                    <div class="form-sort">                        
                        <mat-select [(ngModel)]="selectedAction" (selectionChange)="getAction()"
                            [ngModelOptions]="{standalone: true}" class="select space-select" placeholder="Reportes">
                            <ng-template ngFor let-data [ngForOf]="actionsOptions" let-i="index">
                                <mat-option [value]="data.value">
                                    {{data.name}}
                                </mat-option>
                            </ng-template>
                        </mat-select>
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Inicio</label>
                        <input type="date" class="form-control" [(ngModel)]="startDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="mb-3 add-product">
                        <label class="form-label">Fecha Fin</label>
                        <input type="date" class="form-control" [(ngModel)]="endDate"
                            (keydown.enter)="getReportData()" (change)="getReportData()" />
                    </div>
                </div>
                <div class="col-2">
                    <div class="page-btn pe-4">
                        <a class="btn btn-info" id="donwload-table-data" (click)="exportData()"><i
                                class="feather icon-chevron-down"></i> Descargar </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <p-table [value]="reporData" selectionMode="single" [paginator]="true" [rows]="20"
                [(selection)]="selectedReportRowA" class="table  datanew" dataKey="id"
                [tableStyle]="{'min-width': '50rem'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Fecha</th>
                        <th>Negro Y</th>
                        <th>Negro P</th>
                        <th>Amarillo Oxido Y</th>
                        <th>Amarillo Oxido P</th>
                        <th>Verde Oftalo Y</th>
                        <th>Verde Oftalo P</th>
                        <th>Azul Oftalo Y</th>
                        <th>Azul Oftalo P</th>
                        <th>Rojo Oxido Y</th>
                        <th>Rojo Oxido P</th>
                        <th>Café Oxido Y</th>
                        <th>Café Oxido P</th>
                        <th>Bioxido Titanio Y</th>
                        <th>Bioxido Titanio P</th>
                        <th>Cafe Marron Y</th>
                        <th>Cafe Marron P</th>
                        <th>Rojo Bermellon Y</th>
                        <th>Rojo Bermellon P</th>
                        <th>Amarillo Rojizo Y</th>
                        <th>Amarillo Rojizo P</th>
                        <th>Magenta Y</th>
                        <th>Magenta P</th>
                        <th>Amarillo Canario Y</th>
                        <th>Amarillo Canario P</th>
                        <th>Comentarios</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                    <tr>
                    <td>{{report.fecha}}</td>
                    <td>{{report.negroy}}</td>
                    <td>{{report.negrop}}</td>
                    <td>{{report.amarillooxidoy}}</td>
                    <td>{{report.amarillooxidop}}</td>
                    <td>{{report.verdeoftaloy}}</td>
                    <td>{{report.verdeoftalop}}</td>
                    <td>{{report.azuloftaloy}}</td>
                    <td>{{report.azuloftalop}}</td>
                    <td>{{report.rojooxidoy}}</td>
                    <td>{{report.rojooxidop}}</td>
                    <td>{{report.cafeoxidoy}}</td>
                    <td>{{report.cafeoxidop}}</td>
                    <td>{{report.bioxidotitanioy}}</td>
                    <td>{{report.bioxidotitaniop}}</td>
                    <td>{{report.cafemarrony}}</td>
                    <td>{{report.cafemarronp}}</td>
                    <td>{{report.rojobermellony}}</td>
                    <td>{{report.rojobermellonp}}</td>
                    <td>{{report.amarillorojizoy}}</td>
                    <td>{{report.amarillorojizop}}</td>
                    <td>{{report.magentay}}</td>
                    <td>{{report.magentap}}</td>
                    <td>{{report.amarillocanarioy}}</td>
                    <td>{{report.amarillocanariop}}</td>
                    <td>{{report.ccomentarios}}</td>

                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-toast>
</p-toast>