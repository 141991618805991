import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CashClosingHistoricalService {

  constructor(private http: HttpClient) { }
  getCashClosingHistorical() {
    return this.http.get<any>(`${environment.url}CashClosingHistorical`);
  }
  paymentMethods(cashClosingResume: number) {
    return this.http.get<any>(`${environment.url}CashClosingHistorical/paymentMethods?cashClosingResume=` + cashClosingResume);
  }
  getCashMovements(cashClosingResume: number) {
    return this.http.get<any>(`${environment.url}CashClosingHistorical/getCashMovements?cashClosingResume=` + cashClosingResume);
  }
}
