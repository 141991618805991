import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { companiesList } from '../_models/companiesList';
import Swal from 'sweetalert2';
import { dataCompany } from '../_models/dataCompany';
import { CompaniesService } from '../_services/companies/companies.service';
import { MessageService } from 'primeng/api';
import { FileSaverService } from 'ngx-filesaver';
import { reportsTypes } from '../_models/sales/reportsTypes';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'], 
  providers: [MessageService]
})
export class CompaniesComponent {
  isCollapsed: boolean = false;
  public searchDataValue = '';
  public selectedValue1 = '';
  companyForm!: FormGroup;
  templateForm!: FormGroup;
  templateFormDataFiles!: FormData;
  addEditForm: boolean = false;
  public tableData: Array<companiesList> = [];
  selectedCompany!: companiesList;
  
  selectedList1: dataCompany[] = [
    { value: 'Nombre' },
    { value: 'Antiguo' },
    { value: 'Nuevo' },
  ];
  opciones: reportsTypes[] = [
    {value: 1, name: 'Quetzal'},
    {value: 2, name: 'Peso Mexicano'}
  ];

  selectedAction: number = 0;

  @ViewChild('closebutton') closebutton: any;
  @ViewChild('addeditmodal') addeditmodal: any;

  imgLogo: string = "";
  imgLogoTicket: string = "";


  constructor(private companiesService: CompaniesService, private formBuilder: FormBuilder, private messageService: MessageService, private _FileSaverService: FileSaverService) {
  }
  toggleCollapse() {
    // this.sidebar.toggleCollapse();
    this.isCollapsed = !this.isCollapsed;
  }
  public filter = false;
  openFilter() {
    this.filter = !this.filter;
  }
  sortData() {
    this.getCompaniesList();
  }
  confirmColor() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: ' btn btn-success',
        cancelButton: 'me-2 btn btn-danger',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        confirmButtonText: 'Yes, delete it!',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }
  showAddEditForm(selectedCompany: companiesList) {
    if (selectedCompany != null) {

    }
    else {
      this.addEditForm = true;
    }
  }
  getCompaniesList() {
    document.getElementById('')
    this.companiesService.getCompanies(this.searchDataValue, this.selectedValue1)
      .subscribe(data => {
        if (data.response == 'Ok') {
          this.tableData = data.data;
        }
        else {

        }
      });
  }
  addOrUpdateCompany() {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.valid) {
      this.companiesService.addOrUpdateCompay(this.companyForm.value)
        .subscribe(data => {
          if (data.data == "Ok") {
            this.closebutton.nativeElement.click();
            this.getCompaniesList();
            if(this.companyForm.controls['id'].value != null){
              this.showToastOk('Empresa actualizada exitosamente');
            }
            else {
              this.showToastOk('Empresa agregada exitosamente');
            }
            this.companyForm.reset();
          }
          else if(data.data == "error-nit"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Error ya existe una Empresa con el NIT indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(data.data == "error-name"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Error ya existe una Empresa con el Nombre indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(data.data == "error-bname"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Error ya existe una Empresa con el Nombre Comercial indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al guardar la empresa, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        });
    }
    else {

    }
  }
  ngOnInit() {
    this.companyForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      adminName: ['', [Validators.required]],
      fiscalName: ['', [Validators.required]],
      nit: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      id: [null],
      imgLogo: ['',Validators.required],
      imgTicket: [''],
      validateClient: [''],
      taxIdentifier: ['',Validators.required],
      moneda: [0 ,Validators.required],

    });
    this.templateForm = this.formBuilder.group({
      excelDocument: [null, Validators.required]
    });
    this.getCompaniesList();
  }
  closeAddOrEditForm(){
    this.companyForm.reset();
    this.closebutton.nativeElement.click();
  }
  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }
  downloadTemplateFile(){
    this.companiesService.download()
    .subscribe({
      next: blob=> this._FileSaverService.save(blob, 'template.xlsx'),
      error: error => this.messageService.add({
        summary: 'Error',
        detail: 'Ocurrio un error al descargar el archivo, contacte con el administrador del sistema.',
        styleClass: 'danger-background-popover',
      })
    });
  }
  getCompany(id: number){
    this.companiesService.getCompany(id)
    .subscribe({
      next: result => {
        this.companyForm.setValue({
          name: result.data.name,
          businessName: result.data.businessName,
          adminName: result.data.adminName,
          fiscalName: result.data.fiscalName,
          nit: result.data.nit,
          address: result.data.address,
          city: result.data.city,
          country: result.data.country,
          email: result.data.email,
          phoneNumber: result.data.phoneNumber,
          id: result.data.id,
          imgLogo: result.data.imgLogo,
          imgTicket: result.data.imgTicket,
          validateClient: result.data.validateClient,
          taxIdentifier: result.data.taxIdentifier,
          moneda: result.data.moneda
        });
        this.imgLogo = result.data.imgLogo;
        this.imgLogoTicket = result.data.imgTicket;
        document.getElementById('addeditmodal')?.click();
      },
      error: error => this.messageService.add({
        summary: 'Error',
        detail: 'Ocurrio un error al obtener la empresa, contacte con el administrador del sistema.',
        styleClass: 'danger-background-popover',
      })
    });
  }
  onfileChange(files: FileList) {
    if (files.length > 0) {
      this.templateFormDataFiles = new FormData();
      this.templateFormDataFiles.append('chager' + Date.now.toString + '.xlsx', files[0]);
      this.submitForm();
    }
  }
  uploadFile() {
    this.companiesService.uploadFile(this.templateFormDataFiles)
    .subscribe({
          next: result=> {
          if (result.data == "Ok") {
            this.showToastOk('Documento procesado con exito.');
            this.getCompaniesList();
          }
          else if(result.data == "Document not Valid"){
            this.messageService.add({
              summary: 'Error',
              detail: 'El documento no es valido para la carga masiva, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al leer el documento, verifique.',
              styleClass: 'danger-background-popover',
            })
          } }
          ,
          error: error => this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al leer el archivo, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          })
        });
  }

  imgLogoNav(src: string) {
    this.imgLogo = src;
    this.companyForm.patchValue({imgLogo: src});
    // const image = new Image();
    //     image.src = src as string;
    //     image.onload = () => {
    //       const width = image.width;
    //       const height = image.height;
    //       if (width <= 140 && height <= 33) {
    //         this.imgLogo = src;
    //         this.companyForm.patchValue({imgLogo: src});
    //       } 
    //       else {
    //         this.messageService.add({
    //           summary: 'Error',
    //           detail: 'Las dimensiones de la imagen deben ser menores o iguales a 140 x 33 pixeles. Favor de verificar.',
    //           styleClass: 'danger-background-popover',
    //         })
    //       }
    //   };
  }

  onImgSelected(event: any, option: number): void {
    const file: File = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64Image = e.target.result;
        console.log(base64Image);
        
        if(option == 1) {
          this.imgLogoNav(base64Image);
        }
        else {
          this.imgLogoTicket = base64Image;
          this.companyForm.patchValue({imgTicket: base64Image});
        }
      };
      reader.readAsDataURL(file);
    }
  }

  getAction() { }
  
  onClickInputLogo(idInput: string) {
    document.getElementById(idInput)?.click();
  }

  submitForm(): void {
    if (this.templateForm.valid) {
      this.uploadFile();
    } else {
      if (!this.templateForm.get('excelDocument')!.valid) {
        this.messageService.add({
          summary: 'Error',
          detail: 'Debe selecionar un documento de datos, para la importación. Verificar.',
          styleClass: 'danger-background-popover',
        })
      }
    }
  }
}
