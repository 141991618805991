import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appMaxDecimal]'
})
export class MaxDecimalDirective {

  constructor() { }
  @HostListener('change', ['$event']) onKeyDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    
    if (target && target.getAttribute('type') === 'number') {
      console.log(event.key);
      
     if(event.key.split('.')[1] != undefined) {
      let countDecimal = event.key.split('.')[1].length;
      console.log(countDecimal);
      
      if(countDecimal > 2 ) event.preventDefault();
     }
     
    }
    if (target && target.getAttribute('type') === 'number' && event.key === '-') {
      event.preventDefault();
    }
  }

}
