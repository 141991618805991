import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BranchesList } from 'src/app/_models/branches/branchesList';
import { BranchesService } from 'src/app/_services/branches/branches.service';
import { CommonService } from 'src/app/_services/common/common.service';
import { ProductsService } from 'src/app/_services/products/products.service';
import { Cookies } from 'typescript-cookie';

@Component({
  selector: 'app-price-loading',
  templateUrl: './price-loading.component.html',
  styleUrls: ['./price-loading.component.css']
})
export class PriceLoadingComponent implements OnInit {


  massivePriceForm!: FormGroup;
  archivo!: FormData;
  listBranch: BranchesList[] = [];

  constructor (
    private productSvr: ProductsService,
    private messageService: MessageService,
    private branchSrv: BranchesService,
    private fb: FormBuilder,
    // private salesService: SalesService,
    private commonSrv: CommonService,
    // private xlxsSrv: DownloadExcelService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getBranch();
    this.archivo = new FormData;
  }

  initializeForm() {
    this.massivePriceForm = this.fb.group({
      nameUser: [''],
      nameFile: [''],
      excelFile: ['',Validators.required],
      idBranches: [[],Validators.required]
    })
  }

  get accesBranches() {
    return JSON.parse(Cookies.get('accesBranches')!.toString());
  }

  getBranch() {
    this.branchSrv.getBranches()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          if(this.accesBranches) {
            this.listBranch = value.data;
          }
          else {
            this.listBranch = value.data.filter((b:any) => b.idSucursal == parseInt(this.commonSrv.branchId));
          }
          
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  openFileInput() {
    (document.getElementById('fileInput') as HTMLInputElement).click();
  }

  selectFile(file: FileList) {
    this.massivePriceForm.patchValue({nameFile:file[0].name})
    this.archivo.append('excelFile',file[0]);
    this.massivePriceForm.patchValue({ excelFile: this.archivo, nameUser: this.commonSrv.userName});
  }

  addUsersInBulk() {
    this.massivePriceForm.markAllAsTouched();
    if(this.massivePriceForm.valid) {
      let url = "";
      const {idBranches} = this.massivePriceForm.value;

      idBranches.forEach((id:number) => {
        url+= `idBranches=${id}&`;
      });

      this.productSvr.bulkUploadPricing(this.archivo,url)
      .subscribe({
        next:(value) => {
          if(value.response == 'Ok') {
            this.initializeForm();
            (document.getElementById('fileInput') as HTMLInputElement).value = "";
            this.showToastOk('Precios agregados/actualizados exitosamente.');
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar precios, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error ", err);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar precios, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
        },
      })
    }
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
  }

}
