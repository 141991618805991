import { Component, OnInit } from '@angular/core';
import { LoginService } from '../_services/login/login.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  email: string = "";
  isValidEmail: boolean = false;
  code: number = 0;

  constructor(
    private loginSrv: LoginService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.code = Math.floor(1000 + Math.random() * 9000);
  }

  passwordValidator(control: any) {
    const password = control.value;

    const containsNumbers = /\d/.test(password);
    const containsCapitalLetters = /[A-Z]/.test(password);
    const containsLowercaseLetters = /[a-z]/.test(password);
    const containsCharacters = /[^a-zA-Z0-9]/.test(password);

    if(!containsNumbers) {
      return { containsNumbers: true };
    }
    if(!containsCapitalLetters) {
      return { containsCapitalLetters: true };
    }
    if(!containsLowercaseLetters) {
      return { containsLowercaseLetters: true };
    }
    if(!containsCharacters) {
      return { containsCharacters: true };
    }
    else {
      return null
    }
  }

  validEmail() {
    const patronCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    (patronCorreo.test(this.email)) ? this.isValidEmail = true : this.isValidEmail = false;
  }


  sendEmail() {
    if(this.isValidEmail) {
      let data = {
        email: this.email,
        code: this.code
      }

      this.loginSrv.sendEmail(data)
      .subscribe({
        next:(value) => {
          if(value.response == 'Ok') {
            if(value.data == 'Ok') {
             // console.log("Correo enviado");
              
            }
            else if(value.data == 'error-user') {
              this.messageService.add({
                summary: 'Error',
                detail: 'El email ingresado no se encuentra registrado, favor de verificar.',
                styleClass: 'danger-background-popover',
              });
            }
            else {
              console.warn("Error reset Password ", value);
              this.messageService.add({
                summary: 'Error',
                detail: 'Ocurrio un error, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          }
          else {
            console.warn("Error reset Password ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          console.error("Error reset Password ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      })
    }
    
  }

}
