import { Component, OnInit } from '@angular/core';
import { SalesService } from '../_services/sales/sales.service';
import { MessageService } from 'primeng/api';
import { ProductsService } from '../_services/products/products.service';
import { ProductsTable } from '../_models/products';
import { dataCompany } from '../_models/dataCompany';
import { FormControl } from '@angular/forms';
import { CommonService } from '../_services/common/common.service';
import { DownloadExcelService } from '../_services/common/downloadExcel.service';
import { Cookies } from 'typescript-cookie';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public searchDataValue = '';
  public selectedValue1 = '';
  first = 0;
  rows = 10;
  currency: String = '';


  selectedList1: dataCompany[] = [
    { value: 'Nombre (A-Z)' },
    { value: 'Nombre (Z-A)' },
    { value: 'SKU (A-Z)' },
    { value: 'SKU (Z-A)' },
    { value: 'Categoría (A-Z)' },
    { value: 'Categoría (Z-A)' },
    { value: 'Menos Stock' },
    { value: 'Mayor Stock' },
    { value: 'Antiguos' },
    { value: 'Nuevos' },
  ];

  toppings = new FormControl('');
  toppingList: number[] = [1, 2, 3, 4, 5, 6];

  public productsData: ProductsTable[] = [];

  constructor (
    private productSvr: ProductsService,
    private messageService: MessageService,
    private salesService: SalesService,
    private commonSrv: CommonService,
    private xlxsSrv: DownloadExcelService
  ) {}

  ngOnInit() {
    this.getProducts();
    this.getCurrency();
  }

  get editProducts() {
    return JSON.parse(Cookies.get('editProducts')!.toString());
  }

  getProducts() {
    this.productSvr.getProducts(this.searchDataValue, this.selectedValue1.replace(' ',''), parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.productsData = value.data;
          this.first = 0
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  getCurrency() {
    this.salesService.getCurrency()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.currency = result.data;
          }
          else {
            console.error(result)
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante la carga de datos de móneda, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
  }

  exportToExcel() {
    let products = this.productsData.map(product => {
      return {
        id_Produto: product.id,
        Nombre_Empresa: this.commonSrv.company,
        Nombre_Sucursal: this.commonSrv.branch,
        Codigo_Producto: product.code,
        Nombre_Producto: product.name,
        Descripcion: product.description,
        Categoria: product.category,
        Variante: product.variant,
        Es_Kit: (product.isKit) ? 'Sí' : 'No',
        Precio_Incluye_Impuesto: product.isTax ??'',
        Porcentaje_Impuesto: product.taxPercentage??'0' + '%',
        Inventario: product.inventory ?? '0',
        Precio_Compra: product.purchasePrice ?? '0',
        Precio_Venta: product.cost ??'0'        
      }
    });
    this.xlxsSrv.exportAsExcelFile(products,'Listado de Productos');
  }

}
