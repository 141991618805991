import { Component, Input, OnInit } from '@angular/core';
import { ProductsService } from '../_services/products/products.service';
import { ProductsTable } from '../_models/products';
import { MessageService } from 'primeng/api';
import { TransfersService } from '../_services/transfers/transfers.service';
import { ProductTransfer, TypeTransfer } from '../_models/transfers';
import { BranchesService } from '../_services/branches/branches.service';
import { BranchesList } from '../_models/branches/branchesList';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-transfers-form',
  templateUrl: './transfers-form.component.html',
  styleUrls: ['./transfers-form.component.css']
})
export class TransfersFormComponent implements OnInit {

  title: string = "Realizar";
  listProducts: ProductsTable[] = [];
  listProdTransfer: ProductTransfer[] = [];

  listTypeTranfers: TypeTransfer[] = [];
  listBranches: BranchesList[] = [];
  listBranchesDes: BranchesList[] = [];

  formTransfer!: FormGroup;
  idTransfer: number = 0;
  idProductDes: number = 0;
  isNewTrasfer: boolean = true;


  first = 0;
  rows = 10;
  user = ""

  constructor(
    private productsSrv: ProductsService,
    private messageService: MessageService,
    private transferSrv: TransfersService,
    private branchesSrv: BranchesService,
    private commonSrv: CommonService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.user = this.commonSrv.userName;
    await this.getProducts();
    await this.getTransferById(this.idTransfer);
    this.getTypeTransfers();
    this.getBanches();
    this.initializeForm();
    
  }

  @Input() 
  set id(idTrasnfer: number) {
    if(idTrasnfer == undefined) {
      this.title = "Realizar";
    }
    else {
      this.title = "Autorizar";
      this.idTransfer = idTrasnfer;
      //this.getTransferById(idTrasnfer);
    }
  }

  initializeForm() {
    this.formTransfer = this.fb.group({
      idTransfer:null,
      typeTransfer:['',Validators.required],
      origin: [{value: parseInt(this.commonSrv.branchId!), disabled: true}],
      destination: ['',Validators.required],
      authorizationStatus: 1,
      transferValue: ['',Validators.required],
      commentsCreate: ['',Validators.required],
      commentsUpdate: [''],
      idUser: [this.user],
      products: [{}, Validators.required]
    })
  }

  getTypeTransfers() {
    this.transferSrv.getTypeTransfers()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listTypeTranfers = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  async getProducts() {
    let term = "";
    if(document.getElementById('searchTerm')) term = (document.getElementById('searchTerm') as HTMLInputElement).value;
    this.productsSrv.getProducts(term,'',parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listProducts = value.data.filter((x: ProductsTable) => x.inventory > 0);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getBanches() {
    this.branchesSrv.getBranches()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listBranches = value.data;
          this.listBranchesDes = this.listBranches.filter(x => x.idSucursal != this.formTransfer.get('origin')?.value && x.businessName == this.commonSrv.company);
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  selecProduct(product: ProductsTable) {

    if(this.listProdTransfer.find(x => x.idOriginProduct == product.id) != undefined) {
        this.messageService.add({
        summary: '',
        detail: 'El producto seleccionado ya fue anteriormente agregado.',
        styleClass: 'warning-background-popover',
      });
      return;
    }

    let newProduct: ProductTransfer = {
      nameProduct: product.name,
      nameProductDestination: product.nameDes,
      

      idOriginProduct: product.id,
      originQuantity: product.inventory,
      originUnitValue: product.cost,

      idDestinationProduct: product.id,
      destinationQuantity: 0,
      destinationUnitValue: product.cost,

      receivedAmount:  0,
      status: 1,
      userCreation: this.user,
      userUpdate: this.user
    }

    this.listProdTransfer.push(newProduct);
    document.getElementById('btn-close')?.click();
  }

  changeQuantityProduc(idProduct: number) {
    let quantityStok = this.listProducts.find(x => x.id == idProduct)?.inventory!;
    let newStok = (document.getElementById('quantity'+idProduct) as HTMLInputElement).value;
    if(quantityStok < parseInt(newStok)) {
      (document.getElementById('quantity'+idProduct) as HTMLInputElement).value = quantityStok.toString();
      return;
    }

    this.listProdTransfer = this.listProdTransfer.map(product => {
      if(idProduct == product.idOriginProduct) {
        product.originQuantity = quantityStok;
        product.destinationQuantity = parseInt(newStok);
        return product;
      }
      else {
        return product;
      }
    });
  }

 
  deleteProductList(id: number) {
    this.listProdTransfer = this.listProdTransfer.filter(x => x.idOriginProduct != id);
  }

  filterDestinations() {
    this.formTransfer.enable();
    const {typeTransfer, origin} = this.formTransfer.value;

    if(typeTransfer != 3) {
      this.listBranchesDes = this.listBranches.filter(x => x.idSucursal != this.formTransfer.get('origin')?.value && x.businessName == this.commonSrv.company);
    }
    else {
      this.listBranchesDes = this.listBranches;
      this.formTransfer.patchValue({'destination': origin});
      this.formTransfer.get('destination')?.disable();
    }

    this.formTransfer.get('origin')?.disable();
  }

  createTransfer() {
    this.formTransfer.patchValue({'products': this.listProdTransfer,'idUser': this.user});
    this.formTransfer.enable();
    this.formTransfer.markAllAsTouched();

    if(this.formTransfer.valid) {
      this.transferSrv.createTransfer(this.formTransfer.value)
        .subscribe({
          next:(value) => {
            if(value.data == 'Ok') {
              if(this.title == "Realizar") {
                this.showToastOk('Transferencia agregada exitosamente.');
                this.initializeForm();
                this.listProdTransfer = [];
              }
              else {
                this.router.navigate(['/transfers']);
                this.showToastOk('Usuario editado exitosamente.');
              }
             
            }
            else {
              console.warn("Error ", value);
              this.messageService.add({
                summary: 'Error',
                detail: 'Hubo un problema al crear la transferencia, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          },
          error(err) {
            
          },
        })
    }
    else {

      if(this.title == "Autorizar") {
        this.formTransfer.disable();
        this.formTransfer.get('idTransfer')?.enable();
        this.formTransfer.get('authorizationStatus')?.enable();
        this.formTransfer.get('comments')?.enable();
      }

      this.formTransfer.get('origin')?.disable();
      if(this.formTransfer.get('typeTransfer')?.value == 3)this.formTransfer.get('destination')?.disable();
      this.messageService.add({
        summary: 'Formulario incompleto',
        detail: 'Favor de llenar el formulario correctamente.',
        styleClass: 'danger-background-popover',
      });
    }
    
  }



  // ********************** TRANSEFERNCIA INTERNA *******************************

  selectProductDes(product: ProductsTable) {
    this.listProdTransfer = this.listProdTransfer.map( x => {
      if(x.idOriginProduct == this.idProductDes) {
        (document.getElementById('productDes' + x.idDestinationProduct) as HTMLElement).innerHTML = product.name;
        x.idDestinationProduct = product.id;
        
        return x;
      }
      else {
        return x;
      }
    })
    document.getElementById('btn-closeDes')?.click();
  }

  changeQuantityOriginDesc(idProduct: number, option: string) {
    let quantityStok = this.listProducts.find(x => x.id == idProduct)?.inventory!;
    let newStok = (document.getElementById(option+idProduct) as HTMLInputElement).value;
    if(quantityStok < parseInt(newStok)) {
      (document.getElementById(option+idProduct) as HTMLInputElement).value = quantityStok.toString();
      return;
    }

    this.listProdTransfer = this.listProdTransfer.map(product => {

      if(option == 'quantityOrigin' && idProduct == product.idOriginProduct) {
        product.originQuantity = parseInt(newStok);
      }
      else if(option == 'quantityDestination' && idProduct == product.idDestinationProduct) {
        product.destinationQuantity = parseInt(newStok);
      }

      return product;
    });
  }

  //******************** UPDATE TRASNFER **********************************


  async getTransferById(id: number) {
    this.transferSrv.getTransferById(id)
    .subscribe({
      next:(value) => {
        this.listProdTransfer = value.data.products;
        this.formTransfer.patchValue(value.data);
        this.filterDestinations();
        this.formTransfer.disable();

        this.formTransfer.get('idTransfer')?.enable();
        this.formTransfer.get('authorizationStatus')?.enable();
        this.formTransfer.get('commentsUpdate')?.enable();
        if(value.data.authorizationStatus != 1) this.isNewTrasfer = false;
      },
      error:(err) =>  {
        
      },
    })
  }

  changeReceivedQuantity(idProduct: number) {
    let receivedQuantity =  (document.getElementById('receivedQuantity'+idProduct) as HTMLInputElement).value;
    let quantity = (document.getElementById('quantity'+idProduct) as HTMLInputElement).value;

    if(parseInt(quantity) < parseInt(receivedQuantity)) {
      (document.getElementById('receivedQuantity'+idProduct) as HTMLInputElement).value = quantity.toString();
      return;
    }

    this.listProdTransfer = this.listProdTransfer.map(product => {
      if(idProduct == product.idDestinationProduct) {
        product.receivedAmount = parseInt(receivedQuantity);
        return product;
      }
      else {
        return product;
      }
    });
  }


  changeReceivedQuantityBranch(idProduct: number) {
    let receivedQuantity =  (document.getElementById('receivedQuantity'+idProduct) as HTMLInputElement).value;
    let quantity = (document.getElementById('quantityDestination'+idProduct) as HTMLInputElement).value;

    if(parseInt(quantity) < parseInt(receivedQuantity)) {
      (document.getElementById('receivedQuantity'+idProduct) as HTMLInputElement).value = quantity.toString();
      return;
    }

    this.listProdTransfer = this.listProdTransfer.map(product => {
      if(idProduct == product.idDestinationProduct) {
        product.receivedAmount = parseInt(receivedQuantity);
        return product;
      }
      else {
        return product;
      }
    });
  }



  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

}
