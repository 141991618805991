import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonSelect } from '../_models/common';
import { PromotionsService } from '../_services/promotions/promotions.service';
import { ProductsTable } from '../_models/products';
import { ProductsService } from '../_services/products/products.service';
import { MessageService } from 'primeng/api';
import { customersList } from '../_models/customers/customersList';
import { CustomersService } from '../_services/customers/customers.service';
import { CommonService } from '../_services/common/common.service';

@Component({
  selector: 'app-promotions-form',
  templateUrl: './promotions-form.component.html',
  styleUrls: ['./promotions-form.component.css']
})
export class PromotionsFormComponent implements OnInit {
  
  title: string = "Nueva";
  currentDate = new Date();
  formPromotions!: FormGroup;

  listTypeProm: CommonSelect[] = [];
  listRuler: CommonSelect[] = [];
  listRulerAux: CommonSelect[] = [];
  productsData: ProductsTable[] = [];
  productsDataAux: ProductsTable[] = [];
  inputFilter: string = '';
  isProductPromo = false;

  customersList: customersList[] = [];
  customersListAux: customersList[] = [];
  inputFilterCustomer: string = '';

  @Input()
  set id(idPromotion: string) {
    if(idPromotion == undefined) {
      this.title = "Nueva";
    }
    else {
      this.title = "Editar";
      this.getPromotion(parseInt(idPromotion.split('-')[0]),idPromotion.split('-')[1]);
    }
  }

  constructor(
    private fb: FormBuilder,
    private promotionsSrv: PromotionsService,
    private productsSrv: ProductsService,
    private messageService: MessageService,
    private customersSrv: CustomersService,
    private commonSrv: CommonService
  ) { }

  ngOnInit() {
    this.getTypesProm();
    this.form();
    this.getRuler();
    this.getProducts();
    this.getCustomers();
  }

  form() {
    return this.formPromotions = this.fb.group({
      promotionId: [null],
      promotionName:[''],
      typePromotion: [1,Validators.required],
      dateStart: ['', Validators.required],
      dateEnd: ['',Validators.required],
      productId: [null],
      productCode: [null],
      ruler: [1],
      status: [true],
      percentage:[null],
      clientId: [null],
      clientName: [null],
      couponCode: [null],
      description: [null],
      cardNumber: [null],
      saldo: [null],
      quantity: [null],
      productCodeProm: [null],
      productCodePromId: [null],
      quantityPromtion: [null],
      amountPromtion: [null],
      discountAmount: [null],
      nameUser: '',
      branchId: 0
    });
  }

  getPromotion(id: number, typePromotion: string) {
    this.promotionsSrv.getPromotion(id, typePromotion)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.formPromotions.patchValue(value.data);
          this.formPromotions.patchValue({
            dateStart:  new Date((this.formPromotions.get('dateStart')?.value)),
            dateEnd:  new Date((this.formPromotions.get('dateEnd')?.value))
          })
          this.filtesRule();
          this.formPromotions.disable();
          this.formPromotions.get('promotionId')?.enable();
          this.formPromotions.get('status')?.enable();
        }
        else {

        }
      },
      error(err) {
        
      },
    })
  }

  getTypesProm() {
    this.promotionsSrv.getTypesPromotions()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listTypeProm = value.data;
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  getRuler() {
    this.promotionsSrv.getRuler()
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.listRuler = value.data;
          this.listRulerAux = value.data;
          this.filtesRule();
        }
        else {

        }
      },
      error:(err) => {
        
      },
    })
  }

  filtesRule() {
    
    let idPromotion = this.formPromotions.get('typePromotion')?.value;

    if(idPromotion == 1 || idPromotion == 3) {
      this.listRuler = this.listRulerAux.filter(r => r.id != 5);
    }
    else if(idPromotion == 2 || idPromotion == 4) {
      this.listRuler =this.listRulerAux.filter(r => r.id == 1 || r.id == 2);
    }
    else if(idPromotion == 5) {
      this.listRuler =this.listRulerAux.filter(r => r.id == 5);
    }
  }

  getProducts() {
    this.productsSrv.getProducts('','',parseInt(this.commonSrv.branchId))
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.productsData = value.data;
          this.productsDataAux = this.productsData;
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error al obtener los productos, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getCustomers() {
    this.customersSrv.getCustomers('','')
    .subscribe({
      next:(value) =>{
        if(value.response == 'Ok') {
          this.customersList = value.data;
          this.customersListAux = value.data;
        }
      },
      error:(err) => {
        
      },
    })
  }

  filterProduct() {
    if(this.inputFilter != null && this.productsDataAux != null) {
      this.productsData = this.productsDataAux.filter(p =>  
        (p.code && p.code.includes(this.inputFilter)) || 
        (p.name && p.name.toLowerCase().includes(this.inputFilter.toLowerCase())));
    }
  }

  filterCustomer() {
    if(this.inputFilterCustomer != null && this.customersListAux != null) {
      this.customersList = this.customersListAux.filter(p =>  
        (p.name && p.name.toLowerCase().includes(this.inputFilterCustomer.toLowerCase())));
    }
  }

  selectProduct(product: ProductsTable) {
    if(this.isProductPromo) {
      this.formPromotions.patchValue({
        productCodePromId: product.id,
        productCodeProm: product.code,
      });
    }
    else {
      this.formPromotions.patchValue({
        productId: product.id,
        productCode: product.code,
      });
    }
    this.inputFilter = '';
    this.filterProduct();
    (document.getElementById('btn-close') as HTMLInputElement).click();
  }

  selectCustomer(product: customersList) {
    this.formPromotions.patchValue({
      clientId: product.id,
      clientName: product.name,
    });
    this.inputFilterCustomer = '';
    this.filterCustomer();
    (document.getElementById('btn-close-customer') as HTMLInputElement).click();
  }


  savePromotion() {
    this.formPromotions.patchValue({
      nameUser: this.commonSrv.userName, 
      branchId: this.commonSrv.branchId,
    })

    if(this.formPromotions.get('cardNumber')?.value != null)
      this.formPromotions.patchValue({
        cardNumber: (this.formPromotions.get('cardNumber')?.value).toString()
      })

    this.formPromotions.markAllAsTouched();
    if(this.formPromotions.valid) {
      this.promotionsSrv.addOrUpdatePromotions(this.formPromotions.value) 
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            this.showToastOk('Promoción agregada exitosamente.');
            this.listRuler = this.listRulerAux;
            this.form();
          }
          else if(value.data == 'null product') {
            this.messageService.add({
              summary: 'Error',
              detail: 'El código del producto ingresado no se encuentra registrado, favor de verificar.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(value.data == 'null product free') {
            this.messageService.add({
              summary: 'Error',
              detail: 'El código del producto promoción ingresado no se encuentra registrado, favor de verificar.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(value.data == 'null customer') {
            this.messageService.add({
              summary: 'Error',
              detail: 'El nombre del cliente ingresado no se encuentra registrado, favor de verificar.',
              styleClass: 'danger-background-popover',
            });
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al crear la promoción, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error:(err) => {
          
        },
      })
    }
    else {
      this.messageService.add({
        summary: 'Error',
        detail: 'Formulario incompleto, favor de verificar.',
        styleClass: 'danger-background-popover',
      });
    }
    
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
  }

}
