import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResumeAuditService {

  constructor(private http: HttpClient) { }
  getResumeAudit() {
    return this.http.get<any>(`${environment.url}AuditResume`);
  }
  getLastAudit() {
    return this.http.get<any>(`${environment.url}AuditResume/getLastAudit`);
  }
}
