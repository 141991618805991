
  <p class="title">Cuentas por Cobrar</p>
  <p class="value">${{ receivables0to7Days + receivables8to30Days + receivablesOver30Days + receivablesOverdue | number:'1.0-0' }}</p>
  <div class="card-row" style="margin-top: 2rem;">
    <div class="flex-row"><i class="feather icon-clock circular-outline"></i> Vencidos</div>
    <div class="value">${{ receivablesOverdue | number:'1.0-0' }}</div>
  </div>
  <div class="card-row">
    <div class="flex-row"><i class="feather icon-clock circular-outline-normal"></i> A cobrar en 0 a 7 días</div>
    <div class="value">${{ receivables0to7Days | number:'1.0-0' }}</div>
  </div>
  <div class="card-row">
    <div class="flex-row"><i class="feather icon-clock circular-outline-normal"></i> A cobrar en 8 a 30 días</div>
    <div class="value">${{ receivables8to30Days | number:'1.0-0' }}</div>
  </div>
  <div class="card-row">
    <div class="flex-row"><i class="feather icon-clock circular-outline-normal"></i> A cobrar en más de 30 días</div>
    <div class="value">${{ receivablesOver30Days | number:'1.0-0' }}</div>
  </div>
