import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cartilla',
  templateUrl: './cartilla.component.html',
  styleUrl: './cartilla.component.css'
})
export class CartillaComponent implements OnInit {
  pdfSrc: string = '/assets/images/Cartilla.pdf';

  constructor() {}

  ngOnInit(): void {
    // Ya no necesitamos un servicio ni suscripción.
  }

}
