import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(
    private http: HttpClient
  ) { }

  getPromotions(typePromotionId: number,dateStart: any, dateEnd: any, status: number) {
    return this.http.get<any>(`${environment.url}promotions/getPromotions?typePromotionId=${typePromotionId}&dateStart=${dateStart}&dateEnd=${dateEnd}&status=${status}`);
  }

  getPromotion(idPromotion: number, typePromotion: string) {
    return this.http.get<any>(`${environment.url}promotions/getPromotion?idPromotion=${idPromotion}&typePromotion=${typePromotion}`);
  }

  getTypesPromotions() {
    return this.http.get<any>(`${environment.url}promotions/getTypesPromotions`);
  }

  getRuler() {
    return this.http.get<any>(`${environment.url}promotions/getRuler`);
  }

  addOrUpdatePromotions(body: FormGroup) {
    return this.http.post<any>(`${environment.url}promotions/addOrUpdatePromotions`, body);
  }
}
