startLine: 12;
endLine: 96;
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoriesService } from '../_services/categories/categories.service';
import { Category } from '../_models/category';
import { MessageService } from 'primeng/api';
import { BranchesList } from '../_models/branches/branchesList';

@Component({
  selector: 'app-category-manager',
  templateUrl: './category-manager.component.html',
  styleUrls: ['./category-manager.component.css'],
})
export class CategoryManagerComponent implements OnInit {
  categories: Category[] = [];
  first = 0;
  rows = 10;
  listBranch: BranchesList[] = [];
  categoryForm!: FormGroup;
  selectedCategory: number | null = null;
  selectedCategoryFlag: boolean = false;
  @ViewChild('closebutton') closebutton: any;

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoriesService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadCategories();
    this.categoryForm = this.fb.group({
      id: [],
      categoryName: ['', Validators.required],
      status: [1, Validators.required],
    });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  ngAfterViewInit() {
    (
      document.getElementById('add-edit-modal') as HTMLElement
    )?.addEventListener('hidden.bs.modal', () => {
      this.categoryForm.reset();
      this.categoryForm.patchValue({ status: 1, categoryName: '', id: null });
    });
  }

  loadCategories(): void {
    const companyId = parseInt(localStorage.getItem('idCompany') || '0', 10);
    this.categoryService.getCategories(companyId).subscribe({
      next: (result: any) => {
        if (result.response == 'Ok') {
          this.categories = result.data;
        } else {
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al cargar los datos, verifique.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error: (error) =>
        this.messageService.add({
          summary: 'Error',
          detail:
            'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        }),
    });
    console.log('categories');
    console.log(this.categories);
  }

  resetForm(): void {
    this.selectedCategory = null;
    this.categoryForm.reset();
    this.categoryForm.patchValue({ status: 1, categoryName: '', id: null });
    this.closebutton.nativeElement.click();
  }

  closeAddOrEditForm() {
    this.categoryForm.reset();
    this.categoryForm.patchValue({ status: 1, categoryName: '', id: null });
    this.closebutton.nativeElement.click();
  }

  addOrUpdateCategory(id: number) {
    this.categoryForm.markAllAsTouched();
    console.log(this.categoryForm.value);
    if (this.categoryForm.valid) {
      console.log('Form is valid');
      if (this.selectedCategory) {
        console.log('Selected category');
        this.categoryForm.patchValue({
          id: this.selectedCategory,
        });
      } else {
        console.log('Selected category is null');
        this.categoryForm.patchValue({
          id: null,
        });
      }
      this.categoryService
        .addOrEditCategory(this.categoryForm.value)
        .subscribe({
          next: (data) => {
            console.log('Data: ', data);
            if (data.data == 'Ok') {
              this.closebutton.nativeElement.click();
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Caja agregada/actualizada exitosamente',
                styleClass: 'success-background-popover',
              });
              this.categoryForm.reset();
              this.selectedCategoryFlag = false;
              this.loadCategories();
            } else {
              this.messageService.add({
                summary: 'Error',
                detail:
                  'Ocurrio un error al guardar la caja, contacte con el administrador del sistema.',
                styleClass: 'danger-background-popover',
              });
            }
          },
          error: (error) =>
            this.messageService.add({
              summary: 'Error',
              detail:
                'Ocurrio un error al guardar la caja, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            }),
        });
    } else {
      console.log('Form is not valid');
    }
  }

  getCategory(id: number) {
    const selectedCategory = this.categories.find(
      (category) => category.id === id
    );

    console.log('selectedCategory');
    console.log(selectedCategory);
    if (selectedCategory) {
      this.categoryForm.patchValue({
        id: id,
        categoryName: selectedCategory.categoryName,
        status: selectedCategory.status === 'Activo' ? 1 : 0,
      });
      this.selectedCategoryFlag = true;
      this.selectedCategory = id;
      document.getElementById('addeditmodal')?.click();
    }
  }
}
