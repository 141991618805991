export interface ItemsForSale {
    id?: number;
    quantity?: number;
    name?: String;
    imgUrl?: String;
    price?: number;
    readonly total?: number
}
export class ItemForSaleP implements ItemsForSale {

    id?: number;
    code?: String;
    quantity?: number;
    name?: String;
    imgUrl?: String;
    price?: number;
    pricePrev?: number = 0;
    discountPrice?: number;
    maxQuantity? : number;
    QuantityToPromo?: number = 0;
    promoPrice? : number = 0;
    freeQuantity?: number = 0;
    promoApliedId?: number = 0;
    isFreeItem?: boolean = false;
    parentItemIdFromFree? : number = 0;
    isUnitDiscountApplied : Boolean = false;
    isUnitPercentDeleted : Boolean = true;

    get total(): number { 
        if(this.quantity != null && this.price != null && this.QuantityToPromo == 0 && this.freeQuantity == 0){
            return (this.quantity)*this.price;
        }
        // else if(this.quantity != null && this.price != null && this.QuantityToPromo != 0 && this.freeQuantity != 0){
        //     let freeItemsQuantityToApply : number = Math.floor(this.quantity / this.QuantityToPromo!);
        //     let quantityToRest : number = freeItemsQuantityToApply * this.freeQuantity!;
        //     let priceResult : number = (this.quantity - quantityToRest)*this.price;
        //     if(priceResult < 0){
        //         priceResult = 0;
        //     }
        //     return priceResult;
        // }
        else if(this.promoPrice != 0 && this.quantity! >= this.QuantityToPromo!){
            return (this.quantity!) * this.promoPrice!;
        }
        else {
            return this.price ?? 0;
        }
    }
}