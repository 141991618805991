<div class="mb-4">
  <div class="d-flex my-4 align-items-center">
    <button [routerLink]="['/transfers']" type="button" class="btn btn-light"><i class="bi bi-arrow-left fw-bold"></i></button>
    <h2 class="fw-bold m-0 p-0 ps-2">{{title}} Transferencia</h2>
  </div>

  <form [formGroup]="formTransfer" (ngSubmit)="createTransfer()" class="mb-5">
    <div class="card p-3 mb-4">
      <h2 class="fw-bold text-center text-sm-start">Datos de Transferencia</h2>
        <div class="row col-12 m-0 p-0">
          <div class="col-sm-3 mb-3">
            <p class="m-0">Tipo de transferencia</p>
            <mat-select class="select space-select" formControlName="typeTransfer" (selectionChange)="filterDestinations() ">
              @for (type of listTypeTranfers; track $index) {
                <mat-option [value]="type.idTipoTransferencia">
                  {{type.cTipoTransferencia}}
                </mat-option>
              }
             
            </mat-select>
          </div>
          <div class="col-sm-3 mb-3">
            <p class="m-0">Origen</p>
            <mat-select class="select space-select" formControlName="origin" >
              @for (item of listBranches; track $index) {
                <mat-option [value]="item.idSucursal">
                  {{item.branchName}}
                </mat-option>
              }
            </mat-select>
          </div>
          <div class="col-sm-3 mb-3">
            <p class="m-0">Destino</p>
            <mat-select class="select space-select" formControlName="destination">
              @for (item of listBranchesDes; track $index) {
                <mat-option [value]="item.idSucursal"
                >
                  {{item.branchName}}
                </mat-option>
              }
            </mat-select>
          </div>
          <div class="col-sm-3 mb-3">
            <p class="m-0">Valor de transferencia</p>
            <p-inputGroup>
              <p-inputGroupAddon>Q</p-inputGroupAddon>
              <input type="number" class="inputGroup" formControlName="transferValue" placeholder="0.00" />
            </p-inputGroup>
          </div>
        </div>
    </div>
  
    <div class="card p-3 md-4">
      <h2 class="fw-bold text-center text-md-start mb-3">Detalle de Transferencia</h2>
      <div class="table-responsive">
        <p-table 
        [value]="listProdTransfer"
        class="table datanew" 
        dataKey="code" 
        [tableStyle]="{'min-width': '50rem'}"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th *ngIf="formTransfer.get('typeTransfer')?.value == 3">Producto</th>
              <th *ngIf="formTransfer.get('typeTransfer')?.value == 3">Cantidad</th>
              <th *ngIf="title == 'Autorizar'">Cantidad Recibida</th>
              <th *ngIf="title != 'Autorizar'"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>

            @if (formTransfer.get('typeTransfer')?.value != 3) {
              <tr>
                <td>{{ product.nameProduct }} </td>
                <td> 
                  <input *ngIf="title != 'Autorizar'" [id]="'quantity' + product.idOriginProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeQuantityProduc(product.idOriginProduct)"  [value]="0" /> 
                  <input *ngIf="title == 'Autorizar'" [id]="'quantity' + product.idDestinationProduct" appDecimalRestriction appNotNegative class="form-control" type="number"  [value]="product.destinationQuantity" disabled  /> 
                </td>
                <td *ngIf="title == 'Autorizar'"> 
                  <input [id]="'receivedQuantity' + product.idDestinationProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeReceivedQuantity(product.idDestinationProduct)"  [value]="product.receivedAmount" /> 
                </td>
  
                <td *ngIf="title != 'Autorizar'">
                  <a class="btn btn-info" (click)="deleteProductList(product.idOriginProduct)"><i class="feather icon-delete"></i></a>
                </td>
              </tr>
            }
            @else {
              <tr>
                <td>{{ product.nameProduct }} </td>
                <td> 
                  <input *ngIf="title != 'Autorizar'" [id]="'quantityOrigin' + product.idOriginProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeQuantityOriginDesc(product.idOriginProduct,'quantityOrigin')"  [value]="0" /> 
                  <input *ngIf="title == 'Autorizar'" [id]="'quantityOrigin' + product.idOriginProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeQuantityOriginDesc(product.idOriginProduct,'quantityOrigin')"  [value]="product.originQuantity" disabled /> 
                </td>


                <td *ngIf="title != 'Autorizar'" class="d-flex justify-content-between ">
                  <p [id]="'productDes'+product.idDestinationProduct"></p>
                  <p class="btn btn-info" data-bs-toggle="modal" data-bs-target="#productsDes" (click)="idProductDes = product.idOriginProduct"><i class="bi bi-search"></i></p>
                </td>

                <td *ngIf="title == 'Autorizar'">{{ product.nameProductDestination }} </td>

                <td> 
                  <input *ngIf="title != 'Autorizar'" [id]="'quantityDestination' + product.idDestinationProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeQuantityOriginDesc(product.idDestinationProduct,'quantityDestination')"  [value]="0" /> 
                  <input *ngIf="title == 'Autorizar'" [id]="'quantityDestination' + product.idDestinationProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeQuantityOriginDesc(product.idDestinationProduct,'quantityDestination')"  [value]="product.destinationQuantity" disabled /> 
                </td>
  
                <td *ngIf="title != 'Autorizar'">
                  <a class="btn btn-info" (click)="deleteProductList(product.idOriginProduct)"><i class="feather icon-delete"></i></a>
                </td>

                <td *ngIf="title == 'Autorizar'"> 
                  <input [id]="'receivedQuantity' + product.idDestinationProduct" appDecimalRestriction appNotNegative class="form-control" type="number" (change)="changeReceivedQuantityBranch(product.idDestinationProduct)"  [value]="0" /> 
                </td>
              </tr>
            }
          </ng-template>
        </p-table>
  
        <hr class="mt-3" style="color: #ebebeb;">
        <h3 *ngIf="title != 'Autorizar'" class="mt-2" style="color: #2BA8D9; font-weight: 500;cursor: pointer;" data-bs-toggle="modal" data-bs-target="#products"><i class="bi bi-plus-lg me-2"></i> Agregar Producto</h3>
    
        <!-- MODAL -->
        <div class="modal fade" id="products" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="productsLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="productsLabel">Productos</h2>
                <button type="button" id="btn-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
  
                <div class="form-group row">
                  <div class="col-12 col-md-8">
                    <input type="text" class="form-control" id="searchTerm" placeholder="Filtrar por nombre, categoría, SKU o Código de barras.">
                  </div>
                  <button type="button" class="btn btn-info col-12 col-md-4" (click)="getProducts()">Filtrar productos</button>
                </div>
                <hr>
                <p-table 
                [value]="listProducts" 
                class="table datanew" 
                dataKey="code" 
                [rows]="rows"
                [paginator]="true"
                [first]="first"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} productos"
                (onPage)="pageChange($event)"
                [rowsPerPageOptions]="[10, 25, 50]"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Nombre Producto</th>
                      <th>Stok</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr style="cursor: pointer;" (click)="selecProduct(product)">
                      <td >{{ product.name }} </td>
                      <td>{{ product.inventory }} </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>

         <!-- MODAL -->
         <div class="modal fade" id="productsDes" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="productsDesLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="productsDesLabel">Productos</h2>
                <button type="button" id="btn-closeDes" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
  
                <div class="form-group row">
                  <div class="col-12 col-md-8">
                    <input type="text" class="form-control" id="searchTerm" placeholder="Filtrar por nombre, categoría, SKU o Código de barras.">
                  </div>
                  <button type="button" class="btn btn-info col-12 col-md-4" (click)="getProducts()">Filtrar productos</button>
                </div>
                <hr>
                <p-table 
                [value]="listProducts" 
                class="table datanew" 
                dataKey="code" 
                [rows]="rows"
                [paginator]="true"
                [first]="first"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="{first} al {last} de {totalRecords} productos"
                (onPage)="pageChange($event)"
                [rowsPerPageOptions]="[10, 25, 50]"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Nombre Producto</th>
                      <th>Stok</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr style="cursor: pointer;" (click)="selectProductDes(product)">
                      <td >{{ product.name }} </td>
                      <td>{{ product.inventory }} </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="title == 'Autorizar'" class="card p-3 md-5">
      <div class="col-sm-3 mb-3">
        <h2 class="fw-bold text-center text-md-start mb-3">Respuesta</h2>
        <mat-select class="select space-select" formControlName="authorizationStatus">
          <mat-option [value]="1">
            Pendiente
          </mat-option>
          <mat-option [value]="2">
            Aprobada
          </mat-option>
          <mat-option [value]="3">
            Rechazada
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="card p-3 md-5">
      <h2 class="fw-bold text-center text-md-start mb-3">Comentarios</h2>
      <textarea rows="5" cols="30" pInputTextarea formControlName="commentsCreate" placeholder="Agrega un comentario relacionado a la tranferencia que se realizará." maxlength="500"></textarea>
    </div>

    <div *ngIf="title == 'Autorizar'" class="card p-3 md-5">
      <h2 class="fw-bold text-center text-md-start mb-3">Comentarios Autorizar Transferencia</h2>
      <textarea rows="5" cols="30" pInputTextarea formControlName="commentsUpdate" placeholder="Agrega un comentario relacionado a la tranferencia que se recibida." maxlength="500"></textarea>
    </div>

    <div class="d-flex justify-content-end p-5">
      <div class="page-btn me-3" >
        <a [routerLink]="['/transfers']" class="btn btn-light">
          Cancelar
        </a>
      </div>
      <div *ngIf="isNewTrasfer" class="page-btn">
        <button type="submit" class="btn btn-info">
        @if (title == 'Autorizar') {Generar Respuesta }
        @else {Generar Transferencia}
        </button>
      </div>
    </div>
  </form>
</div>


<p-toast>
</p-toast>
