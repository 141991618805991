import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InventoryAuditService {

  constructor(private http: HttpClient) { }
  getItems(searchTerm: String) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    let  body = {
      searchTerm: searchTerm
    };
    return this.http.post<any>(`${environment.url}InventoryAudit/getItemsNosearchIndicator`,body,{headers: header});
  }
  addItemsToAudit(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}InventoryAudit/addItemsToAudit`,body,{headers: header});
  }
  getAuditItems() {
    return this.http.get<any>(`${environment.url}InventoryAudit`);
  }
  cancelAudit() {
    return this.http.get<any>(`${environment.url}InventoryAudit/cancelAudit`);
  }
  finishAudit() {
    return this.http.get<any>(`${environment.url}InventoryAudit/finishAudit`);
  }
}
