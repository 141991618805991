<div class="page-header">
    <div class="add-item d-flex">
        <div class="page-title">
            <h4>Cartilla</h4>
        </div>
    </div>    
</div>

<div *ngIf="pdfSrc">
    <pdf-viewer [src]="pdfSrc" [render-text]="true" style="display: block; width: 100%; height: 650px ;"></pdf-viewer>
  </div>
  <div *ngIf="!pdfSrc">
    <p>Cargando el PDF...</p>
  </div>