<h1>¡BIENVENIDO!</h1>

<div *ngIf="!allbranches">
    <h4>Graficas y KPI's</h4>
    <div *ngIf="loading" class="loader">Loading...</div>
    <div *ngIf="!loading">
      <div class="charts-container">
        <div class="card">
          <h4>Ventas</h4>
          <p style="font-size: 20px; font-weight: bold;">${{ sales | number:'1.0-0' }}</p>
          <app-line-chart [data]="lineChartData"></app-line-chart>
        </div>
        <div class="card">
          <h4>Ventas por Metodo de Pago</h4>
          <app-bar-chart [data]="barChartData"></app-bar-chart>
        </div>
      </div>
      <div class="charts-container">
        <app-home-card class="card" style="width: 484px; height: 480px;" [receivables0to7Days]="receivables0to7Days" [receivables8to30Days]="receivables8to30Days" [receivablesOver30Days]="receivablesOver30Days" [receivablesOverdue]="receivablesOverdue"></app-home-card>
        <div class="card">
          <h4>Ventas por categorias</h4>
          <app-pie-chart [data]="pieChartData"></app-pie-chart>
        </div>
      </div>
      <div class="charts-container">
        <div class="card" style="width: 800px; ">
          <h4>Ventas objetivo</h4>
          <app-double-line-chart [data]="doubleLineChartData"></app-double-line-chart>
        </div>
      </div>
    </div>
</div>

<div *ngIf="allbranches">
  <h4>Graficas y KPI's Administrativas</h4>
  <div *ngIf="loading" class="loader">Loading...</div>
  <div *ngIf="!loading">
    <div class="charts-container">
      <div class="cardadmin">
        <h4>Ventas de todas las Sucursales</h4>
        <p style="font-size: 20px; font-weight: bold;">${{ sales | number:'1.0-0' }}</p>
        <app-line-chart [data]="lineChartData"></app-line-chart>
      </div>      
    </div>  

    <div class="charts-container">      
      <div class="cardadmin">
        <h4>Ventas por Sucursal</h4>
        <app-bar-chart [data]="barChartData"></app-bar-chart>
      </div>
    </div>        
  </div>
</div>


