import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BranchesService } from '../_services/branches/branches.service';
import { CompaniesService } from '../_services/companies/companies.service';
import { companiesList } from '../_models/companiesList';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-branch-offices-form',
  templateUrl: './branch-offices-form.component.html',
  styleUrls: ['./branch-offices-form.component.css']
})
export class BranchOfficesFormComponent implements OnInit {

  branchForm!: FormGroup;
  title = "Nueva";
  companiesList : companiesList[] = [];

  constructor(
    private fb: FormBuilder,
    private branchesService: BranchesService,
    private messageService: MessageService,
    private router: Router,
    private companiesService: CompaniesService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  @Input() 
  set id(idBranch:number) {
    if(idBranch == undefined) {
      this.title = "Nueva";
    }
    else {
      this.title = "Editar";
      this.getBranchById(idBranch);
    }
  }
  

  initializeForm() {
    this.branchForm = this.fb.group({
      id: [null],
      branchName: ['',Validators.required],
      branchCode: ['',Validators.required],
      branchNIT: ['',Validators.required],
      idCompany: ['',Validators.required],
      adminName: ['',Validators.required],
      branchAddress: ['',Validators.required],
      branchCP: ['',Validators.required],
      branchCountry: ['',Validators.required],
      branchEmail: ['',Validators.required],
      branchPhone: ['',Validators.required],
    });
    this.getCompaniesList();
  }

  createOrUpdateBranch() {
    this.branchForm.markAllAsTouched();
    if(this.branchForm.valid) {
      this.branchesService.createBranch(this.branchForm.value)
      .subscribe({
        next:(value) => {
          if(value.data == 'Ok') {
            if(this.title == 'Nueva') {
              this.showToastOk('Sucursal agregada exitosamente');
              this.branchForm.reset();
            }
            else {
              this.router.navigate(['/branch-office']);
              this.showToastOk('Sucursal editada exitosamente');
            }
          }
          else if(value.data == "error-nit"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Ya existe una Sucursal con el NIT indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(value.data == "error-name"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Ya existe una Sucursal con el Nombre indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else if(value.data == "error-code"){
            this.messageService.add({
              summary: 'Error',
              detail: 'Error ya existe una Sucursal con el Código indicado.',
              styleClass: 'danger-background-popover',
            });
          }
          else {
            console.warn("Error ", value);
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al guardar la sucursal, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
          
        },
        error:(err) => {
          console.error("Error ", err);
          this.messageService.add({
            summary: 'Error',
            detail: 'Ocurrio un error al guardar la sucursal, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        },
      });
    }
    else {

    }
  }

  getBranchById(id: number) {
    this.branchesService.getBranch(id)
    .subscribe({
      next:(value) => {
        if(value.response == 'Ok') {
          this.branchForm.patchValue(value.data);
        }
        else {
          console.warn("Error ", value);
          this.messageService.add({
            summary: 'Error',
            detail: 'No fue posible obtener la información de la sucursal, contacte con el administrador del sistema.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error:(err) => {
        console.error("Error ", err);
        this.messageService.add({
          summary: 'Error',
          detail: 'No fue posible obtener la información de la sucursal, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        });
      },
    })
  }

  getCompaniesList() {    
    this.companiesService.getCompanies('', '')
      .subscribe(data => {
        if (data.response == 'Ok') {
          this.companiesList = data.data;
        }
        else {

        }
      });
  }

  showToastOk(messageContent : string){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: messageContent, styleClass: 'success-background-popover' });
    
  }

}
