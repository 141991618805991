import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { LineChartComponent } from '../line-chart/line-chart.component';
import { BarChartComponent } from '../bar-chart/bar-chart.component';
import { PieChartComponent } from '../pie-chart/pie-chart.component';
import { DoubleLineChartComponent } from '../double-line-chart/double-line-chart.component';
import { HomeCardComponent } from '../home-card/home-card.component';

@NgModule({
  imports: [CommonModule, HomeCardComponent],
  declarations: [
    HomeComponent,
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
    DoubleLineChartComponent,
  ],
})
export class HomeModule {}
