import { Component, OnInit, Input } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  template: '<canvas id="lineChart" width="900" height="800"></canvas>',
})
export class LineChartComponent implements OnInit {
  @Input() data: any;
  ngOnInit() {
    console.log('Passed data:', this.data); // Log the passed data
    Chart.register(...registerables);
    const ctx = document.getElementById('lineChart') as HTMLCanvasElement;
    if (ctx) {
      new Chart(ctx, {
        type: 'line',
        data: {
          // format the dates
          labels: this.data.labels.map((label: string) =>
            new Date(label).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            })
          ),
          datasets: [
            {
              label: 'Sales',
              backgroundColor: 'rgba(75,192,192,0.1)',
              borderColor: 'rgba(75,192,192,1)',
              data: this.data.datasets[0].data,
              fill: true,
              tension: 0.5,
              // decrease fill opacity
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    } else {
      console.error('Canvas element not found');
    }
  }
}
