import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VariantService } from '../_services/productVariant/variant.service';
import { ProductVariant } from '../_models/product-variant';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrls: ['./product-variant.component.css'],
})
export class ProductVariantComponent implements OnInit {
  variants: ProductVariant[] = [];
  first = 0;
  rows = 10;
  variantForm!: FormGroup;
  selectedVariant: number | null = null;
  selectedVariantFlag: boolean = false;
  @ViewChild('closebutton') closebutton: any;

  constructor(
    private fb: FormBuilder,
    private variantService: VariantService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadVariants();
    this.variantForm = this.fb.group({
      id: [],
      cOpcionVariante: ['', Validators.required],
      cValores: ['', Validators.required],
    });
  }

  pageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
  }

  ngAfterViewInit() {
    (
      document.getElementById('add-edit-modal') as HTMLElement
    )?.addEventListener('hidden.bs.modal', () => {
      this.variantForm.reset();
      this.variantForm.patchValue({
        cOpcionVariante: '',
        cValores: '',
        idVariante: null,
      });
    });
  }

  loadVariants(): void {
    const companyId = parseInt(localStorage.getItem('idCompany') || '0', 10);
    this.variantService.getVariants(companyId).subscribe({
      next: (result: any) => {
        if (result.length) {
          this.variants = result;
          console.log('variants');
          console.log(this.variants);
        } else {
          this.messageService.add({
            summary: 'Error',
            detail: 'Error loading data, please check.',
            styleClass: 'danger-background-popover',
          });
        }
      },
      error: (error) =>
        this.messageService.add({
          summary: 'Error',
          detail: 'An error occurred, please contact the system administrator.',
          styleClass: 'danger-background-popover',
        }),
    });
  }

  resetForm(): void {
    this.selectedVariant = null;
    this.variantForm.reset();
    this.variantForm.patchValue({
      cOpcionVariante: '',
      cValores: '',
      idVariante: null,
    });
    this.closebutton.nativeElement.click();
  }

  closeAddOrEditForm() {
    this.variantForm.reset();
    this.variantForm.patchValue({
      cOpcionVariante: '',
      cValores: '',
      idVariante: null,
    });
    this.closebutton.nativeElement.click();
  }

  addOrUpdateVariant(id: number) {
    this.variantForm.markAllAsTouched();
    if (this.variantForm.valid) {
      console.log('form valid');
      if (this.selectedVariant) {
        console.log('selected variant');
        this.variantForm.patchValue({
          id: this.selectedVariant,
        });
      } else {
        console.log('selected variant2');
        this.variantForm.patchValue({
          id: null,
        });
      }
      this.variantService.addOrEditVariant(this.variantForm.value).subscribe({
        next: (data) => {
          if (data.response == 'Ok') {
            console.log('data ok');
            this.closebutton.nativeElement.click();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Variant added/updated successfully',
              styleClass: 'success-background-popover',
            });
            this.variantForm.reset();
            this.selectedVariantFlag = false;
            this.loadVariants();
          } else {
            console.log('data not ok');
            this.messageService.add({
              summary: 'Error',
              detail:
                'Error saving variant, please contact the system administrator.',
              styleClass: 'danger-background-popover',
            });
          }
        },
        error: (error) =>
          this.messageService.add({
            summary: 'Error',
            detail:
              'Error saving variant, please contact the system administrator.',
            styleClass: 'danger-background-popover',
          }),
      });
    }
  }

  getVariant(idVariante: number) {
    const selectedVariant = this.variants.find(
      (variant) => variant.idVariante === idVariante
    );

    if (selectedVariant) {
      this.variantForm.patchValue({
        idVariante: idVariante,
        cOpcionVariante: selectedVariant.cOpcionVariante,
        cValores: selectedVariant.cValores,
      });
      this.selectedVariantFlag = true;
      this.selectedVariant = idVariante;
      document.getElementById('addeditmodal')?.click();
    }
  }
}
