import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }
  getCustomers(searchTerm: String, shortTerm: String) {
    return this.http.get<any>(`${environment.url}customers?searchTerm=`+ searchTerm + '&shortTerm=' +shortTerm);
  }
  getCustomer(id : number) {
    return this.http.get<any>(`${environment.url}customers/`+ id);
  }
  addOrEditCustomer(body:any) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}customers`,body,{headers: header});
  }
}
