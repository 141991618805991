import { Component, OnInit, ViewChild } from '@angular/core';
import { dataCompany } from '../_models/dataCompany';
import { itemsAuditList } from '../_models/inventoryAudit/itemsAuditList';
import { itemsCodeList } from '../_models/inventoryAudit/itemsCodeList';
import { InventoryAuditService } from '../_services/inventoryAudit/inventory-audit.service';
import { MessageService } from 'primeng/api';
import { Router}  from '@angular/router';
@Component({
  selector: 'app-inventory-audit',
  templateUrl: './inventory-audit.component.html',
  styleUrl: './inventory-audit.component.css'
})
export class InventoryAuditComponent implements OnInit {
  actionsOptions: dataCompany[] = [
    { value: 'Cancelar Auditoria' },
    { value: 'Resumen de Auditoria' }
  ];
  @ViewChild('closebutton') closebutton: any;
  public selectedAction = '';
  public tableData: Array<itemsAuditList> = [];
  public itemsResultSearch: Array<itemsCodeList> = [];
  public selectedItem!: itemsAuditList;
  @ViewChild('closebuttonConfirmClosingCash') closebuttonConfirmClosingCash: any;
  showSearchIndicator: boolean = true;
  showResultItems: boolean = false;
  ngOnInit(): void {
    this.getAuditItems();
  }
  constructor(private salesService: InventoryAuditService, private messageService: MessageService, private _router: Router) { }
  showSearchItemIndicator(event: Event) {
    const target = event.target as HTMLInputElement;
    this.showSearchIndicator = false;
    this.getItems(target.value)
  }
  getItems(searchTerm: string) {
    this.salesService.getItems(searchTerm)
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.itemsResultSearch = [];
            this.itemsResultSearch = result.data;
            this.showSearchIndicator = true;
            this.showResultItems = false;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al realizar la busqueda, verifique.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  closeManualModal() { }
  addItem() { }
  hideSearchResult() { }
  addItemToAudit(item: itemsCodeList) {
    let itemToAdd = new itemsAuditList();
    itemToAdd.id = item.id;
    itemToAdd.itemCode = item.code;
    itemToAdd.itemName = item.name;
    itemToAdd.quantity = 0;
    itemToAdd.comments = '';
    this.tableData.push(itemToAdd);
    this.itemsResultSearch = [];
    this.closebutton.nativeElement.click();
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Producto Añadido con exito.', styleClass: 'success-background-popover' });
  }
  deleteItem(id?: number) {
    this.tableData.forEach((item, index) => {
      if (item.id === id) this.tableData.splice(index, 1);
    });
  }
  addItemsToAudit() {
    this.salesService.addItemsToAudit(this.tableData)
      .subscribe({
        next: data => {
          if (data.data == "Ok") {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auditoria guardada exitosamente', styleClass: 'success-background-popover' });
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al guardar los datos, contacte con el administrador del sistema.',
              styleClass: 'danger-background-popover',
            });
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio un error interno, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getAuditItems() {
    this.salesService.getAuditItems()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.tableData = result.data;
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cargar los datos, intente nuevamente.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
  getAction(){
    if(this.selectedAction == 'Resumen de Auditoria'){
      this._router.navigate(['audit-resume'])
    }
    else {
      document.getElementById('btn-cofirm-cash-closing')?.click();
    }
  }
  closeManualModalConfirmCashClosing() {
    this.closebuttonConfirmClosingCash.nativeElement.click();
  }
  cancelAudit(){
    this.salesService.cancelAudit()
      .subscribe({
        next: result => {
          if (result.response == "Ok") {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Auditoria cancelada exitosamente', styleClass: 'success-background-popover' });
            this.closeManualModalConfirmCashClosing();
            this.getAuditItems();
          }
          else {
            this.messageService.add({
              summary: 'Error',
              detail: 'Ocurrio un error al cancelar la auditoria, intente nuevamente.',
              styleClass: 'danger-background-popover',
            })
          }
        }
        ,
        error: error => this.messageService.add({
          summary: 'Error',
          detail: 'Ocurrio durante el proceso, contacte con el administrador del sistema.',
          styleClass: 'danger-background-popover',
        })
      });
  }
}
