import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BranchesService {
  constructor(private http: HttpClient) {}

  getBranches() {
    return this.http.get<any>(`${environment.url}branches`);
  }

  getBranch(id: number) {
    return this.http.get<any>(`${environment.url}branches/${id}`);
  }

  createBranch(data: FormGroup) {
    let header = new HttpHeaders({ 'content-type': 'application/json' });
    return this.http.post<any>(`${environment.url}branches`, data, {
      headers: header,
    });
  }

  getBranchesByCompany(companyId: number | String): Observable<any> {
    return this.http.get(`/api/branches?companyId=${companyId}`);
  }
}
