<div class="card px-5 py-3 mb-5">
  <h2 class="fw-bold">Carga masiva de productos</h2>
    <div class="row col-12 mb-3">
      <div class="col-12 col-md-6">
        <div class="form-group px-3 mb-2 col-12">
          <label for="file">Nombre del documento</label>
          <input type="text" class="form-control w-100" id="file" aria-describedby="emailHelp" [(ngModel)]="fileName" readonly>
        </div>
        <button type="button" class="btn btn w-100" (click)="openFileInputProducts()">Seleccionar archivo.</button>
      </div>
      <div class="col-12 col-md-3 mb-3">
        <button type="button" class="btn btn-info w-100 mt-3" (click)="addUsersInBulk()">Realizar carga  de productos.</button>
      </div>
      <div class="col-12 col-md-3 mb-3">
        <button type="button" class="btn btn-light w-100 mt-3" (click)="downloadLayout()">Descargar layout de carga masiva.</button>
      </div>
      <input id="fileInputProducts" type="file" accept=".xls,.xlsx" class="form-control d-none" (change)="selectFileProducts($any($event.target).files)">
    </div>
</div>
