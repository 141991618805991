import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { registerables } from 'chart.js';

@Component({
  selector: 'app-bar-chart',
  template: '<canvas id="barChart"  width="900" height="800"></canvas>',
})
export class BarChartComponent implements OnInit {
  @Input() data: any;
  ngOnInit() {
    Chart.register(...registerables);
    const ctx = document.getElementById('barChart') as HTMLCanvasElement;
    new Chart('barChart', {
      type: 'bar',
      data: {
        labels: this.data.labels,
        datasets: [
          {
            label: 'Sales',
            backgroundColor: ['#0F67B1'],
            data: this.data.datasets[0].data,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
}
