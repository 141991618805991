
<section>
  <div class="row col-12">
    <h2 class="col-12 col-sm-4 fw-bold mt-3 text-center text-md-start">Promociones</h2>

    <div class="col-12 col-sm-8">
      <div class="my-2 d-flex flex-column align-items-center  flex-sm-row  justify-content-sm-end  gap-sm-4">
        
        <div class="page-btn">
          <a class="btn btn-light text-center "><i class="feather icon-arrow-up-circle me-2"></i>Descargar reporte</a>
        </div>

        <div class="mt-3 mt-sm-0">
          <button type="button" [routerLink]="['/new-promotion']" class="btn btn btn-info"><i class="feather icon-plus feather-search"></i> Nueva promoción</button>
        </div>
      </div>

    </div>
  </div>
</section>
<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive" style="min-height: 500px;">
      <div class="table-top row col-12 m-0 p-0 mb-4">
          <div class="search-set col-12">
            <div class="row col-12">
              <div class="col-12 col-md-3 mb-4">
                <p class="m-0 p-0">Tipo promoción</p>
                <mat-select [(ngModel)]="typePromotion" (ngModelChange)="getPromotions()" [ngModelOptions]="{standalone: true}"
                class="select space-select">
                  <mat-option [value]="0">
                    Todas
                  </mat-option>
                  @for (item of listTypeProm; track $index) {
                    <mat-option [value]="item.id">
                      {{item.name}}
                    </mat-option>
                  }
                </mat-select>
              </div>

              <div class="col-12 col-md-3 mb-3">
                <p class="m-0">Fecha inicio de promoción</p>
                <p-calendar [(ngModel)]="dateStart" class="w-100"  (ngModelChange)="getPromotions()" [iconDisplay]="'input'" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
              </div>

              <div class="col-12 col-md-3 mb-3">
                <p class="m-0">Fecha fin de promoción</p>
                <p-calendar [(ngModel)]="dateEnd" [iconDisplay]="'input'" dateFormat="dd/mm/yy" (ngModelChange)="getPromotions()"  [showIcon]="true"></p-calendar>
              </div>

              <div class="col-12 col-md-3 mb-4">
                <p class="m-0 p-0">Estatus</p>
                <mat-select [(ngModel)]="status" (ngModelChange)="getPromotions()" [ngModelOptions]="{standalone: true}"
                class="select space-select">
                  <mat-option [value]="3">
                    Todas
                  </mat-option>
                  <mat-option [value]="1">
                    Activo
                  </mat-option>
                  <mat-option [value]="0">
                    Inactivo
                  </mat-option>
                 
                </mat-select>
              </div>

            </div>
          </div>
        </div>
        <!-- /Filter -->
      <p-table  
      [value]="promotionsList"
      class="table datanew" 
      dataKey="code" 
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords}"
     
      [rowsPerPageOptions]="[10, 25, 50]"
      
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Tipo promoción</th>
            
            @if (typePromotion != 4 && typePromotion != 5) {
              <th>Nombre promoción</th>
            }

            @if (typePromotion == 0 || typePromotion == 1 || typePromotion == 3) {
              <th>Código producto</th>
            }
            @if (typePromotion == 2) {
              <th>Id cliente</th>
            }
            @if (typePromotion == 0 || typePromotion == 3 || typePromotion == 2) {
              <th>Nombre cliente</th>
            }
            @if (typePromotion == 1) {
              <th>Nombre producto</th>
            }
            @if (typePromotion == 0 || typePromotion == 3 || typePromotion == 1 || typePromotion == 2) {
              <th>Vigencia</th>
            }
            @if (typePromotion == 1 || typePromotion == 2) {
              <th>Regla</th>
            }

            @if (typePromotion == 4) {
              <th>Código Cupón</th>
              <th>% Descuento</th>
              <th>Monto Descuento</th>
            }

            @if (typePromotion == 5) {
              <th>Número de tarjeta</th>
            }

            <th>Estatus</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-promotion>
          <tr>
            
            <td>{{ promotion.typePromotion }} </td>

            @if (typePromotion != 4 && typePromotion != 5) {
              <td>{{ promotion.promotionName }} </td>
            }

            @if (typePromotion == 0 || typePromotion == 1 || typePromotion == 3) {
              <td>{{ promotion.productCode }} </td>
            }
            @if (typePromotion == 2) {
              <td>{{ promotion.clientId }} </td>
            }
            @if (typePromotion == 0 || typePromotion == 3 || typePromotion == 2) {
              <td>{{ promotion.clientName }} </td>
            }
            @if (typePromotion == 1) {
              <td>{{ promotion.productName }} </td>
            }
            @if (typePromotion == 0 || typePromotion == 3 || typePromotion == 1 || typePromotion == 2) {
              <td>{{ promotion.dateStart | date: 'dd/MM/yyyy' }} - {{ promotion.dateEnd | date: 'dd/MM/yyyy' }} </td>
            }
            @if (typePromotion == 1 || typePromotion == 2) {
              <td>{{ promotion.rule }} </td>
            }

            @if (typePromotion == 4) {
              <td>{{ promotion.couponCode }} </td>
              <td>{{ promotion.percentage }} </td>
              <td>{{ promotion.amount }} </td>
            }

            @if (typePromotion == 5) {
              <td>{{ promotion.cardNumber }} </td>
            }

            <td>{{ promotion.status }} </td>
            <td><a class="btn btn-info" [routerLink]="['/update-promotion/'+promotion.promotionId+'-'+promotion.typePromotion]"><i class="bi bi-pencil-square"></i></a></td>
            <!-- @if (promotion.typePromotion != 'Cupones' && promotion.typePromotion != 'Gift Card' ) {
              
            } -->
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>

<p-toast>
</p-toast>

