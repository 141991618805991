
<section>
  <div class="row col-12 d-flex justify-content-between">
    <h2 class="col-12 col-md-4 fw-bold mt-3">Categorías</h2>

    <div class="col-12 col-md-4 text-end">
      <a class="btn btn-info" id="addeditmodal" data-bs-toggle="modal" #addeditmodal data-bs-target="#add-edit-modal">
        <i class="feather icon-plus-circle me-2"></i> Agregar Categoría
      </a>
    </div>
  </div>
</section>

<div class="card table-list-card">
  <div class="card-body">
    <div class="table-responsive">
      <p-table [value]="categories"
      class="table datanew"
      dataKey="code"
      [tableStyle]="{'min-width': '50rem'}"
      [paginator]="true"
      [rows]="10"
      [first]="first"
      [showCurrentPageReport]="true"
      [tableStyle]="{ 'min-width': '50rem' }"
      currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} categorías"
      (onPage)="pageChange($event)"
      [rowsPerPageOptions]="[10, 25, 50]"

      >
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Status</th>
            <th>Company Id</th>
            <th>User Id</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-category>
          <tr>
            <td>{{ category.id}} </td>
            <td>{{ category.categoryName }} </td>
            <td>{{ category.status }} </td>
            <td>{{ category.companyId }} </td>
            <td>{{ category.userId }} </td>
            <td>{{ category.createdAt }} </td>
            <td>{{ category.updatedAt }} </td>
            <td><a class="btn btn-info" (click)="getCategory(category.id)"><i class="feather icon-edit"></i></a></td>

          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- <app-custom-pagination></app-custom-pagination> -->
  </div>
</div>

<div class="modal fade" id="add-edit-modal" tabindex="-1" aria-labelledby="exampleModalFullscreenLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalFullscreenLabel">Crear Categoría</h4>
                <button #closebutton type="button" (click)="closeAddOrEditForm()" class="btn-close" data-bs-dismiss="modal" id="modal-close-button"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row h-100">
                    <div class="col-12">
                        <form class="pb-2" [formGroup]="categoryForm">
                            <div class="card mb-0">
                                <div class="card-body add-product pb-0">
                                    <div class="accordion-card-one accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="headingOne">
                                                <div class="accordion-button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-controls="collapseOne">
                                                    <div class="addproduct-icon">
                                                        <h5>
                                                            <i class="feather icon-info add-info text-info"></i><span>Datos
                                                                de Categoría</span>
                                                        </h5>
                                                        <a href="javascript:void(0);"><i
                                                                class="feather icon-chevron-down chevron-down-add"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                            <div class="mb-3 add-product">
                                                              <label class="form-label">Nombre de Categoría</label>
                                                              <input type="text" class="form-control" name="categoryName" formControlName="categoryName" />
                                                              <div *ngIf="!categoryForm.get('categoryName')?.valid && (categoryForm.get('categoryName')?.dirty || categoryForm.get('categoryName')?.touched)" class="invalid-feedback d-block">
                                                                Por favor, ingrese un nombre.
                                                              </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-12 col-12">
                                                          <div class="mb-3 add-product">
                                                            <label class="form-label">Estado</label>
                                                              <select class="form-control" name="status" formControlName="status">
                                                                <option value="1" selected>Activo</option>
                                                                <option value="0">Inactivo</option>
                                                              </select>
                                                              <div *ngIf="!categoryForm.get('status')?.valid && (categoryForm.get('status')?.dirty || categoryForm.get('status')?.touched)" class="invalid-feedback d-block">
                                                                Por favor, seleccione un estado.
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light me-2" (click)="closeAddOrEditForm()">Cancelar</button>
                <button type="button" (click)="addOrUpdateCategory(0)" class="btn btn btn-info">Crear y Guardar</button>
            </div>
        </div>
    </div>
</div>


<p-toast>
</p-toast>
